import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CandidateFilter } from 'src/app/model/CandidateFilter';
import { JobSeekerModel } from 'src/app/model/JobSeeker';
import { JobSeekerProffDetailModel } from 'src/app/model/JobSeekerProffDetail';
import { AvailabilityModel } from 'src/app/model/master/Availability';
import { CountryModel } from 'src/app/model/master/Country';
import { CourseModel } from 'src/app/model/master/Course';
import { FunctionalAreaModel } from 'src/app/model/master/FunctionalArea';
import { IndustryModel } from 'src/app/model/master/Industry';
import { NationalityModel } from 'src/app/model/master/Nationality';
import { WorkLevelModel } from 'src/app/model/master/WorkLevel';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyPackageService } from 'src/app/services/companyPackage';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-all-candidate',
  templateUrl: './all-candidate.component.html',
  styleUrls: ['./all-candidate.component.scss']
})
export class AllCandidateComponent implements OnInit {
  @Input() candId: number = 0;
  packageExist: boolean = false;
  total: number = 0;
  countPerpage: number = 11;
  currPage: number = 0;

  availabilityModel: AvailabilityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  availabilityModelList: Array<AvailabilityModel> = []

  workLevelModel: WorkLevelModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  workLevelModelList: Array<WorkLevelModel> = []

  industry: IndustryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  industryList: Array<IndustryModel> = []

  functionalArea: FunctionalAreaModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    count: 0
  }
  functionalAreaList: Array<FunctionalAreaModel> = []

  courseModel: CourseModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  courseModelList: Array<CourseModel> = []

  countryModel: CountryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  countryModelList: Array<CountryModel> = []

  nationality: NationalityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  nationalityList: Array<NationalityModel> = []

  jobSeeker: JobSeekerModel = {
    id: 0,
    userId: '',
    fullNameEn: '',
    fullNameAr: '',
    cvheadline: '',
    eamil: '',
    mobileNo: '',
    currentCity: '',
    profilePicturePath: '',
    resumePath: '',
    coverLetterPath: ''
  }
  jobSeekerList: Array<JobSeekerModel> = [];

  candiFilter: CandidateFilter = {
    locationId: 0,
    nationalityId: 0,
    skills: "",
    courseId: 0,
    functionalAreaId: 0,
    workLevelId: 0,
    industryId: 0,
    availabilityToJoinId: 0,
    minExperience: 0,
    maxExperience: 0,
    minSalary: 0,
    maxSalary: 0,
    countPerPage: this.countPerpage,
    page: 0
  }

  jobSeekerProffDetailModel: JobSeekerProffDetailModel = {
    id: 0,
    jobSeekerId: 0,
    industryId: 0,
    functionalAreaId: 0,
    workLevelId: 0,
    courseId: 0,
    availabilityToJoinId: 0,
    totalWorkExperienceYear: 0,
    totalWorkExperienceMonth: 0,
    monthlySalary: 0,
  }
  jobSeekerProffDetailModelList: string[] = ['industryId', 'functionalAreaId', 'workLevelId', 'courseId', 'availabilityToJoinId', , 'totalWorkExperienceYear', 'totalWorkExperienceMonth']


  yearList: number[] = [];
  monthList: number[] = [];
  isFilter: boolean = false;
  isSubmit: boolean = false;
  p: any;

  configSelect = {
    displayFn: (item: any) => { return item.nameEn; },
    displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    searchOnKey: 'nameEn',
    limitTo: 0,
    height: "231px",
    enableSelectAll: true,
  };

  configStatic = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };
  configStaticNoSearch = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: false,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  // End

  constructor(private masterService: MasterService, private jobSeekerSrv: JobSeekerService, private authService: AuthService,
    private packageService: CompanyPackageService,
    public loader: LoaderService) {
    this.loader.loader = true;
  }
  ngOnInit(): void {
    this.yearList = this.masterService.yearList();
    this.monthList = this.masterService.monthList();
    var urlString = window.location.href;
    var url = new URL(urlString);
    var skill = url.searchParams.get("skill");
    if (skill != null && skill != undefined && skill != "") {
      this.getJobSeekerList();
    } else {
      this.getJobSeekerList();
    }

    this.masterService.getNationalitAll().subscribe((data) => {
      this.nationalityList = data;
    });
    this.masterService.getCountryAll().subscribe((data) => {
      this.countryModelList = data;
    });
    this.masterService.getCourseAll().subscribe((data) => {
      this.courseModelList = data;
    });
    this.masterService.getWorkLevelAll().subscribe((data) => {
      this.workLevelModelList = data;
    });
    this.masterService.getAvailibilityAll().subscribe((data) => {
      this.availabilityModelList = data;
    });
    this.masterService.getIndustryAll().subscribe((data) => {
      this.industryList = data;
    });
    this.masterService.getfunctionalAreaAll().subscribe((data) => {
      this.functionalAreaList = data;
    });
    this.masterService.getCourseAll().subscribe((data) => {
      this.courseModelList = data;
    });
    this.checkPackage();
  }

  filter() {
    this.isFilter = !this.isFilter;
  }

  getJobSeekerList() {
    this.loader.loader = true;
    this.getCountJobSeekerList();
    this.jobSeekerSrv.getAll(this.candiFilter).subscribe((data) => {
      this.jobSeekerList = data;
      this.loader.loader = false;
    })
  }
  getCountJobSeekerList() {
    this.jobSeekerSrv.getCountAll(this.candiFilter).subscribe((data) => {
      this.total = data;
      this.loader.loader = false;
    })
  }

  delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  }

  paginate(p: any) {
    this.p = p;
    this.currPage = this.p - 1;
    this.candiFilter.page = this.currPage;
    this.getJobSeekerList();
  }

  skillInput(event: any) {
    this.candiFilter.skills = event.target.value;

  }

  assignCountry(id: Number) {
    this.candiFilter.locationId = id;
  }

  assignNationality(id: Number) {
    this.candiFilter.nationalityId = id;
  }

  assignCourseId(id: Number) {
    this.candiFilter.courseId = id;
  }

  assignAvalibility(id: Number) {
    this.candiFilter.availabilityToJoinId = id;
  }
  assignIndustry(id: Number) {
    this.candiFilter.industryId = id;
  }
  assignFunctionalArea(id: Number) {
    this.candiFilter.functionalAreaId = id;
  }
  assignWorkLevel(id: Number) {
    this.candiFilter.workLevelId = id;
  }

  selectedObject(list, val) {
    // return list.find(n => n.id == val);
  }
  submit() {
    this.currPage = 0;
    this.candiFilter.page = 0;
    this.getJobSeekerList();
  }


  checkPackage() {
    let compId: Number = this.authService.getAuthStorage().companyId;
    this.packageService.getByCompId(compId).subscribe((data) => {
      if (data.length > 0) {
        this.packageExist = true;
      }
    })
  }

}
