import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-three.component.html',
  styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {

  type: string
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.type = this.authService.getLoginType();
  }
  logout() {
    this.authService.saveLoginType("");
    window.location.href = "/"
  }

}
