import { Component, Input, OnInit } from '@angular/core';
import { JobSeekerModel } from 'src/app/model/JobSeeker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-candidate-widget',
  templateUrl: './candidate-widget.component.html',
  styleUrls: ['./candidate-widget.component.scss']
})
export class CandidateWidgetComponent implements OnInit {
  @Input() jobSeeker: JobSeekerModel;
  @Input() packageExist: boolean = false;

  url: string = "";
  urlCl: string = "";
  urlUser: string = "";
  error: boolean = false;
  ngOnInit(): void {
    this.url = environment.apiUrl + 'Cv/' + this.jobSeeker.resumePath;
    this.urlCl = environment.apiUrl + 'CoverLetter/' + this.jobSeeker.resumePath;
    this.urlUser = environment.apiUrl + 'ProfilePhoto/' + this.jobSeeker.profilePicturePath;
    this.error = false
  }
  imageError() {
    this.error = true;
  }

}
