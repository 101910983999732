import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employer-top-nav',
  templateUrl: './employer-top-nav.component.html',
  styleUrls: ['./employer-top-nav.component.scss']
})
export class EmployerTopNavComponent implements OnInit {
  @Input() active: string
  @Output() toggleActive = new EventEmitter<string>();
  name: string = "";
  url: string = "";
  error:boolean = false;

  constructor(private authService: AuthService, private company: CompanyService) {

  }
  ngOnInit(): void {
    this.url = environment.apiUrl + 'ProfilePhoto/' + this.authService.getAuthStorage().logo;
    this.name = this.authService.getAuthStorage().companyName;
    if (this.name == "" || this.name == undefined) {
      this.company.getById(this.authService.getAuthStorage().companyId).subscribe((data) => {
        this.authService.saveCompanyName(data["nameEn"]);
        this.name = this.authService.getAuthStorage().companyName;
      })
    }
  }
  toggle() {
    console.log("sddsds", this.active);
    if (this.active == "active") {
      this.toggleActive.emit("");
    } else {
      this.toggleActive.emit("active");
    }
  }

  imageError(){
    this.error = true;
  }

}
