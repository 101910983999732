<div class="content-admin-main">

    <div class="wt-admin-right-page-header clearfix">
        <h2>Company Profile!</h2>
        <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>Company Profile!</span></div>
    </div>

    <!--Logo and Cover image-->
    <div class="panel panel-default">
        <div class="panel-heading wt-panel-heading p-a20">
            <h4 class="panel-tittle m-a0">Logo and Cover image</h4>
        </div>
        <div class="panel-body wt-panel-body p-a20 p-b0 m-b30 ">

            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="form-group">

                        <div class="dashboard-profile-pic">
                            <div class="dashboard-profile-photo">
                                <img *ngIf="!error" class="logoImg" [src]="url" alt="" (error)="imageError()">
                                <span *ngIf="error" class="me-3"><i class="fa fa-building i"></i></span>
                                <div class="upload-btn-wrapper">
                                    <div id="upload-image-grid"></div>
                                    <button class="site-button button-sm">Upload Photo</button>
                                    <input type="file" name="myfile" id="" accept=".png,.jpg,.jpeg"
                                        (change)="handleUpload($event)">
                                </div>
                            </div>
                            <p><b>Company Logo </b></p>
                        </div>

                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">

                        <div class="dashboard-profile-pic">
                            <div class="dashboard-profile-photo">
                                <img *ngIf="!errorCover" class="coverImg" [src]="urlCp" alt=""
                                    (error)="imageErrorCover()">
                                <span *ngIf="errorCover" class="me-3"><i class="fa fa-building i"></i></span>
                                <div class="upload-btn-wrapper">
                                    <div id="upload-image-grid"></div>
                                    <button class="site-button button-sm">Upload Cover Photo</button>
                                    <input type="file" name="myfile" id="" accept=".png,.jpg,.jpeg"
                                        (change)="handleUploadCoverPhoto($event)">
                                </div>
                            </div>
                            <p><b>Company Cover Photo </b></p>
                        </div>

                    </div>
                </div>

                <!-- <div class="col-lg-12 col-md-12">
                        <div class="dashboard-cover-pic">
                            <form action="upload.php" class="dropzone dz-clickable"><div class="dz-default dz-message"><span><i class="sl sl-icon-plus"></i> Click here or drop files to upload</span></div></form>
                            <p><b>Background Banner Image :- </b> Max file size is 1MB, Minimum dimension: 770 x 310 And Suitable files are .jpg &amp; .png</p> 
                        </div>                                    
                    </div> -->

            </div>

        </div>
    </div>

    <!--Basic Information-->
    <div class="panel panel-default">
        <div class="panel-heading wt-panel-heading p-a20">
            <h4 class="panel-tittle m-a0">Basic Informations</h4>
        </div>
        <div class="panel-body wt-panel-body p-a20 m-b30 ">
            <div>
                <div class="row">

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Company Name</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_name" type="text" placeholder="Company"
                                    [(ngModel)]="company.nameEn">
                                <!-- <i class="fs-input-icon fa fa-user "></i> -->
                                <mat-icon class="fs-input-icon">compnay</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Phone</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_phone" type="text"
                                    placeholder="(251) 1234-456-7890" [(ngModel)]="company.phoneNo" prefix="+"
                                    mask="(000) 000 00 00">
                                <!-- <i class="fs-input-icon fa fa-phone-alt"></i> -->
                                <mat-icon class="fs-input-icon">phone</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Email Address</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_Email" type="email"
                                    placeholder="Devid@example.com" [(ngModel)]="company.emailId" disabled="true">
                                <!-- <i class="fs-input-icon fa fa-envelope"></i> -->
                                <mat-icon class="fs-input-icon">mail</mat-icon>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-xl-4 col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Website</label>
                                    <div class="ls-inputicon-box"> 
                                        <input class="form-control" name="company_website" type="text" placeholder="https://...">
                                        <mat-icon class="fs-input-icon" >public</mat-icon>
                                    </div>
                                </div>
                            </div> -->

                    <!-- <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Est. Since</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_since" type="integer" placeholder="Since..."
                                    [(ngModel)]="company.establishmentYear">
                                <mat-icon class="fs-input-icon">public</mat-icon>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group mb-3">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Est. Since:</label>
                                <ngx-select-dropdown [options]="yearList"
                                [(ngModel)]="company.establishmentYear"
                                [instanceId]="'instance21'" [multiple]="false"
                                [config]="configStaticNoSearch"></ngx-select-dropdown>

                            
                            </div>
                        </div>
                    </div>



                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>CR Number</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_since" type="integer" placeholder="Since..."
                                    [(ngModel)]="company.crnumber">
                                <!-- <i class="fs-input-icon fa fa-globe-americas"></i> -->
                                <mat-icon class="fs-input-icon">public</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group city-outer-bx has-feedback">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Industry Type:</label>
                                <ngx-select-dropdown [ngModel]="selectedObject(industryList,company.industryId)"
                                    [config]="configSelect" (change)="assignJobType($event.value.id)"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="industryList"></ngx-select-dropdown>

                            </div>
                            <!-- <label>Team Size</label>
                                    <div class="ls-inputicon-box">  
                                        <div class="dropdown bootstrap-select wt-select-box"><select class="wt-select-box selectpicker" name="team-size" data-live-search="true" title="team-size" id="city" data-bv-field="size"><option class="bs-title-option" value=""></option>
                                            <option class="bs-title-option" value="">5-10</option>
                                            <option class="" value="">10+</option>
                                            <option class="" value="">20+</option>
                                            <option class="" value="">50+</option>
                                        </select><button type="button" tabindex="-1" class="btn dropdown-toggle bs-placeholder btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="team-size" data-id="city"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">team-size</div></div> </div></button><div class="dropdown-menu "><div class="bs-searchbox"><input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-1" aria-autocomplete="list"></div><div class="inner show" role="listbox" id="bs-select-1" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div>
                                       
                                        <mat-icon class="fs-input-icon" >translate</mat-icon>
                                    </div> -->

                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group city-outer-bx has-feedback">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Country:</label>
                                <ngx-select-dropdown [ngModel]="selectedObject(countryList,company.countryId)"
                                    [config]="configSelect" (change)="assignCountry($event.value.id)"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="countryList"></ngx-select-dropdown>

                            </div>


                        </div>
                    </div>


                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group city-outer-bx has-feedback">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">City:</label>
                                <ngx-select-dropdown [ngModel]="selectedObject(cityList,company.locationId)"
                                    [config]="configSelect" (change)="assignLocation($event.value.id)"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="cityList"></ngx-select-dropdown>

                            </div>


                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Description</label>
                            <div class="NgxEditor__Wrapper">
                                <angular-editor #editor [(ngModel)]="company.descriptions" [config]="config"
                                    id="my-textarea">
                                    <ng-template #customButtons let-executeCommandFn="executeCommandFn">
                                        <ae-toolbar-set>
                                            <div class="col">
                                                <!-- <div ngbDropdown class="d-inline-block disp-flex">
                                                            <mat-icon aria-hidden="false" class="disp-flex"
                                                                aria-label="Dashboard" style="margin-right: 15px;"
                                                                id="dropdownBasic1" ngbDropdownToggle>library_add</mat-icon>
                                                        </div> -->
                                            </div>
                                        </ae-toolbar-set>
                                    </ng-template>
                                </angular-editor>
                            </div>
                            <!-- <textarea class="form-control" rows="3" placeholder="Greetings! We are Galaxy Software Development Company."
                                    [(ngModel)]="company.descriptions"
                                    ></textarea> -->
                        </div>
                    </div>


                    <div class="col-lg-12 col-md-12 mt-3">
                        <div class="text-left">
                            <button type="submit" class="site-button" (click)="update()">Save Changes</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>