<div class="content-admin-main">

    <div class="wt-admin-right-page-header clearfix">
        <h2>Packages</h2>
        <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>Packages</span></div>
    </div>

    <!--Logo and Cover image-->
    <div class="panel panel-default row">
        <div class="col-lg-4 col-md-6 m-b30" *ngFor="let item of packageList">
            <app-package [package]="item" [selectedPackage]="compPackage" [enroll]="true"> </app-package>

        </div>
    </div>
</div>