<footer class="footer-light">
    <div class="container">
        <!-- FOOTER BLOCKES START -->
        <div class="footer-top">
            <div class="row">
                <div class="col-lg-3 col-md-12">
                    <div class="widget widget_about">
                        <div class="logo-footer clearfix">
                            <a href="/"><img src="assets/img/logo_mahad.png" alt="" /></a>
                        </div>
                        <!-- <p>
                            Many desktop publishing packages and web page
                            editors now.
                        </p> -->
                        <!-- <ul class="ftr-list">
                            <li>
                                <p>
                                    <span>Address :</span>Qatar
                                </p>
                            </li>
                            <li>
                                <p><span>Email :</span>example@max.com</p>
                            </li>
                            <li>
                                <p><span>Call :</span>555-555-1234</p>
                            </li>
                        </ul> -->
                    </div>
                </div>

                <div class="col-lg-9 col-md-12">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="widget widget_services ftr-list-center">
                                <h3 class="widget-title">Mahad Job</h3>
                                <ul>
                                    <li>
                                        <a routerLink="/">Home</a>
                                    </li>
                                    <li>
                                        <a routerLink="/contact-us">Contact us</a>
                                    </li>
                                    <li>
                                        <a routerLink="/signup">Sign-up</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="widget widget_services ftr-list-center">
                                <h3 class="widget-title">Quick Links</h3>
                                <ul>
                                    <li>
                                        <a routerLink="/">Home</a>
                                    </li>
                                    <li>
                                        <a routerLink="/contact-us">Contact us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="widget widget_services ftr-list-center">
                                <h3 class="widget-title">For Employers</h3>
                                <ul>
                                    <li>
                                        <a href="dash-post-job.html">Post Jobs</a>
                                    </li>
                                    <li>
                                        <a href="blog-grid.html">Blog Grid</a>
                                    </li>
                                    <li>
                                        <a href="contact.html">Contact</a>
                                    </li>
                                    <li>
                                        <a href="job-list.html">Jobs Listing</a>
                                    </li>
                                    <li>
                                        <a href="job-detail.html">Jobs details</a>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- FOOTER COPYRIGHT -->
        <div class="footer-bottom">
            <div class="footer-bottom-info">
                <div class="footer-copy-right">
                    <span class="copyrights-text">Copyright © 2023 by MahadJob All Rights
                        Reserved.</span>
                </div>
                <!-- <ul class="social-icons">
                    <li>
                        <a href="javascript:void(0);" class="fab fa-facebook-f"></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fab fa-twitter"></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fab fa-instagram"></a>
                    </li>
                    <li>
                        <a href="javascript:void(0);" class="fab fa-youtube"></a>
                    </li>
                </ul> -->
            </div>
        </div>
    </div>
</footer>