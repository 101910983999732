import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

  @Input() type: string = '';
  constructor() { }

  ngOnInit(): void {
    console.log(this.type,"sa")
  }

}
