import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { JobSeekerSkillModel } from 'src/app/model/JobSeekerSkill';
import { AuthService } from 'src/app/services/auth.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-job-seeker-keyskill',
  templateUrl: './job-seeker-keyskill.component.html',
  styleUrls: ['./job-seeker-keyskill.component.scss']
})
export class JobSeekerKeyskillComponent implements OnInit {

  @Input() edit = true;
  @Input() seekerId: number;
  showEdit: boolean = false;
  submitProfile: boolean = false;
  constructor(private authService: AuthService, private masterService: MasterService,
    private tost: ToastrService, private jobSeekerSrv: JobSeekerService) {

  }
  ngOnInit(): void {
    this.jobSeekerSkillModel.jobSeekerId = this.seekerId;
    this.getJobSeekerSkillList();
  }

  jobSeekerSkillModel: JobSeekerSkillModel = {
    id: 0,
    jobSeekerId: 0,
    nameEn: '',
    nameAr: ''
  }
  jobSeekerSkillModelList: Array<JobSeekerSkillModel> = [];

  showEditToggle() {
    this.showEdit = !this.showEdit;
  }

  getJobSeekerSkillList() {
    this.jobSeekerSrv.getKeySkillBySeekerId(this.jobSeekerSkillModel.jobSeekerId).subscribe((data) => {
      this.jobSeekerSkillModelList = data;
      console.log(this.jobSeekerSkillModelList);
    });
  }


}
