<div class="twm-s-info-wrap">
    <h4 class="twm-s-title m-t0">
        Languages

    </h4>


    <div class="skills-cont d-flex">
        <div class="skills" *ngFor="let item of jobSeekerLanguageModelList">
            <div>

                <span>{{item.nameEn}}</span>

            </div>
            <div>
                <span class="level">{{item.levelKnown}}</span>
            </div>

        </div>
    </div>
    <!-- <p *ngIf="!showEdit">
        {{
        jobSeekerProfileSummary.summary
        }}
    </p> -->
</div>