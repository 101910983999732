import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthStorage } from 'src/app/model/AuthStorage';
import { CompanyUserShorlistingModel } from 'src/app/model/CompanyUserShortlisting';
import { JobSeekerModel } from 'src/app/model/JobSeeker';
import { JobSeekerEducationDetailModel } from 'src/app/model/JobSeekerEducationDetail';
import { JobSeekerEmploymentDetailModel } from 'src/app/model/JobSeekerEmploymentDetail';
import { JobSeekerNotificationModel } from 'src/app/model/JobSeekerNotification';
import { JobSeekerPersonalDetailModel } from 'src/app/model/JobSeekerPersonalDetail';
import { JobSeekerProfileSummaryModel } from 'src/app/model/JobSeekerProfileSummary';
import { JobSeekerSkillModel } from 'src/app/model/JobSeekerSkill';
import { CityModel } from 'src/app/model/master/City';
import { CountryModel } from 'src/app/model/master/Country';
import { CourseModel } from 'src/app/model/master/Course';
import { FunctionalAreaModel } from 'src/app/model/master/FunctionalArea';
import { IndustryModel } from 'src/app/model/master/Industry';
import { JobTypeModel } from 'src/app/model/master/JobType';
import { MartialStatusModel } from 'src/app/model/master/MartialStatus';
import { NationalityModel } from 'src/app/model/master/Nationality';
import { ReligionModel } from 'src/app/model/master/Religion';
import { VisaStatusModel } from 'src/app/model/master/VisaStatus';
import { UploadMedia } from 'src/app/model/UploadMedia';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { CompanyPackageService } from 'src/app/services/companyPackage';
import { CompanyUserShorlistingService } from 'src/app/services/companyUserShortlisting.service';
import { JobService } from 'src/app/services/job.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { JobSeekerNotificationService } from 'src/app/services/jobSeekerNotification.service';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-profile-by-employer',
  templateUrl: './user-profile-by-employer.component.html',
  styleUrls: ['./user-profile-by-employer.component.scss']
})
export class UserProfileByEmployerComponent implements OnInit {
  // Masters
  candId: string = null;
  error: boolean = false;
  religion: ReligionModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  religionList: Array<ReligionModel> = []

  city: CityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  cityList: Array<CityModel> = []

  nationality: NationalityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  nationalityList: Array<NationalityModel> = []

  countryModel: CountryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  countryModelList: Array<CountryModel> = []

  visaStatusModel: VisaStatusModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  visaStatusModelList: Array<VisaStatusModel> = []

  martialStatusModel: MartialStatusModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  martialStatusModelList: Array<MartialStatusModel> = []

  courseModel: CourseModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  courseModelList: Array<CourseModel> = []

  jobSeekerNotificationModel: JobSeekerNotificationModel = {
    id: 0,
    jobSeekerId: 0,
    companyId: 0,
    sentDateTime: '',
    message: '',
    subject: ''
  }

  // End

  url: string = "";
  urlCl: string = "";
  urlUser: string = "";
  industry0: string = "";
  location: string = "";
  name: string = "";
  isSubmit: boolean = false;
  jobId: Number = 0;
  userId: string = this.authService.getAuthStorage().id;
  isShortlisted: boolean = false;
  media: UploadMedia = {
    id: 0,
    base64: "",
    name: ""
  }
  constructor(private authService: AuthService, private uploadMedia: UploadMediaService,
    private companyService: CompanyService, private masterService: MasterService, private packageService: CompanyPackageService,
    private jobService: JobService, private tost: ToastrService, private jobSeekerSrv: JobSeekerService,
    private shorlistingService: CompanyUserShorlistingService, private jobSeekerNotificationService: JobSeekerNotificationService) {

  }
  ngOnInit(): void {
    var urlString = window.location.href;
    var url = new URL(urlString);
    var id = url.searchParams.get("Id");
    if (id != null && id != undefined && id != "") {
      this.candId = id;
      this.jobId = parseInt(url.searchParams.get("jobId") ?? "0");

      this.name = this.authService.getAuthStorage().username;
      this.masterService.getNationalitAll().subscribe((data) => {
        this.nationalityList = data;
      });
      // this.masterService.getCityAll().subscribe((data) => {
      //   this.cityList = data;
      //   console.log(this.cityList);
      // });
      this.masterService.getReligionAll().subscribe((data) => {
        this.religionList = data;
      });
      this.masterService.getMartialStatusAll().subscribe((data) => {
        this.martialStatusModelList = data;
      });
      this.masterService.getVisaStatusAll().subscribe((data) => {
        this.visaStatusModelList = data;
      });
      this.masterService.getCountryAll().subscribe((data) => {
        this.countryModelList = data;
      });
      this.masterService.getCourseAll().subscribe((data) => {
        this.courseModelList = data;
      });
      this.getProfileInfo(parseInt(this.candId));
      this.getProfileSummary(this.candId)
      this.getEmploymentAll(parseInt(this.candId))
      this.getEducationAll(parseInt(this.candId))
      this.gePersonalDetails(parseInt(this.candId))
      this.checkIsShortlisted(parseInt(this.candId));
      this.checkPackage();

      this.sentNotiMsg();
    }
  }

  companyUserShorlistingModel: CompanyUserShorlistingModel = {
    id: undefined,
    companyUserId: undefined,
    jobSeekerId: undefined,
    companyId: undefined,
    userDefined1: '',
    userDefined2: '',
    userDefined3: '',
    userDefined4: ''
  }

  checkIsShortlisted(candId: Number) {
    let data: AuthStorage = this.authService.getAuthStorage();
    if (data.companyUserId != null) {

      this.shorlistingService.isShortlisted(candId, data.companyUserId).subscribe((data) => {
        this.isShortlisted = data;
      })
    }
  }

  toggleShortlist() {
    let data: AuthStorage = this.authService.getAuthStorage();
    if (this.isShortlisted) {
      if (data.companyUserId != null) {
        this.shorlistingService.remove(data.companyUserId, parseInt(this.candId)).subscribe(data => {
          if (data.isSuccess) {
            this.isShortlisted = false;
          } else {
            this.tost.warning(data.message);
          }
        })
      }
    } else {
      this.companyUserShorlistingModel.companyUserId = data.companyUserId;
      this.companyUserShorlistingModel.jobSeekerId = parseInt(this.candId);
      this.companyUserShorlistingModel.companyId = data.companyId;
      this.shorlistingService.create(this.companyUserShorlistingModel).subscribe((data) => {
        if (data.isSuccess) {
          this.isShortlisted = true;
          this.tost.success("Candidates shortlisted");
          this.sentShortlistNotiMsg();
        } else {
          this.tost.warning(data.message);
        }
      });

    }
  }
  // Job Seeker Profile Info

  submitProfileInfo: boolean = false;
  packageExist: boolean = false;
  jobSeekerModel: JobSeekerModel = {
    id: 0,
    userId: this.authService.getAuthStorage().id,
    fullNameEn: '',
    fullNameAr: '',
    cvheadline: '',
    eamil: '',
    mobileNo: '',
    currentCity: '',
    profilePicturePath: '',
    resumePath: '',
    coverLetterPath: ''
  }
  jobSeekerMadatory = [
    'fullNameEn',
    'fullNameAr',
    'eamil',
    'mobileNo',
    'currentCity',
    'cvheadline',
  ]
  saveProfileInfo() {
    console.log(this.masterService.validate(this.jobSeekerMadatory, this.jobSeekerModel), "xzklklklklsklkklx", this.jobSeekerModel.id);
    if (this.masterService.validate(this.jobSeekerMadatory, this.jobSeekerModel)) {
      //Api call
      if (this.jobSeekerModel.id == 0) {
        this.jobSeekerSrv.saveProfileInfo(this.jobSeekerModel).subscribe((data) => {
          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerSrv.updateProfileInfo(this.jobSeekerModel).subscribe((data) => {
          this.getProfileInfo(this.authService.getAuthStorage().id);
          this.tost.success("Operation Succeeded");
        });
      }
    } else {
      this.submitProfileInfo = true;
    }
  }
  getProfileInfo(id: Number) {
    this.jobSeekerSrv.getProfileInfoBySeekerId(id).subscribe((data) => {
      this.jobSeekerModel = data;
      this.assignUrl();
    });
  }
  // End


  // Job Seeker Personal Details

  submitPersonalDetails: boolean = false;
  jobSeekerPersonalDetailModel: JobSeekerPersonalDetailModel = {
    id: 0,
    jobSeekerId: 0,
    dob: '',
    gender: '',
    nationalityId: 0,
    maritalStatusId: 0,
    isDrivingLicense: false,
    issuingCountryId: 0,
    currentCityId: 0,
    languagesKnown: '',
    visaStatusId: 0,
    religionId: 0,
    alternateEmailId: '',
    alternateCountryCode: '',
    alternateMobile: '',
    maritalStatus: undefined,
    religion: undefined,
    nationality: undefined,
    visaStatus: undefined,
    issuingCountry: undefined,
    currentCity: undefined,
    currentCountryId: 0,
    currentCountry: undefined
  }
  jobSeekerPersonalDetailMandatory = [
    'dob',
    'gender',
    'nationalityId',
    'maritalStatusId',
    'isDrivingLicense',
    'issuingCountryId',
    'currentCityId',
    'languagesKnown',
    'visaStatusId',
    'religionId',
  ]

  savePersonalDetails() {
    if (this.masterService.validate(this.jobSeekerPersonalDetailMandatory, this.jobSeekerPersonalDetailModel)) {
      //Api call
      this.jobSeekerSrv.savePersonalInfo(this.jobSeekerPersonalDetailModel).subscribe((data) => {
        this.tost.success("Operation Succeeded");
      });

    } else {
      this.submitPersonalDetails = true;
    }
  }
  gePersonalDetails(id: Number) {
    this.jobSeekerSrv.getPPersonalInfo(id).subscribe((data) => {
      if (data.dob.includes("T")) {
        data.dob = data.dob.split("T")[0];
      }
      this.jobSeekerPersonalDetailModel = data;
    });
  }

  // End

  // Job Seeker Skill

  jobSeekerSkill: JobSeekerSkillModel = {
    id: 0,
    jobSeekerId: 0,
    nameEn: '',
    nameAr: ''
  }
  jobSeekerSkillList: Array<JobSeekerSkillModel> = [];

  // End

  // Job Seeker Profile Summary

  submitProfile: boolean = false;
  showEdit: boolean = false;

  jobSeekerProfileSummary: JobSeekerProfileSummaryModel = {
    id: 0,
    jobSeekerId: this.authService.getAuthStorage().jobseekerId,
    summary: ''
  }
  jobSeekerProfileSummaryMandatory = [
    "summary"
  ]

  showEditToggle() {
    this.showEdit = !this.showEdit;
  }

  saveProfileSummary() {
    if (this.masterService.validate(this.jobSeekerProfileSummaryMandatory, this.jobSeekerProfileSummary)) {
      //Api call
      if (this.jobSeekerProfileSummary.id == 0) {
        this.jobSeekerSrv.saveProfileSummary(this.jobSeekerProfileSummary).subscribe((data) => {
          this.getProfileSummary(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerSrv.updateProfileSummary(this.jobSeekerProfileSummary).subscribe((data) => {
          this.getProfileSummary(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
        });;
      }
    } else {
      this.submitProfile = true;
    }
  }
  getProfileSummary(id: string) {
    this.jobSeekerSrv.getProfileSummary(id).subscribe((data) => {
      this.jobSeekerProfileSummary = data;
    });
  }

  // End

  // Job Seeker Education Detail

  submitEducation: boolean = false;
  jobSeekerEducationDetail: JobSeekerEducationDetailModel = {
    id: 0,
    jobSeekerId: this.authService.getAuthStorage().jobseekerId,
    courseId: 0,
    specialization: '',
    instituteName: '',
    instituteLocationId: 0,
    passingYear: undefined,
  }
  jobSeekerEducationDetailList: Array<JobSeekerEducationDetailModel> = [];
  jobSeekerEducationMadatory = [
    "courseId",
    "specialization",
    "instituteName",
    "instituteLocationId",
    'passingYear'
  ]
  assignInstituteLocationId(id: Number) {
    this.jobSeekerEducationDetail.instituteLocationId = id;
  }
  assignCourseId(id: Number) {
    this.jobSeekerEducationDetail.courseId = id;
  }
  saveEducation() {
    if (this.masterService.validate(this.jobSeekerEducationMadatory, this.jobSeekerEducationDetail)) {
      //Api call
      this.jobSeekerEducationDetail.passingYear = parseInt(this.jobSeekerEducationDetail.passingYear.toString());
      if (this.jobSeekerEducationDetail.id == 0) {
        this.jobSeekerSrv.saveEducationDetails(this.jobSeekerEducationDetail).subscribe((data) => {
          this.getEducationAll(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerSrv.updateEducationDetails(this.jobSeekerEducationDetail).subscribe((data) => {
          this.getEducationAll(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
        });
      }
    } else {
      this.submitEducation = true;
    }
  }
  newEducation() {
    this.jobSeekerEducationDetail = {
      id: 0,
      jobSeekerId: this.authService.getAuthStorage().jobseekerId,
      courseId: 0,
      specialization: '',
      instituteName: '',
      instituteLocationId: 0,
      passingYear: undefined,
    }
  }
  getByIdEducation(id: Number) {
    this.jobSeekerSrv.getEducationById(id).subscribe((data) => {
      this.jobSeekerEducationDetail = data;
    });
  }
  deleteEducation(id: Number) {
    this.jobSeekerSrv.deleteEducationById(id).subscribe((data) => {
      this.getEducationAll(this.authService.getAuthStorage().jobseekerId);
      this.tost.success("Operation Succeeded");
    });
  }
  getEducationAll(id: Number) {
    this.jobSeekerSrv.getEducationAll(id).subscribe((data) => {
      this.jobSeekerEducationDetailList = data;
    });
  }

  // Ended Job Seeker Education Detail


  // Job Seeker Employment Detail

  submitEmployment: boolean = false;
  jobSeekerEmploymentDetail: JobSeekerEmploymentDetailModel = {
    id: 0,
    jobSeekerId: this.authService.getAuthStorage().jobseekerId,
    designation: '',
    companyName: '',
    companyLocation: '',
    isCurrentCompany: false,
    fromMonth: '',
    fromYear: 0,
    toMonth: '',
    toYear: 0,
    jobProfile: ''
  }
  jobSeekerEmploymentDetailList: Array<JobSeekerEmploymentDetailModel> = [];
  jobSeekerEmploymentMandatory = [
    'designation',
    'companyName',
    'companyLocation',
    'fromMonth',
    'fromYear',
    'toMonth',
    'toYear',
    'jobProfile'
  ]
  saveEmployment() {
    console.log(this.masterService.validate(this.jobSeekerEmploymentMandatory, this.jobSeekerEmploymentDetail), "yoyoyy")
    if (this.masterService.validate(this.jobSeekerEmploymentMandatory, this.jobSeekerEmploymentDetail)) {
      //Api call
      this.jobSeekerEmploymentDetail.fromMonth = this.jobSeekerEmploymentDetail.fromMonth.toString();
      this.jobSeekerEmploymentDetail.toMonth = this.jobSeekerEmploymentDetail.toMonth.toString();
      this.jobSeekerEmploymentDetail.toYear = parseInt(this.jobSeekerEmploymentDetail.toYear.toString());
      this.jobSeekerEmploymentDetail.fromYear = parseInt(this.jobSeekerEmploymentDetail.fromYear.toString());
      if (this.jobSeekerEmploymentDetail.id == 0) {
        this.jobSeekerSrv.saveEmploymentDetails(this.jobSeekerEmploymentDetail).subscribe((data) => {
          this.getEmploymentAll(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerSrv.updateEmploymentDetails(this.jobSeekerEmploymentDetail).subscribe((data) => {
          this.getEmploymentAll(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
        });;
      }
    } else {
      this.submitEmployment = true;
    }
  }
  newEmployment() {
    this.jobSeekerEmploymentDetail = {
      id: 0,
      jobSeekerId: this.authService.getAuthStorage().jobseekerId,
      designation: '',
      companyName: '',
      companyLocation: '',
      isCurrentCompany: false,
      fromMonth: '',
      fromYear: 0,
      toMonth: '',
      toYear: 0,
      jobProfile: ''
    }
  }
  getByIdEmployment(id: Number) {
    this.jobSeekerSrv.getEmploymentById(id).subscribe((data) => {
      this.jobSeekerEmploymentDetail = data;
    });
  }
  deleteEmployment(id: Number) {
    this.jobSeekerSrv.deleteEmploymentById(id).subscribe((data) => {
      this.getEmploymentAll(this.authService.getAuthStorage().jobseekerId);
      this.tost.success("Operation Succeeded");
    });
  }
  getEmploymentAll(id: Number) {
    this.jobSeekerSrv.getEmploymentAll(id).subscribe((data) => {
      this.jobSeekerEmploymentDetailList = data;
    });
  }
  // Ended Job Seeker Employment Detail


  assignNationality(id: Number) {
    this.jobSeekerPersonalDetailModel.nationalityId = id;
  }

  assignCity(id: Number) {
    this.jobSeekerPersonalDetailModel.currentCityId = id;
  }

  assignMartialStatus(id: Number) {
    this.jobSeekerPersonalDetailModel.maritalStatusId = id;
  }

  assignCountry(id: Number) {
    this.jobSeekerPersonalDetailModel.issuingCountryId = id;
  }

  assignVisaId(id: Number) {
    this.jobSeekerPersonalDetailModel.visaStatusId = id;
  }

  assignReligionId(id: Number) {
    this.jobSeekerPersonalDetailModel.religionId = id;
  }

  assignUrl() {
    this.url = environment.apiUrl + 'Cv/' + this.jobSeekerModel.resumePath;
    this.urlCl = environment.apiUrl + 'CoverLetter/' + this.jobSeekerModel.coverLetterPath;
    this.urlUser = environment.apiUrl + 'ProfilePhoto/' + this.jobSeekerModel.profilePicturePath;
    this.error = false;
  }

  assignCurrCountry(id: Number) {
    this.jobSeekerPersonalDetailModel.currentCountryId = id;
    this.cityList = [];
    console.log("Current", id);
    this.masterService.getCityByCountryId(id).subscribe((data) => {
      this.cityList = data;
    });
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      console.log(reader.result);
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      if (this.jobSeekerModel.resumePath == "" || this.jobSeekerModel.resumePath == undefined || this.jobSeekerModel.resumePath == null) {
        this.jobSeekerModel.resumePath = "new" + "." + type;
      } else {
        this.jobSeekerModel.resumePath = this.jobSeekerModel.resumePath.split(".")[0] + "." + type;
      }
      this.jobSeekerModel["base64"] = reader.result.toString().split(";base64,")[1];
      this.jobSeekerSrv.addCvProfileInfo(this.jobSeekerModel).subscribe((data) => {

        this.assignUrl();
        window.location.reload();
      });
      // this.media.name = "cv" + this.authService.getAuthStorage().id + "." + type;
      // this.media.base64 = reader.result.toString().split(";base64,")[1];
      // this.uploadMedia.uploadCv(this.media).subscribe((data) => {
      //   console.log(data)
      // });
      // console.log(reader.result,type);
    };
  }
  handleUploadPic(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      if (this.jobSeekerModel.profilePicturePath == "" || this.jobSeekerModel.profilePicturePath == undefined || this.jobSeekerModel.profilePicturePath == null) {
        this.jobSeekerModel.profilePicturePath = "new" + "." + type;
      } else {
        this.jobSeekerModel.profilePicturePath = this.jobSeekerModel.profilePicturePath.split(".")[0] + "." + type;
      }
      this.jobSeekerModel["base64"] = reader.result.toString().split(";base64,")[1];
      this.jobSeekerSrv.addPicProfileInfo(this.jobSeekerModel).subscribe((data) => {
        this.assignUrl();
        window.location.reload();
      });
      // console.log(reader.result,type);
    };
  }
  assignSalaryYear(val: string) {

  }

  imageError() {
    this.error = true;
  }

  sentNotiMsg() {
    if (this.jobSeekerNotificationService.getLocalDbSeen(this.candId) == null) {
      this.jobSeekerNotificationModel.companyId = this.authService.getAuthStorage().companyId;
      this.jobSeekerNotificationModel.jobSeekerId = parseInt(this.candId.toString());
      this.jobSeekerNotificationModel.subject = "You are getting noticied";
      this.jobSeekerNotificationModel.message = "An employer from " + this.authService.getAuthStorage().companyName + " has viewed your profile";
      this.jobSeekerNotificationModel.sentDateTime = new Date().toISOString();
      this.jobSeekerNotificationService.create(this.jobSeekerNotificationModel).subscribe((data) => {
        this.jobSeekerNotificationService.saveLocalDbSeen(this.candId)
      })
    }
  }
  sentShortlistNotiMsg() {
    if (this.jobSeekerNotificationService.getLocalDbShortlist(this.candId) == null) {
      this.jobSeekerNotificationModel.companyId = this.authService.getAuthStorage().companyId;
      this.jobSeekerNotificationModel.jobSeekerId = parseInt(this.candId.toString());
      this.jobSeekerNotificationModel.subject = "You are getting noticied";
      this.jobSeekerNotificationModel.message = "An employer from " + this.authService.getAuthStorage().companyName + " has shortlisted your profile";
      this.jobSeekerNotificationModel.sentDateTime = new Date().toISOString();
      this.jobSeekerNotificationService.create(this.jobSeekerNotificationModel).subscribe((data) => {
        this.jobSeekerNotificationService.saveLocalDbShortlist(this.candId)
      })
    }
  }
  checkPackage() {
    let compId: Number = this.authService.getAuthStorage().companyId;
    this.packageService.getByCompId(compId).subscribe((data) => {
      if (data.length > 0) {
        this.packageExist = true;
      }
    })
  }

  msg(){
      this.tost.warning("Please buy package");
      
  }
}

