import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ForgetPwdModel } from 'src/app/model/ForgetPwd';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-foget-pwd',
  templateUrl: './foget-pwd.component.html',
  styleUrls: ['./foget-pwd.component.scss']
})
export class FogetPwdComponent implements OnInit {
  forgetPwd: ForgetPwdModel = {
    email: '',
    userType: 'employer'
  }
  submit: boolean = false;
  constructor(
    private authService: AuthService,
    private tost: ToastrService,
    private spinner: NgxSpinnerService,
    public loader: LoaderService,
  ) {
    this.loader.loader = false;

  }
  ngOnInit(): void {
  }
  onSubmit() {
    this.submit = true;
    this.loader.loader = true;
    this.authService.forgetPwd(this.forgetPwd).subscribe((data) => {
      if (data.isSuccess) {
        this.tost.success("Please check your email to reset your password");
        this.loader.loader = false;
      } else {
        this.tost.error(data.message.toString());
        this.loader.loader = false;
      }
    })
  }
}
