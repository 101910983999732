<div class="we-disp mt-3">
    <h4 class="twm-s-title">
        Skill Certification
    </h4>
    <!-- <button type="submit" (click)="newCertification()" class="btn site-button action" data-bs-target="#sign_up_popup"
        data-bs-toggle="modal" data-bs-dismiss="modal">
        <mat-icon>add</mat-icon>
    </button> -->
</div>
<div class="twm-timing-list-wrap row">
    <div class="twm-timing-list col-4" *ngFor="
            let item of jobSeekerSkillCertificationList
        ">
        <div class="twm-time-list-date">
            Expiry: {{ item.experienceMonth }}-{{ item.experienceYear }}
        </div>
        <!-- <div class="twm-time-list-title">
            {{ item.companyName }}
            <button type="submit" class="btn site-button action" (click)="
            getSkillCertificationById(
                        item.id
                    )
                " data-bs-target="#sign_up_popup" data-bs-toggle="modal" data-bs-dismiss="modal">
                <mat-icon>edit</mat-icon>
            </button>
            <button (click)="
                    deleteSkillCertification(
                        item.id
                    )
                " type="submit" class="ms-3 btn site-button action mandatory">
                <mat-icon>delete</mat-icon>
            </button>
        </div> -->
        <div class="twm-time-list-position">
            {{ item.designation }}
        </div>
        <div class="twm-time-list-discription">
            <p>
                {{
                item.name
                }}
            </p>
        </div>
    </div>

</div>