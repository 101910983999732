<div class="page-wraper container">

    <!-- <app-navbar-style-one></app-navbar-style-one> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content ">
        <app-loading *ngIf="loader.loader"></app-loading>
        <!-- <app-login *ngIf="type == 'login'" [type]="type" [userType]="userType" (toggle)="toggle($event)"></app-login> -->
        <div class="modal-content">
            <div>
                <div class="">
                    <h2 class="modal-title text-center" id="sign_up_popupLabel">Sign Up</h2>
                    <hr />
                </div>

                <div class="modal-body">
                    <div class="twm-tabs-style-2 d-flex align-content-center">
                        <div class="tab-content" id="myTabContent">
                            <!--Signup Employer Content-->
                            <div class="tab-pane fade show active" id="sign-Employer">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <label>Company Name:</label>
                                            <input type="text" class="form-control" placeholder="Company Name "
                                                [(ngModel)]="register.nameEn">
                                            <span class="mandatory" *ngIf="register.nameEn== '' && isSubmit">This field
                                                is requied</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <label>Contact person :</label>
                                            <input type="text" class="form-control" placeholder="Contact Person Name"
                                                [(ngModel)]="register.fullName">
                                            <span class="mandatory" *ngIf="register.fullName == '' && isSubmit">This
                                                field is requied</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <label>Phone:</label>
                                            <input type="text" class="form-control" placeholder="+123456789" prefix="+"
                                                mask="(000) 000 00 0000" [(ngModel)]="register.phoneNo">
                                            <span class="mandatory" *ngIf="register.phoneNo == '' && isSubmit">This
                                                field is requied</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Mobile:</label>
                                            <input type="text" class="form-control" placeholder="+12349056789"
                                                prefix="+" mask="(000) 000 00 0000" [(ngModel)]="register.mobileNo">
                                            <span class="mandatory" *ngIf="register.mobileNo == '' && isSubmit">This
                                                field is requied</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <label>Cr Number:</label>
                                            <input type="text" class="form-control" placeholder="Cr Number"
                                                [(ngModel)]="register.crnumber">
                                            <span class="mandatory" *ngIf="register.crnumber == '' && isSubmit">This
                                                field is requied</span>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <input class="form-control" matInput [matDatepicker]="dp"
                                                placeholder="Month and Year" [(ngModel)]="register.establishmentYear">
                                            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                            <mat-datepicker #dp startView="multi-year" class="yo"
                                                (yearSelected)="chosenYearHandler($event, dp)"
                                                panelClass="example-month-picker">
                                            </mat-datepicker>
                                            <label>Establishment Year:</label>
                                            <input type="number" class="form-control" placeholder="1995"
                                                [(ngModel)]="register.establishmentYear">
                                            <span class="mandatory"
                                                *ngIf="register.establishmentYear == 0 && isSubmit">This field is
                                                requied</span>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">Establishment Year:</label>
                                                <ngx-select-dropdown [options]="yearList"
                                                    [(ngModel)]="register.establishmentYear" [instanceId]="'instance21'"
                                                    [multiple]="false"
                                                    [config]="configStaticNoSearch"></ngx-select-dropdown>


                                            </div>
                                            <span class="mandatory"
                                                *ngIf="register.establishmentYear == 0 && isSubmit">This
                                                field is requied</span>
                                            <!-- <label>Industry Type:</label>
                                            <select style="display: none;"  [(ngModel)]="register.industryId">
                                                <option *ngFor="let industry of industryList" [value]="industry.id">{{industry.nameEn}}</option>
                                            </select>
                                            <div class="nice-select" tabindex="0">
                                                <span class="current">Select Industry</span>
                                                <ul class="list">
                                                    <li  *ngFor="let industry of industryList" [data-value]="industry.nameEn" class="option" (click)="assignIndustry(industry.id)">{{industry.nameEn}}</li>
                                                </ul>
                                            </div> -->
                                        </div>
                                    </div>



                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">Industry Type:</label>
                                                <ngx-select-dropdown
                                                    [ngModel]="selectedObject(industryList,register.industryId )"
                                                    [config]="configSelect" (change)="assignIndustry($event.value.id)"
                                                    [instanceId]="'instance21'" [multiple]="false"
                                                    [options]="industryList"></ngx-select-dropdown>


                                            </div>
                                            <span class="mandatory" *ngIf="register.industryId == 0 && isSubmit">This
                                                field is requied</span>
                                            <!-- <label>Industry Type:</label>
                                            <select style="display: none;"  [(ngModel)]="register.industryId">
                                                <option *ngFor="let industry of industryList" [value]="industry.id">{{industry.nameEn}}</option>
                                            </select>
                                            <div class="nice-select" tabindex="0">
                                                <span class="current">Select Industry</span>
                                                <ul class="list">
                                                    <li  *ngFor="let industry of industryList" [data-value]="industry.nameEn" class="option" (click)="assignIndustry(industry.id)">{{industry.nameEn}}</li>
                                                </ul>
                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">Country:</label>
                                                <ngx-select-dropdown
                                                    [ngModel]="selectedObject(countryList,register.countryId )"
                                                    [config]="configSelect" (change)="assignCountry($event.value.id)"
                                                    [instanceId]="'instance21'" [multiple]="false"
                                                    [options]="countryList"></ngx-select-dropdown>


                                            </div>
                                            <span class="mandatory" *ngIf="register.countryId == 0 && isSubmit">This
                                                field is requied</span>

                                            <!-- <label>Select City:</label>
                                            <select style="display: none;"  [(ngModel)]="register.locationId">
                                                <option *ngFor="let city of cityList" [value]="city.id">{{city.nameEn}}</option>
                                            </select>
                                            <div class="nice-select" tabindex="0">
                                                <span class="current">City</span>
                                                <ul class="list">
                                                    <li  *ngFor="let city of cityList" [data-value]="city.nameEn" class="option" (click)="assignCity(city.id)">{{city.nameEn}}</li>
                                                </ul>
                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <div _ngcontent-sva-c15=""
                                                class="form-group city-outer-bx has-feedback city">
                                                <label _ngcontent-sva-c15="">City:</label>
                                                <ngx-select-dropdown
                                                    [ngModel]="selectedObject(cityList,register.locationId )"
                                                    [config]="configSelect" (change)="assignCity($event.value.id)"
                                                    [instanceId]="'instance21'" [multiple]="false"
                                                    [options]="cityList"></ngx-select-dropdown>


                                            </div>
                                            <span class="mandatory" *ngIf="register.locationId == 0 && isSubmit">This
                                                field is requied</span>

                                            <!-- <label>Select City:</label>
                                            <select style="display: none;"  [(ngModel)]="register.locationId">
                                                <option *ngFor="let city of cityList" [value]="city.id">{{city.nameEn}}</option>
                                            </select>
                                            <div class="nice-select" tabindex="0">
                                                <span class="current">City</span>
                                                <ul class="list">
                                                    <li  *ngFor="let city of cityList" [data-value]="city.nameEn" class="option" (click)="assignCity(city.id)">{{city.nameEn}}</li>
                                                </ul>
                                            </div> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">
                                            <label>Address:</label>
                                            <textarea class="form-control" placeholder=""
                                                [(ngModel)]="register.address"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <div class="form-group mb-3">

                                            <div class="dashboard-profile-pic">
                                                <div class="dashboard-profile-photo">
                                                    <img [src]="url" alt="">
                                                    <div class="upload-btn-wrapper">
                                                        <div id="upload-image-grid"></div>
                                                        <button class="site-button button-sm">Upload Logo</button>
                                                        <input type="file" name="myfile" id=""
                                                            accept=".png, .jpg, .jpeg" (change)="upload($event)">
                                                    </div>
                                                    <span class="mandatory" *ngIf="file0 == null && isSubmit">This
                                                        field is requied</span>
                                                    <p>{{logoFile}}</p>
                                                </div>
                                                <!-- <p><b>Company Logo :- </b> Max file size is 1MB </p> -->
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-12">
                                        <div class="form-group mb-3">

                                            <div class="dashboard-profile-pic">
                                                <div class="dashboard-profile-photo">
                                                    <img [src]="url" alt="">
                                                    <div class="upload-btn-wrapper">
                                                        <div id="upload-image-grid"></div>
                                                        <button class="site-button button-sm">Upload Cover
                                                            Photo</button>
                                                        <input type="file" name="myfile3" id="j"
                                                            accept=".jpg,.jpeg,.png," (change)="uploadCover($event)">
                                                        <!-- <span class="mandatory" *ngIf="file0 == null && isSubmit">This
                                                                field is requied</span> -->
                                                    </div>
                                                    <p>{{coverFile}}</p>
                                                </div>
                                                <!-- <p><b>Cover Photo :- </b> Max file size is 1MB </p> -->
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" class="form-control" placeholder="Email "
                                                    [(ngModel)]="register.emailId">
                                                <span class="mandatory" *ngIf="(register.emailId== '') && isSubmit">This
                                                    field
                                                    is requied</span>
                                                <span class="mandatory"
                                                    *ngIf="(register.emailId  != '' && (!register.emailId.includes('@') || !register.emailId.includes('.'))) && isSubmit">Enter
                                                    Valid Email</span>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <label>Password :</label>
                                                <input type="password" class="form-control" placeholder="Password"
                                                    [(ngModel)]="register.password">
                                                <span class="mandatory" *ngIf="register.password == '' && isSubmit">This
                                                    field is requied</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group mb-3">
                                                <label>Company description:</label>
                                                <br>
                                                <span class="mandatory"
                                                    *ngIf="register.descriptions == '' && isSubmit">This
                                                    field is requied</span>
                                                <div class="NgxEditor__Wrapper">
                                                    <angular-editor #editor [(ngModel)]="register.descriptions"
                                                        [config]="config" id="my-textarea">
                                                        <ng-template #customButtons
                                                            let-executeCommandFn="executeCommandFn">
                                                            <ae-toolbar-set>
                                                                <div class="col">
                                                                    <!-- <div ngbDropdown class="d-inline-block disp-flex">
                                                                        <mat-icon aria-hidden="false" class="disp-flex"
                                                                            aria-label="Dashboard" style="margin-right: 15px;"
                                                                            id="dropdownBasic1" ngbDropdownToggle>library_add</mat-icon>
                                                                    </div> -->
                                                                </div>
                                                            </ae-toolbar-set>
                                                        </ng-template>
                                                    </angular-editor>
                                                </div>
                                                <!-- <textarea class="form-control" placeholder=""
                                                    [(ngModel)]="register.descriptions"></textarea>
                                                <span class="mandatory" *ngIf="register.descriptions == '' && isSubmit">This
                                                    field is requied</span> -->
                                            </div>
                                        </div>
                                    </div>


                                    <!-- <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-3">
                                            <input name="username" type="text" required="" class="form-control" placeholder="Usearname*">
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-3">
                                            <input name="email" type="text" class="form-control" required="" placeholder="Password*">
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-3">
                                            <input name="phone" type="text" class="form-control" required="" placeholder="Email*">
                                        </div>
                                    </div>
    
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-3">
                                            <input name="phone" type="text" class="form-control" required="" placeholder="Phone*">
                                        </div>
                                    </div> -->
                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-3">
                                            <div class=" form-check">
                                                <input type="checkbox" [(ngModel)]="termCond" class="form-check-input"
                                                    id="agree2">
                                                <label class="form-check-label" for="agree2">I agree to the <a
                                                        href="javascript:;">Terms and conditions</a></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <button class="site-button" (click)="registerEmployer()">Sign Up</button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        Already have an account ? Please <button class="btn btn-primary "
                                            data-bs-target="#sign_up_popup2" data-bs-toggle="modal"
                                            data-bs-dismiss="modal" [routerLink]="'/login'"> Login </button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- <div class="modal-footer">
                    <span class="modal-f-title">Login or Sign up with</span>
                    <ul class="twm-modal-social">
                        <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                    </ul>
                </div> -->

            </div>
        </div>
    </div>
</div>
<!-- End Post A Job -->