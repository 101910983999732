import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

  IsAuthenticated: boolean = false;
  name:string=""
  type:string = "";

  constructor(private authService:AuthService) { }

  ngOnInit(): void {
    this.IsAuthenticated = this.authService.isAuth();
    this.name = this.authService.getAuthStorage().username.substring(0,1);
    this.type = this.authService.getAuthStorage().type;
  }

  logout(){
    this.authService.LogOut();
    window.location.href = "/"
  }

}
