import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponseModel } from '../model/LoginResponseModel';
import { environment } from 'src/environments/environment';
import { JsonPipe } from '@angular/common';
import { AuthStorage } from '../model/AuthStorage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  private authStorage: AuthStorage = {
    token: "",
    username: "",
    email: "",
    id: "",
    type: "",
    companyId: null,
    companyName: "",
    jobseekerId: null,
    companyUserId: null,
    role: "",
    logo: "",
  }

  private loginPath = environment.apiUrl + 'Identity/Login';
  private registerPath = environment.apiUrl + 'Identity/Register';
  private registerEmployerPath = environment.apiUrl + 'Employer/Registration';
  private isActivePath = environment.apiUrl + 'Identity/IsActive';
  private forgetPwdPath = environment.apiUrl + 'Identity/ForgetPwd';
  private resetPwdPath = environment.apiUrl + 'Identity/ResetPwd';
  private changePwdPath = environment.apiUrl + 'Identity/ChangePwd';

  likeThat = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI4OTVjZTFkMi1jMWNmLTQ4NDAtYjM3MS02YmIzZmRjYjVmMzYiLCJuYW1lIjoieW9icm8iLCJuYmYiOjE2NzkwNzQwMTcsImV4cCI6MTY3OTY3ODgxNywiaWF0IjoxNjc5MDc0MDE3fQ.13gY4FI9ZstHpGbL4SgxHStHqp1hGAv_MenWLthRx3c";

  login(data: any): Observable<LoginResponseModel> {
    return this.http.post<LoginResponseModel>(this.loginPath, data);
  }

  isActive(data: any): Observable<any> {
    return this.http.post<any>(this.isActivePath, data);
  }


  register(data: any): Observable<any> {
    return this.http.post(this.registerPath, data);
  }

  registerEmployer(data: any): Observable<any> {
    return this.http.post(this.registerEmployerPath, data);
  }

  forgetPwd(data: any): Observable<any> {
    return this.http.post(this.forgetPwdPath, data);
  }
  resetPwd(data: any): Observable<any> {
    return this.http.post(this.resetPwdPath, data);
  }
  changePwd(data: any): Observable<any> {
    return this.http.post(this.changePwdPath, data);
  }


  saveToken(data: any) {
    let token = data['token'];
    localStorage.setItem('user', JSON.stringify(data));
    console.log(token);
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getAuthStorage() {
    let val = localStorage.getItem('authstorage');
    if (val == null || val == undefined) {
      return this.authStorage;
    } else {
      return JSON.parse(val);
    }
  }

  saveAuthStorage(data: AuthStorage) {
    localStorage.setItem('authstorage', JSON.stringify(data));
  }
  saveLogo(name: string) {
    let val = this.getAuthStorage();
    val.logo = name;
    this.saveAuthStorage(val);
  }
  saveCompanyName(name: string) {
    let val = this.getAuthStorage();
    val.companyName = name;
    this.saveAuthStorage(val);
  }

  logout() {
    localStorage.removeItem('authstorage');
  }

  isUser() {
    let data = this.getAuthStorage();
    if (data["type"] == "user") {
      return true;
    }
    return false;
  }

  isAuth() {
    let data = this.getAuthStorage();
    if (data["token"] == "") {
      return false;
    }
    return true;
  }

  getLoginType() {
    let type = "";
    let data = this.getAuthStorage()
    if (data["type"] == "employer") {
      return "employer"
    }
    return "user";
    return localStorage.getItem('loginType') == null || undefined ? "" : localStorage.getItem('loginType');
  }

  saveLoginType(type: string) {
    return localStorage.setItem('loginType', type);
  }
  // getCurrentProfile(): LoginResponseModel {

  //   return JSON.parse<LoginResponseModel>(localStorage.getItem('user'));
  // }

  isAuthenticated() {
    if (this.getToken()) return true;
    else return false;
  }

  LogOut() {
    localStorage.clear();
  }

}
