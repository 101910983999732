import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class JobSeekerNotificationService {
    private jobSeekerNotipath = environment.apiUrl + 'JobSeekerNotification/';
    constructor(private http: HttpClient) { }

    getByJobSeekerId(jobSeekerId: Number): Observable<any> {
        return this.http.get(this.jobSeekerNotipath + "ByJobSeekerId?jobSeekerId=" + jobSeekerId);
    }

    create(data: any): Observable<any> {
        return this.http.post(this.jobSeekerNotipath + "Create", data);
    }

    saveLocalDbSeen(candiId: string) {
        console.log("dfjkkdfjkdfdkjjf");
        localStorage.setItem("candidateSeen" + candiId, "seen");
    }
    getLocalDbSeen(candiId: string) {
        console.log("dfjkkdfjkdfdf", localStorage.getItem("candidateSeen" + candiId));
        return localStorage.getItem("candidateSeen" + candiId);
    }

    saveLocalDbShortlist(candiId: string) {
        localStorage.setItem("candidateShortlist" + candiId, "seen");
    }
    getLocalDbShortlist(candiId: string) {
        return localStorage.getItem("candidateShortlist" + candiId);
    }
}
