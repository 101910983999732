import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { JobSeekerEmploymentDetailModel } from 'src/app/model/JobSeekerEmploymentDetail';
import { JobSeekerSkillCertificationModel } from 'src/app/model/JobSeekerSkillCertification';
import { AuthService } from 'src/app/services/auth.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-job-seeker-skillcertification',
  templateUrl: './job-seeker-skillcertification.component.html',
  styleUrls: ['./job-seeker-skillcertification.component.scss']
})
export class JobSeekerSkillcertificationComponent implements OnInit {
  @Input() id: number = 0;
  constructor(private authService: AuthService, private masterService: MasterService,
    private tost: ToastrService, private jobSeekerSrv: JobSeekerService) {

  }
  ngOnInit(): void {
    this.jobSeekerSkillCertification.jobSeekerId = this.id;
    this.getSkillCertificationAll(this.id);
  }

  // Job Seeker Employment Detail

  submitEmployment: boolean = false;
  jobSeekerSkillCertification: JobSeekerSkillCertificationModel = {
    id: 0,
    jobSeekerId: this.id,
    name: '',
    lastUsed: 0,
    experienceYear: 0,
    experienceMonth: 0
  }
  jobSeekerSkillCertificationList: Array<JobSeekerSkillCertificationModel> = [];
  jobSeekerSkillCertificationMandatory = [
    'name',
    'experienceYear',
    'experienceMonth'
  ]

  newCertification() {
    this.jobSeekerSkillCertification = {
      id: 0,
      jobSeekerId: this.id,
      name: '',
      lastUsed: 0,
      experienceYear: 0,
      experienceMonth: 0
    }
  }
  getSkillCertificationAll(id: Number) {
    this.jobSeekerSrv.getKeySkillCertificationBySeekerId(id).subscribe((data) => {
      this.jobSeekerSkillCertificationList = data;
    });
  }
  // Ended Job Seeker Employment Detail


}
