import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyPackageModel } from 'src/app/model/CompanyPackage';
import { PackageModel } from 'src/app/model/master/Package';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { CompanyPackageService } from 'src/app/services/companyPackage';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';
import { Location } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  @Input() package: PackageModel;
  @Input() selectedPackage: CompanyPackageModel | null;
  @Input() enroll: boolean;

  constructor(private authService: AuthService, private uploadMedia: UploadMediaService, private _router: Router,
    private companyService: CompanyService, private masterService: MasterService, private _location: Location,
    private packageService: CompanyPackageService, private tost: ToastrService, private routerLink: Router) {

  }

  ngOnInit(): void {

  }

  submit() {
    if (this.selectedPackage?.packageId != this.package.id) {
      if (this.enroll && this.selectedPackage?.packageId == 0) {

        let pack: CompanyPackageModel = {
          id: 0,
          fromDate: null,
          toDate: null,
          companyId: this.authService.getAuthStorage().companyId,
          packageId: this.package.id,
        }
        this.packageService.create(pack).subscribe((data) => {
          this.tost.success("Enrollment Successfully");
          this._location.back();
        })
      } else {
        if (!this.enroll) {

          this.routerLink.navigate(["/packages"]);
        } else {

          this.tost.info("Please contact administrator for change in package");
        }
      }
    } else {
    }
  }

  openModal() {
    $("#sign_up_popup").modal('show');
  }

  route() {
    $("#sign_up_popup").modal('hide');
    this._router.navigateByUrl('/contact-us')
  }


}
