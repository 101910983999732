<div class="pricing-table-1">
    <div class="p-table-title">
        <h4 class="wt-title">
            {{package.name}}
        </h4>
        <!-- <h4 class="wt-title mt-3">
            ${{package.price}}
            <p>Monthly</p>
        </h4> -->
    </div>
    <div class="p-table-price">
        <p class="package-price">Price: ${{package.price}}/Monthly</p>

    </div>
    <div class="p-table-title">
        <h4 class="wt-title change-font">
            Allowed job post
        </h4>
        <!-- <h4 class="wt-title mt-3">
            ${{package.price}}
            <p>Monthly</p>
        </h4> -->
    </div>
    <div class="p-table-price">
        <p>{{package.jobPostAllowed}} Job post</p>

    </div>
    <div class="p-table-title">
        <h4 class="wt-title change-font">
            Total Candidates Shortlist
        </h4>
        <!-- <h4 class="wt-title mt-3">
            ${{package.price}}
            <p>Monthly</p>
        </h4> -->
    </div>
    <div class="p-table-price">
        <p>{{package.candidatesShortlist}} Cadidates</p>

    </div>
    <div class="p-table-title">
        <h4 class="wt-title">
            Description
        </h4>
        <!-- <h4 class="wt-title mt-3">
            ${{package.price}}
            <p>Monthly</p>
        </h4> -->
    </div>
    <div class="p-table-list">
        <ul>
            <div><i class="feather-check"></i>{{package.featureList}}</div>
            <!-- <li class="disable"><i class="feather-x"></i>0 featured job</li>
            <li class="disable"><i class="feather-x"></i>job displayed fo 20 days</li>
            <li class="disable"><i class="feather-x"></i>Premium support 24/7</li> -->
        </ul>
    </div>
    <div class="p-table-inner mt-1 d-flex">

        <div class="p-table-btn">
            <a (click)="submit()" class="site-button"
                [ngStyle]="{'background-color':selectedPackage?.packageId != package.id?'':'green'}">{{selectedPackage?.packageId
                != package.id? 'Purchase Now':'Enrolled'}}</a>
        </div>
        <div class="p-table-btn ms-3" *ngIf="selectedPackage?.packageId == package.id">
            <a (click)="openModal()" class="site-button">Change</a>
        </div>
    </div>
</div>
<div [class]="'modal fade twm-sign-up'" id="sign_up_popup" aria-hidden="true" aria-labelledby="sign_up_popupLabel"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">

        <div class="modal-content">
            <div>
                <div class="modal-header">
                    <h4 class="modal-title mandatory" id="sign_up_popupLabel">
                        Plan Change
                    </h4>
                    <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="twm-tabs-style-2">
                        <div class="row">
                            <h5>In order to change you plan please contact with our executive from the contact page. Our
                                team is dedicated to solve your problem and will respond within 24 hrs</h5>

                        </div>
                    </div>
                </div>


            </div>

            <div class="modal-footer">
                <button class="site-button " (click)="route()">Change Plan</button>
            </div>

        </div>
    </div>
</div>