import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { JobSeekerProffDetailModel } from 'src/app/model/JobSeekerProffDetail';
import { AvailabilityModel } from 'src/app/model/master/Availability';
import { CourseModel } from 'src/app/model/master/Course';
import { FunctionalAreaModel } from 'src/app/model/master/FunctionalArea';
import { IndustryModel } from 'src/app/model/master/Industry';
import { WorkLevelModel } from 'src/app/model/master/WorkLevel';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { JobSeekerProffDetailService } from 'src/app/services/jobProfessionalDetail.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';

@Component({
  selector: 'app-job-seeker-prof-detail',
  templateUrl: './job-seeker-prof-detail.component.html',
  styleUrls: ['./job-seeker-prof-detail.component.scss']
})
export class JobSeekerProfDetailComponent implements OnInit {
  @Input() candId: number = 0;
  availabilityModel: AvailabilityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  availabilityModelList: Array<AvailabilityModel> = []

  workLevelModel: WorkLevelModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  workLevelModelList: Array<WorkLevelModel> = []

  industry: IndustryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  industryList: Array<IndustryModel> = []

  functionalArea: FunctionalAreaModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    count: 0
  }
  functionalAreaList: Array<FunctionalAreaModel> = []

  courseModel: CourseModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  courseModelList: Array<CourseModel> = []

  jobSeekerProffDetailModel: JobSeekerProffDetailModel = {
    id: 0,
    jobSeekerId: 0,
    industryId: 0,
    functionalAreaId: 0,
    workLevelId: 0,
    courseId: 0,
    availabilityToJoinId: 0,
    totalWorkExperienceYear: 0,
    totalWorkExperienceMonth: 0,
    monthlySalary: 0,
  }
  jobSeekerProffDetailModelList: string[] = ['industryId', 'functionalAreaId', 'workLevelId', 'courseId', 'availabilityToJoinId', , 'totalWorkExperienceYear', 'totalWorkExperienceMonth']


  yearList: number[] = [];
  monthList: number[] = [];
  submit: boolean = false;

  configSelect = {
    displayFn: (item: any) => { return item.nameEn; },
    displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    searchOnKey: 'nameEn',
    limitTo: 0,
    height: "231px",
    enableSelectAll: true,
  };

  configStatic = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };
  configStaticNoSearch = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: false,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  // End


  isSubmit: boolean = false;

  constructor(private authService: AuthService, private uploadMedia: UploadMediaService,
    private companyService: CompanyService, public masterService: MasterService,
    private jobSeekerProffDetailService: JobSeekerProffDetailService, private tost: ToastrService, private jobSeekerSrv: JobSeekerService) {

  }
  ngOnInit(): void {
    this.yearList = this.masterService.yearList();
    this.monthList = this.masterService.monthList();
    this.jobSeekerProffDetailModel.jobSeekerId = this.candId;
    this.get();
    this.masterService.getWorkLevelAll().subscribe((data) => {
      this.workLevelModelList = data;
    });
    this.masterService.getAvailibilityAll().subscribe((data) => {
      this.availabilityModelList = data;
    });
    this.masterService.getCourseAll().subscribe((data) => {
      this.courseModelList = data;
    });
    this.masterService.getIndustryAll().subscribe((data) => {
      this.industryList = data;
    });
    this.masterService.getfunctionalAreaAll().subscribe((data) => {
      this.functionalAreaList = data;
    });
    this.masterService.getCourseAll().subscribe((data) => {
      this.courseModelList = data;
    });
  }

  assignCourse(id: Number) {
    this.jobSeekerProffDetailModel.courseId = id;
  }
  assignAvalibility(id: Number) {
    this.jobSeekerProffDetailModel.availabilityToJoinId = id;
  }
  assignIndustry(id: Number) {
    this.jobSeekerProffDetailModel.industryId = id;
  }
  assignFunctionalArea(id: Number) {
    this.jobSeekerProffDetailModel.functionalAreaId = id;
  }
  assignWorkLevel(id: Number) {
    this.jobSeekerProffDetailModel.workLevelId = id;
  }

  selectedObject(list, val) {
    return list.find(n => n.id == val);
  }

  saveProfessionalDetails() {
    console.log(this.jobSeekerProffDetailModel.totalWorkExperienceYear);
    if (this.jobSeekerProffDetailModel.totalWorkExperienceYear == null || this.jobSeekerProffDetailModel.totalWorkExperienceMonth == null || this.jobSeekerProffDetailModel.monthlySalary == null) {
      this.isSubmit = true;
      return;
    }

    if (this.masterService.validate(this.jobSeekerProffDetailModelList, this.jobSeekerProffDetailModel)) {
      //Api call
      if (this.jobSeekerProffDetailModel.id == 0) {
        this.jobSeekerProffDetailService.create(this.jobSeekerProffDetailModel).subscribe((data) => {
          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerProffDetailService.update(this.jobSeekerProffDetailModel).subscribe((data) => {
          this.tost.success("Operation Succeeded");
        });
      }
    } else {
      this.isSubmit = true;
    }
  }

  get() {
    this.jobSeekerProffDetailService.getByJobSeekerId(this.jobSeekerProffDetailModel.jobSeekerId).subscribe((data) => {
      this.jobSeekerProffDetailModel = data;
    });
  }

}
