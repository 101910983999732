import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { JobService } from 'src/app/services/job.service';
import { PeriodicElement } from '../employer-managejob/employer-managejob.component';
import { Dashboard } from 'src/app/model/Dashboard';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-employer-dashboard',
  templateUrl: './employer-dashboard.component.html',
  styleUrls: ['./employer-dashboard.component.scss']
})
export class EmployerDashboardComponent implements OnInit {
  active:string
  postedJob:Number = 0;
  name:string = "";
  dashboard:Dashboard = {
    jobPosted: 0,
    jobViews: 0,
    candidateApplied: 0
  }
  constructor(private _liveAnnouncer: LiveAnnouncer, private jobSrv:JobService,private authService:AuthService,
    private companySrv:CompanyService) {}
  ngOnInit(): void {
    this.getJobList();
    this.getDashboard();
  }

  toggleActive(classS:string){
    console.log(classS);
    this.active = classS;
  }

  getJobList(){
    this.name = this.authService.getAuthStorage().username
    console.log(this.authService.getAuthStorage(),"sdmsdksd")
    this.jobSrv.getByCompanyId(this.authService.getAuthStorage().companyId).subscribe((data) => {
      this.authService.saveCompanyName(data["nameEn"]);
      this.postedJob = data.length;
    });
  }

  getDashboard(){
    this.name = this.authService.getAuthStorage().username
    this.companySrv.getDashboard(this.authService.getAuthStorage().companyId).subscribe((data) => {
      this.dashboard = data;
    });
  }

}
