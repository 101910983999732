import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadMediaService {
  private mediapath = environment.apiUrl + 'UploadMedia/';

  constructor(private http: HttpClient) { }

  uploadProfile(data: any): Observable<any> {
    return this.http.post<any>(this.mediapath + 'UploadProfile', data);
  }

  uploadProfileCover(data: any): Observable<any> {
    return this.http.post<any>(this.mediapath + 'UploadProfileCover', data);
  }

  uploadProfileCompanyLogo(data: any): Observable<any> {
    return this.http.post<any>(this.mediapath + 'UploadProfileCompanyLogoBase64', data);
  }

  uploadCv(data: any): Observable<any> {
    return this.http.post<any>(this.mediapath + 'UploadCv', data);
  }
}
