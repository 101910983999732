<div class="page-content" style="transform: none;">



    <!-- OUR BLOG START -->
    <div class="section-full p-t12  p-b90 site-bg-white" style="transform: none;">


        <!-- <app-loading *ngIf="loader.loader"></app-loading> -->


        <div class="container" style="transform: none;">

            <div class="wt-admin-right-page-header clearfix">
                <h2>Company Users</h2>
                <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>Company Users</span></div>
            </div>
            <div class="row" style="transform: none;">


                <div class="col-lg-12 col-md-12">
                    <!--Filter Short By-->

                    <div class="product-filter-wrap d-flex justify-content-between align-items-center m-b30">



                        <form class="woocommerce-ordering twm-filter-select" method="get">
                            <!-- <span class="woocommerce-result-count">Short By</span> -->
                            <!-- <div class="dropdown bootstrap-select wt-select-bar-2"><select class="wt-select-bar-2 selectpicker" data-live-search="true" data-bv-field="size">
                                <option>Most Recent</option>
                                <option>Freelance</option>
                                <option>Full Time</option>
                                <option>Internship</option>
                                <option>Part Time</option>
                                <option>Temporary</option>
                            </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-2" aria-haspopup="listbox" aria-expanded="false" title="Most Recent"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">Most Recent</div></div> </div></button><div class="dropdown-menu "><div class="bs-searchbox"><input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-2" aria-autocomplete="list"></div><div class="inner show" role="listbox" id="bs-select-2" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div> -->
                            <!-- <div class="dropdown bootstrap-select wt-select-bar-2"><select class="wt-select-bar-2 selectpicker" data-live-search="true" data-bv-field="size">
                                <option>Show 10</option>
                                <option>Show 20</option>
                                <option>Show 30</option>
                                <option>Show 40</option>
                                <option>Show 50</option>
                                <option>Show 60</option>
                            </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-3" aria-haspopup="listbox" aria-expanded="false" title="Show 10"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">Show 10</div></div> </div></button><div class="dropdown-menu "><div class="bs-searchbox"><input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-3" aria-autocomplete="list"></div><div class="inner show" role="listbox" id="bs-select-3" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div> -->
                        </form>

                    </div>

                    <div class="twm-jobs-list-wrap">
                        <div class="row">
                            <div class="col-11">

                                <span class="woocommerce-result-count-left">Showing total of {{companyUserList.length}}
                                    candidate</span>
                            </div>
                            <div class="col-1">

                                <button class="site-button button-sm  float-right" type="submit"
                                    data-bs-target="#sign_up_popup1" data-bs-toggle="modal" data-bs-dismiss="modal"> Add
                                    user</button>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                        </div>
                        <ul>
                            <li *ngFor="let item of companyUserList | paginate: { itemsPerPage: 7, currentPage: p }">
                                <app-company-user-widget [companyUser]="item"></app-company-user-widget>
                            </li>
                        </ul>
                        <div class="pagination-outer">
                            <div class="pagination-style1">
                                <pagination-controls (pageChange)="paginate($event)"></pagination-controls>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="pagination-outer">
                        <div class="pagination-style1">
                            <ul class="clearfix">
                                <li class="prev"><a href="javascript:;"><span> <i class="fa fa-angle-left"></i> </span></a></li>
                                <li><a href="javascript:;">1</a></li>
                                <li class="active"><a href="javascript:;">2</a></li>
                                <li><a href="javascript:;">3</a></li>
                                <li><a class="javascript:;" href="javascript:;"><i class="fa fa-ellipsis-h"></i></a></li>
                                <li><a href="javascript:;">5</a></li>
                                <li class="next"><a href="javascript:;"><span> <i class="fa fa-angle-right"></i> </span></a></li>
                            </ul>
                        </div>
                    </div> -->

                </div>

            </div>
        </div>
        <div class="modal fade twm-sign-up" id="sign_up_popup1" aria-hidden="true" aria-labelledby="sign_up_popupLabel"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                    <div>

                        <div class="modal-header">
                            <h4 class="modal-title" id="sign_up_popupLabel">User</h4>
                            <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">
                            <div class="twm-tabs-style-2">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Full Name:</label>
                                            <div class="ls-inputicon-box">
                                                <input type="text" class="form-control" placeholder="Rahul"
                                                    [(ngModel)]="companyUser.fullName">
                                                <span class="mandatory"
                                                    *ngIf="companyUser.fullName == '' && submitEducation">This
                                                    field is requied</span>
                                                <i class="fs-input-icon fas fa-at"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Email:</label>
                                            <div class="ls-inputicon-box">
                                                <input type="email" class="form-control" placeholder="rahul@mahad.com"
                                                    [(ngModel)]="companyUser.emailId">
                                                <span class="mandatory"
                                                    *ngIf="companyUser.emailId == '' && submitEducation">This
                                                    field is requied</span>
                                                <i class="fs-input-icon fas fa-at"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Password:</label>
                                            <div class="ls-inputicon-box">
                                                <input type="password" class="form-control" placeholder="Password"
                                                    [(ngModel)]="companyUser.password">
                                                <span class="mandatory"
                                                    *ngIf="companyUser.password == '' && submitEducation">This
                                                    field is requied</span>
                                                <i class="fs-input-icon fas fa-at"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Mobile Number:</label>
                                            <div class="ls-inputicon-box">
                                                <input type="text" class="form-control" placeholder="971504893833"
                                                    [(ngModel)]="companyUser.mobileNo" minlength="4" maxlength="13">
                                                <span class="mandatory"
                                                    *ngIf="companyUser.mobileNo == '' && submitEducation">This
                                                    field is requied</span>
                                                <i class="fs-input-icon fas fa-at"></i>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">Role:</label>
                                                <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                    <div _ngcontent-sva-c15=""
                                                        class="dropdown bootstrap-select wt-select-box">
                                                        <ngx-select-dropdown [(ngModel)]="companyUser.role"
                                                            [config]="configStatic" [instanceId]="'instance21'"
                                                            [multiple]="false"
                                                            [options]="['admin','user']"></ngx-select-dropdown>
                                                        <!-- <select _ngcontent-sva-c15="" name="team-size"
                                                            data-live-search="true" title="team-size" id="city"
                                                            data-bv-field="size" class="wt-select-box selectpicker"
                                                            style="display: none;">
                                                            <option value="admin">admin</option>
                                                            <option value="user">user</option>
                                                        </select> -->
                                                        <!-- <div class="nice-select wt-select-box selectpicker"
                                                            tabindex="0">
                                                            <span class="current"></span>
                                                            <ul class="list">
                                                                <li class="option" (click)="assignRole('admin')">
                                                                    admin</li>
                                                                <li class="option" (click)="assignRole('user')">
                                                                    user</li>
                                                            </ul>
                                                        </div> -->
                                                        <button _ngcontent-sva-c15="" type="button" tabindex="-1"
                                                            data-bs-toggle="dropdown" role="combobox"
                                                            aria-owns="bs-select-1" aria-haspopup="listbox"
                                                            aria-expanded="false" title="team-size" data-id="city"
                                                            class="btn dropdown-toggle bs-placeholder btn-light">
                                                            <div _ngcontent-sva-c15="" class="filter-option">
                                                                <div _ngcontent-sva-c15="" class="filter-option-inner">
                                                                    <div _ngcontent-sva-c15=""
                                                                        class="filter-option-inner-inner">
                                                                        team-size
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                        <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                            <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                <input _ngcontent-sva-c15="" type="search"
                                                                    autocomplete="off" role="combobox"
                                                                    aria-label="Search" aria-controls="bs-select-1"
                                                                    aria-autocomplete="list" class="form-control">
                                                            </div>
                                                            <div _ngcontent-sva-c15="" role="listbox" id="bs-select-1"
                                                                tabindex="-1" class="inner show">
                                                                <ul _ngcontent-sva-c15="" role="presentation"
                                                                    class="dropdown-menu inner show"></ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <mat-icon _ngcontent-sva-c15="" role="img"
                                                        class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                        aria-hidden="true"
                                                        data-mat-icon-type="font">translate</mat-icon> -->
                                                </div>
                                            </div>
                                            <span class="mandatory"
                                                *ngIf="companyUser.role == '' && submitEducation">This
                                                field is requied</span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 mt-3">
                                        <button type="submit" class="site-button" (click)="addRole()"
                                            data-bs-dismiss="modal">Save</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!-- <div class="modal-footer">
                                    <span class="modal-f-title">Login or Sign up with</span>
                                    <ul class="twm-modal-social">
                                        <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                                    </ul>
                                </div> -->

                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- OUR BLOG END -->



</div>