import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PackageModel } from 'src/app/model/master/Package';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { JobService } from 'src/app/services/job.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  constructor(private authService: AuthService, private uploadMedia: UploadMediaService,
    private companyService: CompanyService, private masterService: MasterService,
    private jobService: JobService, private tost: ToastrService, private jobSeekerSrv: JobSeekerService) {

  }

  package: PackageModel = {
    id: 0,
    name: '',
    description: '',
    price: 0,
    featureList: '',
    jobPostAllowed: 0,
    candidatesShortlist: 0
  }
  packageList: Array<PackageModel> = [];
  ngOnInit(): void {
    this.masterService.getPackageAll().subscribe((data) => {
      this.packageList = data;
    });
  }

}
