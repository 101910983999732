import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ChangePwdModel } from 'src/app/model/ChangePwd';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-change-pwd',
  templateUrl: './change-pwd.component.html',
  styleUrls: ['./change-pwd.component.scss']
})
export class ChangePwdComponent implements OnInit {
  checkPwd: string = "";
  changePwd: ChangePwdModel = {
    email: '',
    oldPassword: '',
    newPassword: ''
  }
  changePwdMandatory = [
    "email", "oldPassword", "newPassword"
  ]
  submit: boolean = false;
  constructor(
    private authService: AuthService,
    private tost: ToastrService,
    private masterService: MasterService,
    private spinner: NgxSpinnerService,
    public loader: LoaderService,
  ) {
    this.loader.loader = false;

  }
  ngOnInit(): void {
    let authStore = this.authService.getAuthStorage();
    this.changePwd.email = authStore.email;
  }
  onSubmit() {
    this.submit = true;
    if (this.checkPwd == this.changePwd.newPassword && this.masterService.validate(this.changePwdMandatory, this.changePwd)) {
      this.loader.loader = true;
      this.authService.changePwd(this.changePwd).subscribe((data) => {
        if (data.isSuccess) {
          this.tost.success("Password changed successfully");
          this.loader.loader = false;
          window.location.href = "/employer-dashboard"
        } else {
          this.tost.error(data.message);
          this.loader.loader = false;
        }
      })
      this.submit = false;
    }
  }
}
