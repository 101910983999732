<div class="page-wraper mt-0">

    <!-- <app-navbar-style-one></app-navbar-style-one> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">


        <!-- Candidate Detail V2 START -->
        <div class="section-full p-b90 bg-white">
            <div class="twm-candi-self-wrap-2 overlay-wraper"
                style="background-image:url(images/candidates/candidate-bg2.jpg);">
                <div class="twm-mid-content">

                    <h3 class="twm-job-title">Profile of {{jobSeekerModel.fullNameEn}} </h3>

                    <!-- <p>Senior UI / UX Designer and Developer at Google INC</p>
                    <p class="twm-candidate-address"><i class="feather-map-pin"></i>United States</p> -->

                </div>
                <div class="overlay-main site-bg-primary opacity-01"></div>
                <div class="container">
                    <div class="twm-candi-self-info-2">
                        <div class="twm-candi-self-top">
                            <div class="twm-candi-fee">Profile</div>
                            <div *ngIf="packageExist" class="twm-candi-fee mt-5 color" (click)="toggleShortlist()">{{!isShortlisted?
                                '+ ShortList':"X Remove"}}</div>
                            <div class="twm-media">
                                <img *ngIf="!error" [src]="urlUser" alt="" (error)="imageError($event)">
                                <span *ngIf="error"> <i class="fa fa-user i"></i></span>
                            </div>
                            <div class="upload-btn-wrapper">
                                <div id="upload-image-grid1"></div>
                                <!-- <button class="site-button button-sm">Upload Photo</button> -->

                            </div>
                            <div class="twm-mid-content">


                                <h4 *ngIf="packageExist" class="twm-job-title">{{jobSeekerModel.eamil}} </h4>
                                <h4 *ngIf="!packageExist" class="twm-job-title">ma**********@y***** </h4>

                                <!-- <p>Senior UI / UX Designer and Developer at Google INC</p>
                                <p class="twm-candidate-address"><i class="feather-map-pin"></i>United States</p> -->

                            </div>
                        </div>
                        <div class="row" *ngIf="packageExist">
                            <div class="twm-candi-self-bottom col-6">
                                <a  [href]="url" target="_blank"
                                    class="site-button button-sm z-index">Preview CV</a>
                                <div class="upload-btn-wrapper">
                                    <div id="upload-image-grid"></div>
                                    <!-- <a  class="site-button secondry">Upload CV</a>
                                <input type="file" name="myfile" id="" accept=".pdf" (change)="handleUpload($event)"> -->
                                </div>
                            </div>
                            <div *ngIf="(jobSeekerModel.coverLetterPath !='' && jobSeekerModel.coverLetterPath != null)"
                                class="twm-candi-self-bottom col-6">
                                <a [href]="urlCl" target="_blank" class="site-button button-sm ms-auto">Cover Letter</a>
                                <div class="upload-btn-wrapper">
                                    <div id="upload-image-grid"></div>
                                    <!-- <a  class="site-button secondry">Upload CV</a>
                                <input type="file" name="myfile" id="" accept=".pdf" (change)="handleUpload($event)"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <mat-tab-group dynamicHeight>
                <mat-tab label="First"> Content 1 </mat-tab>
                <mat-tab label="Second"> Content 2 </mat-tab>
                <mat-tab label="Third"> Content 3 </mat-tab>
              </mat-tab-group> -->
            <div class="container" *ngIf="true">


                <div class="section-content">
                    <div class="row d-flex justify-content-center">

                        <div class="col-lg-11 col-md-12">
                            <mat-tab-group dynamicHeight>
                                <mat-tab label="Profile">
                                    <div class="cabdidate-de-info">

                                        <div class="twm-s-info-wrap mb-5">
                                            <h4 class="section-head-small mb-4">Profile Info</h4>
                                            <div class="twm-s-info-4">
                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label>Full name:</label>
                                                            <div class="ls-inputicon-box">
                                                                <input type="text" class="form-control" placeholder="3"
                                                                    [(ngModel)]="jobSeekerModel.fullNameEn" disabled>
                                                                <span class="mandatory"
                                                                    *ngIf="jobSeekerModel.fullNameEn == '' && submitProfileInfo">This
                                                                    field is requied</span>
                                                                <i class="fs-input-icon fas fa-at"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label>Full name (Ar):</label>
                                                            <div class="ls-inputicon-box">
                                                                <input type="text" class="form-control" placeholder="3"
                                                                    [(ngModel)]="jobSeekerModel.fullNameAr" disabled>
                                                                <span class="mandatory"
                                                                    *ngIf="jobSeekerModel.fullNameAr == '' && submitProfileInfo">This
                                                                    field is requied</span>
                                                                <i class="fs-input-icon fas fa-at"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label>Email:</label>
                                                            <div class="ls-inputicon-box">

                                                                <input *ngIf="!packageExist" type="email"
                                                                    class="form-control" placeholder="ma********@g*****"
                                                                    disabled>
                                                                <i (click)="msg()" *ngIf="!packageExist"
                                                                    class="fs-input-icon fas fa-eye"></i>
                                                                <input *ngIf="packageExist" type="email"
                                                                    class="form-control" placeholder="m@m.com"
                                                                    [(ngModel)]="jobSeekerModel.eamil" disabled>
                                                                <span class="mandatory"
                                                                    *ngIf="jobSeekerModel.eamil == '' && submitProfileInfo">This
                                                                    field is requied</span>
                                                                <i *ngIf="packageExist"
                                                                    class="fs-input-icon fas fa-at"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label>Mobile:</label>
                                                            <div class="ls-inputicon-box">
                                                                <input *ngIf="!packageExist" type="text"
                                                                    class="form-control" placeholder="+9*********13"
                                                                    disabled>
                                                                <i (click)="msg()" *ngIf="!packageExist"
                                                                    class="fs-input-icon fas fa-eye"></i>

                                                                <input *ngIf="packageExist" type="text"
                                                                    class="form-control" placeholder="+9713838484838"
                                                                    [(ngModel)]="jobSeekerModel.mobileNo" disabled>
                                                                <span class="mandatory"
                                                                    *ngIf="jobSeekerModel.mobileNo == '' && submitProfileInfo">This
                                                                    field is requied</span>
                                                                <i *ngIf="packageExist"
                                                                    class="fs-input-icon fas fa-at"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label>Current City:</label>
                                                            <div class="ls-inputicon-box">
                                                                <input type="text" class="form-control"
                                                                    placeholder="+9713838484838"
                                                                    [(ngModel)]="jobSeekerModel.currentCity" disabled>
                                                                <span class="mandatory"
                                                                    *ngIf="jobSeekerModel.currentCity == '' && submitProfileInfo">This
                                                                    field is requied</span>
                                                                <i class="fs-input-icon fas fa-at"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                                        <div class="form-group">
                                                            <label>CV Headline:</label>
                                                            <div class="ls-inputicon-box">
                                                                <input type="text" class="form-control"
                                                                    placeholder="yo bro"
                                                                    [(ngModel)]="jobSeekerModel.cvheadline" disabled>
                                                                <span class="mandatory"
                                                                    *ngIf="jobSeekerModel.cvheadline == '' && submitProfileInfo">This
                                                                    field is requied</span>
                                                                <i class="fs-input-icon fas fa-at"></i>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!--         
                                                    <div class="col-md-6">
                                                        <div class="twm-s-info-inner">
                                                            <i class="fas fa-money-bill-wave"></i>
                                                            <span class="twm-title">Offered Salary</span>
                                                            <div class="twm-s-info-discription">$20 / Day</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="twm-s-info-inner">
                                                            <i class="fas fa-clock"></i>
                                                            <span class="twm-title">Experience</span>
                                                            <div class="twm-s-info-discription">6 Year</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="twm-s-info-inner">
                                                            <i class="fas fa-venus-mars"></i>
                                                            <span class="twm-title">Gender</span>
                                                            <div class="twm-s-info-discription">Male</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="twm-s-info-inner">
                                                            <i class="fas fa-mobile-alt"></i>
                                                            <span class="twm-title">Phone</span>
                                                            <div class="twm-s-info-discription">+291  560 56456</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="twm-s-info-inner">
                                                            <i class="fas fa-at"></i>
                                                            <span class="twm-title">Email</span>
                                                            <div class="twm-s-info-discription">thewebmaxdemo@gmail.com</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="twm-s-info-inner">
                                                            <i class="fas fa-book-reader"></i>
                                                            <span class="twm-title">Qualification</span>
                                                            <div class="twm-s-info-discription">MCA</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="twm-s-info-inner">
                                                            
                                                            <i class="fas fa-map-marker-alt"></i>
                                                            <span class="twm-title">Address</span>
                                                            <div class="twm-s-info-discription">1363-1385 Sunset Blvd Angeles, CA 90026 ,USA</div>
                                                        </div>
                                                    </div> -->

                                                </div>

                                                <!-- <button class="btn site-button mt-3" (click)="saveProfileInfo()">Save</button> -->
                                            </div>
                                        </div>

                                    </div>
                                </mat-tab>
                                <mat-tab label="Personal information">
                                    <div class="twm-s-info-wrap mb-5">
                                        <h4 class="section-head-small mb-4">Personal Information</h4>
                                        <div class="twm-s-info-4">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Full name:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="text" class="form-control" placeholder="3"
                                                                [(ngModel)]="jobSeekerModel.fullNameEn" disabled>
                                                            <span class="mandatory"
                                                                *ngIf="jobSeekerModel.fullNameEn == '' && submitPersonalDetails">This
                                                                field is requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Full name (Ar):</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="text" class="form-control" placeholder="3"
                                                                [(ngModel)]="jobSeekerModel.fullNameAr" disabled>
                                                            <span class="mandatory"
                                                                *ngIf="jobSeekerModel.fullNameAr == '' && submitPersonalDetails">This
                                                                field is requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>DOB:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="date" class="form-control" placeholder=""
                                                                [(ngModel)]="jobSeekerPersonalDetailModel.dob" disabled>
                                                            <span class="mandatory"
                                                                *ngIf="jobSeekerPersonalDetailModel.dob == '' && submitPersonalDetails">This
                                                                field is requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Gender:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="text" class="form-control" placeholder="Female"
                                                                [(ngModel)]="jobSeekerPersonalDetailModel.gender"
                                                                disabled>
                                                            <span class="mandatory"
                                                                *ngIf="jobSeekerPersonalDetailModel.gender == '' && submitPersonalDetails">This
                                                                field is requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <div _ngcontent-sva-c15=""
                                                            class="form-group city-outer-bx has-feedback">
                                                            <label _ngcontent-sva-c15="">Martial Status:</label>
                                                            <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="dropdown bootstrap-select wt-select-box">
                                                                    <!-- <select _ngcontent-sva-c15="" name="team-size"
                                                                        data-live-search="true" title="team-size"
                                                                        id="city" data-bv-field="size"
                                                                        class="wt-select-box selectpicker"
                                                                        style="display: none;">
                                                                        <option
                                                                            *ngFor="let city of martialStatusModelList"
                                                                            [data-value]="city.id">{{city.nameEn}}
                                                                        </option>
                                                                    </select> -->
                                                                    <div class="nice-select wt-select-box selectpicker"
                                                                        tabindex="0">
                                                                        <span
                                                                            class="current">{{jobSeekerPersonalDetailModel.maritalStatus?.nameEn}}</span>
                                                                        <!-- <ul class="list">
                                                                            <li *ngFor="let city of martialStatusModelList"
                                                                                [data-value]="city.nameEn"
                                                                                class="option"
                                                                                (click)="assignMartialStatus(city.id)">
                                                                                {{city.nameEn}}</li> </ul> -->
                                                                    </div>
                                                                    <button _ngcontent-sva-c15="" type="button"
                                                                        tabindex="-1" data-bs-toggle="dropdown"
                                                                        role="combobox" aria-owns="bs-select-1"
                                                                        aria-haspopup="listbox" aria-expanded="false"
                                                                        title="team-size" data-id="city"
                                                                        class="btn dropdown-toggle bs-placeholder btn-light">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option">
                                                                            <div _ngcontent-sva-c15=""
                                                                                class="filter-option-inner">
                                                                                <div _ngcontent-sva-c15=""
                                                                                    class="filter-option-inner-inner">
                                                                                    team-size</div>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                        <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                            <input _ngcontent-sva-c15="" type="search"
                                                                                autocomplete="off" role="combobox"
                                                                                aria-label="Search"
                                                                                aria-controls="bs-select-1"
                                                                                aria-autocomplete="list"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div _ngcontent-sva-c15="" role="listbox"
                                                                            id="bs-select-1" tabindex="-1"
                                                                            class="inner show">
                                                                            <ul _ngcontent-sva-c15=""
                                                                                role="presentation"
                                                                                class="dropdown-menu inner show">
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div><mat-icon _ngcontent-sva-c15="" role="img"
                                                                    class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                                    aria-hidden="true"
                                                                    data-mat-icon-type="font">translate</mat-icon>
                                                            </div>
                                                        </div>
                                                        <span class="mandatory"
                                                            *ngIf="jobSeekerPersonalDetailModel.maritalStatusId == 0 && submitPersonalDetails">This
                                                            field is requied</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <div _ngcontent-sva-c15=""
                                                            class="form-group city-outer-bx has-feedback">
                                                            <label _ngcontent-sva-c15="">Visa Status:</label>
                                                            <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="dropdown bootstrap-select wt-select-box">
                                                                    <!-- <select _ngcontent-sva-c15="" name="team-size"
                                                                        data-live-search="true" title="team-size"
                                                                        id="city" data-bv-field="size"
                                                                        class="wt-select-box selectpicker"
                                                                        style="display: none;">
                                                                        <option *ngFor="let city of visaStatusModelList"
                                                                            [data-value]="city.id">{{city.nameEn}}
                                                                        </option>
                                                                    </select> -->
                                                                    <div class="nice-select wt-select-box selectpicker"
                                                                        tabindex="0">
                                                                        <span
                                                                            class="current">{{jobSeekerPersonalDetailModel.visaStatus?.nameEn}}</span>
                                                                        <!-- <ul class="list">
                                                                            <li *ngFor="let city of visaStatusModelList"
                                                                                [data-value]="city.nameEn"
                                                                                class="option"
                                                                                (click)="assignVisaId(city.id)">
                                                                                {{city.nameEn}}</li> </ul> -->
                                                                    </div>
                                                                    <button _ngcontent-sva-c15="" type="button"
                                                                        tabindex="-1" data-bs-toggle="dropdown"
                                                                        role="combobox" aria-owns="bs-select-1"
                                                                        aria-haspopup="listbox" aria-expanded="false"
                                                                        title="team-size" data-id="city"
                                                                        class="btn dropdown-toggle bs-placeholder btn-light">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option">
                                                                            <div _ngcontent-sva-c15=""
                                                                                class="filter-option-inner">
                                                                                <div _ngcontent-sva-c15=""
                                                                                    class="filter-option-inner-inner">
                                                                                    team-size</div>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                        <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                            <input _ngcontent-sva-c15="" type="search"
                                                                                autocomplete="off" role="combobox"
                                                                                aria-label="Search"
                                                                                aria-controls="bs-select-1"
                                                                                aria-autocomplete="list"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div _ngcontent-sva-c15="" role="listbox"
                                                                            id="bs-select-1" tabindex="-1"
                                                                            class="inner show">
                                                                            <ul _ngcontent-sva-c15=""
                                                                                role="presentation"
                                                                                class="dropdown-menu inner show">
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div><mat-icon _ngcontent-sva-c15="" role="img"
                                                                    class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                                    aria-hidden="true"
                                                                    data-mat-icon-type="font">translate</mat-icon>
                                                            </div>
                                                        </div>
                                                        <span class="mandatory"
                                                            *ngIf="jobSeekerPersonalDetailModel.visaStatusId == 0 && submitPersonalDetails">This
                                                            field is requied</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Alternative Email:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input *ngIf="!packageExist" type="email"
                                                                class="form-control" placeholder="ma********@g*****"
                                                                disabled>
                                                            <i (click)="msg()" *ngIf="!packageExist"
                                                                class="fs-input-icon fas fa-eye"></i>

                                                            <input *ngIf="packageExist" type="email"
                                                                class="form-control" placeholder="yo@bro.com"
                                                                [(ngModel)]="jobSeekerPersonalDetailModel.alternateEmailId"
                                                                disabled>
                                                            <span class="mandatory"
                                                                *ngIf="jobSeekerPersonalDetailModel.alternateEmailId == '' && submitPersonalDetails">This
                                                                field is requied</span>
                                                            <i *ngIf="packageExist" class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Alternative Mobile:</label>
                                                        <input *ngIf="!packageExist" type="text" class="form-control"
                                                            placeholder="+97********91" disabled>
                                                        <i (click)="msg()" *ngIf="!packageExist"
                                                            class="fs-input-icon fas fa-eye"></i>
                                                        <div class="ls-inputicon-box">
                                                            <input *ngIf="packageExist" type="text" class="form-control"
                                                                placeholder="+971373783833"
                                                                [(ngModel)]="jobSeekerPersonalDetailModel.alternateMobile"
                                                                disabled>
                                                            <span class="mandatory"
                                                                *ngIf="jobSeekerPersonalDetailModel.alternateMobile == '' && submitPersonalDetails">This
                                                                field is requied</span>
                                                            <i *ngIf="packageExist" class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <div _ngcontent-sva-c15=""
                                                            class="form-group city-outer-bx has-feedback">
                                                            <label _ngcontent-sva-c15="">Nationality:</label>
                                                            <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="dropdown bootstrap-select wt-select-box">
                                                                    <!-- <select _ngcontent-sva-c15="" name="team-size"
                                                                        data-live-search="true" title="team-size"
                                                                        id="city" data-bv-field="size"
                                                                        class="wt-select-box selectpicker"
                                                                        style="display: none;">
                                                                        <option *ngFor="let city of nationalityList"
                                                                            [data-value]="city.id">{{city.nameEn}}
                                                                        </option>
                                                                    </select> -->
                                                                    <div class="nice-select wt-select-box selectpicker"
                                                                        tabindex="0">
                                                                        <span
                                                                            class="current">{{jobSeekerPersonalDetailModel.nationality?.nameEn}}</span>
                                                                        <!-- <ul class="list">
                                                                            <li *ngFor="let city of nationalityList"
                                                                                [data-value]="city.nameEn"
                                                                                class="option"
                                                                                (click)="assignNationality(city.id)">
                                                                                {{city.nameEn}}</li> </ul> -->
                                                                    </div>
                                                                    <button _ngcontent-sva-c15="" type="button"
                                                                        tabindex="-1" data-bs-toggle="dropdown"
                                                                        role="combobox" aria-owns="bs-select-1"
                                                                        aria-haspopup="listbox" aria-expanded="false"
                                                                        title="team-size" data-id="city"
                                                                        class="btn dropdown-toggle bs-placeholder btn-light">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option">
                                                                            <div _ngcontent-sva-c15=""
                                                                                class="filter-option-inner">
                                                                                <div _ngcontent-sva-c15=""
                                                                                    class="filter-option-inner-inner">
                                                                                    team-size</div>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                        <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                            <input _ngcontent-sva-c15="" type="search"
                                                                                autocomplete="off" role="combobox"
                                                                                aria-label="Search"
                                                                                aria-controls="bs-select-1"
                                                                                aria-autocomplete="list"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div _ngcontent-sva-c15="" role="listbox"
                                                                            id="bs-select-1" tabindex="-1"
                                                                            class="inner show">
                                                                            <ul _ngcontent-sva-c15=""
                                                                                role="presentation"
                                                                                class="dropdown-menu inner show">
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div><mat-icon _ngcontent-sva-c15="" role="img"
                                                                    class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                                    aria-hidden="true"
                                                                    data-mat-icon-type="font">translate</mat-icon>
                                                            </div>
                                                        </div>
                                                        <span class="mandatory"
                                                            *ngIf="jobSeekerPersonalDetailModel.nationalityId == 0 && submitPersonalDetails">This
                                                            field is requied</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12"
                                                    *ngIf="jobSeekerPersonalDetailModel.isDrivingLicense">
                                                    <div class="form-group">
                                                        <div _ngcontent-sva-c15=""
                                                            class="form-group city-outer-bx has-feedback">
                                                            <label _ngcontent-sva-c15="">Driving License Issue
                                                                Country:</label>
                                                            <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="dropdown bootstrap-select wt-select-box">
                                                                    <!-- <select _ngcontent-sva-c15="" name="team-size"
                                                                        data-live-search="true" title="team-size"
                                                                        id="city" data-bv-field="size"
                                                                        class="wt-select-box selectpicker"
                                                                        style="display: none;">
                                                                        <option *ngFor="let city of countryModelList"
                                                                            [data-value]="city.id">{{city.nameEn}}
                                                                        </option>
                                                                    </select> -->
                                                                    <div class="nice-select wt-select-box selectpicker"
                                                                        tabindex="0">
                                                                        <span
                                                                            class="current">{{jobSeekerPersonalDetailModel.issuingCountry?.nameEn}}</span>
                                                                        <!-- <ul class="list">
                                                                            <li *ngFor="let city of countryModelList"
                                                                                [data-value]="city.nameEn"
                                                                                class="option"
                                                                                (click)="assignCountry(city.id)">
                                                                                {{city.nameEn}}</li> </ul> -->
                                                                    </div>
                                                                    <button _ngcontent-sva-c15="" type="button"
                                                                        tabindex="-1" data-bs-toggle="dropdown"
                                                                        role="combobox" aria-owns="bs-select-1"
                                                                        aria-haspopup="listbox" aria-expanded="false"
                                                                        title="team-size" data-id="city"
                                                                        class="btn dropdown-toggle bs-placeholder btn-light">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option">
                                                                            <div _ngcontent-sva-c15=""
                                                                                class="filter-option-inner">
                                                                                <div _ngcontent-sva-c15=""
                                                                                    class="filter-option-inner-inner">
                                                                                    team-size</div>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                        <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                            <input _ngcontent-sva-c15="" type="search"
                                                                                autocomplete="off" role="combobox"
                                                                                aria-label="Search"
                                                                                aria-controls="bs-select-1"
                                                                                aria-autocomplete="list"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div _ngcontent-sva-c15="" role="listbox"
                                                                            id="bs-select-1" tabindex="-1"
                                                                            class="inner show">
                                                                            <ul _ngcontent-sva-c15=""
                                                                                role="presentation"
                                                                                class="dropdown-menu inner show">
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div><mat-icon _ngcontent-sva-c15="" role="img"
                                                                    class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                                    aria-hidden="true"
                                                                    data-mat-icon-type="font">translate</mat-icon>
                                                            </div>
                                                        </div>
                                                        <span class="mandatory"
                                                            *ngIf="jobSeekerPersonalDetailModel.issuingCountryId == 0 && submitPersonalDetails">This
                                                            field is requied</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <div _ngcontent-sva-c15=""
                                                            class="form-group city-outer-bx has-feedback">
                                                            <label _ngcontent-sva-c15="">Current
                                                                Country:</label>
                                                            <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="dropdown bootstrap-select wt-select-box">
                                                                    <!-- <select _ngcontent-sva-c15="" name="team-size"
                                                                        data-live-search="true" title="team-size"
                                                                        id="city" data-bv-field="size"
                                                                        class="wt-select-box selectpicker" style="
                                                                            display: none;
                                                                        ">
                                                                        <option *ngFor="
                                                                                let city of countryModelList
                                                                            " [data-value]="
                                                                                city.id
                                                                            ">
                                                                            {{
                                                                            city.nameEn
                                                                            }}
                                                                        </option>
                                                                    </select> -->
                                                                    <div class="nice-select wt-select-box selectpicker"
                                                                        tabindex="0">
                                                                        <span class="current">{{
                                                                            jobSeekerPersonalDetailModel
                                                                            .currentCountry
                                                                            ?.nameEn
                                                                            }}</span>
                                                                        <!-- <ul class="list">
                                                                            <li *ngFor="
                                                                                    let city of countryModelList
                                                                                " [data-value]="
                                                                                    city.nameEn
                                                                                " class="option" (click)="
                                                                                    assignCurrCountry(
                                                                                        city.id
                                                                                    )
                                                                                ">
                                                                                {{
                                                                                city.nameEn
                                                                                }}
                                                                            </li> </ul> -->
                                                                    </div>
                                                                    <button _ngcontent-sva-c15="" type="button"
                                                                        tabindex="-1" data-bs-toggle="dropdown"
                                                                        role="combobox" aria-owns="bs-select-1"
                                                                        aria-haspopup="listbox" aria-expanded="false"
                                                                        title="team-size" data-id="city"
                                                                        class="btn dropdown-toggle bs-placeholder btn-light">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option">
                                                                            <div _ngcontent-sva-c15=""
                                                                                class="filter-option-inner">
                                                                                <div _ngcontent-sva-c15=""
                                                                                    class="filter-option-inner-inner">
                                                                                    team-size
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                        <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                            <input _ngcontent-sva-c15="" type="search"
                                                                                autocomplete="off" role="combobox"
                                                                                aria-label="Search"
                                                                                aria-controls="bs-select-1"
                                                                                aria-autocomplete="list"
                                                                                class="form-control" />
                                                                        </div>
                                                                        <div _ngcontent-sva-c15="" role="listbox"
                                                                            id="bs-select-1" tabindex="-1"
                                                                            class="inner show">
                                                                            <ul _ngcontent-sva-c15=""
                                                                                role="presentation"
                                                                                class="dropdown-menu inner show">
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <mat-icon _ngcontent-sva-c15="" role="img"
                                                                    class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                                    aria-hidden="true"
                                                                    data-mat-icon-type="font">translate</mat-icon>
                                                            </div>
                                                        </div>
                                                        <span class="mandatory" *ngIf="
                                                                jobSeekerPersonalDetailModel.currentCityId ==
                                                                    0 &&
                                                                submitPersonalDetails
                                                            ">This field
                                                            is
                                                            requied</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <div _ngcontent-sva-c15=""
                                                            class="form-group city-outer-bx has-feedback">
                                                            <label _ngcontent-sva-c15="">Current City:</label>
                                                            <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="dropdown bootstrap-select wt-select-box">
                                                                    <!-- <select _ngcontent-sva-c15="" name="team-size"
                                                                        data-live-search="true" title="team-size"
                                                                        id="city" data-bv-field="size"
                                                                        class="wt-select-box selectpicker"
                                                                        style="display: none;">
                                                                        <option *ngFor="let city of cityList"
                                                                            [data-value]="city.id">{{city.nameEn}}
                                                                        </option>
                                                                    </select> -->
                                                                    <div class="nice-select wt-select-box selectpicker"
                                                                        tabindex="0">
                                                                        <span
                                                                            class="current">{{jobSeekerPersonalDetailModel.currentCity?.nameEn}}</span>
                                                                        <!-- <ul class="list">
                                                                            <li *ngFor="let city of cityList"
                                                                                [data-value]="city.nameEn"
                                                                                class="option"
                                                                                (click)="assignCity(city.id)">
                                                                                {{city.nameEn}}</li></ul> -->
                                                                    </div>
                                                                    <button _ngcontent-sva-c15="" type="button"
                                                                        tabindex="-1" data-bs-toggle="dropdown"
                                                                        role="combobox" aria-owns="bs-select-1"
                                                                        aria-haspopup="listbox" aria-expanded="false"
                                                                        title="team-size" data-id="city"
                                                                        class="btn dropdown-toggle bs-placeholder btn-light">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option">
                                                                            <div _ngcontent-sva-c15=""
                                                                                class="filter-option-inner">
                                                                                <div _ngcontent-sva-c15=""
                                                                                    class="filter-option-inner-inner">
                                                                                    team-size</div>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                        <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                            <input _ngcontent-sva-c15="" type="search"
                                                                                autocomplete="off" role="combobox"
                                                                                aria-label="Search"
                                                                                aria-controls="bs-select-1"
                                                                                aria-autocomplete="list"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div _ngcontent-sva-c15="" role="listbox"
                                                                            id="bs-select-1" tabindex="-1"
                                                                            class="inner show">
                                                                            <ul _ngcontent-sva-c15=""
                                                                                role="presentation"
                                                                                class="dropdown-menu inner show">
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div><mat-icon _ngcontent-sva-c15="" role="img"
                                                                    class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                                    aria-hidden="true"
                                                                    data-mat-icon-type="font">translate</mat-icon>
                                                            </div>
                                                        </div>
                                                        <span class="mandatory"
                                                            *ngIf="jobSeekerPersonalDetailModel.currentCityId == 0 && submitPersonalDetails">This
                                                            field is requied</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <div _ngcontent-sva-c15=""
                                                            class="form-group city-outer-bx has-feedback">
                                                            <label _ngcontent-sva-c15="">Religion:</label>
                                                            <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="dropdown bootstrap-select wt-select-box">
                                                                    <!-- <select _ngcontent-sva-c15="" name="team-size"
                                                                        data-live-search="true" title="team-size"
                                                                        id="city" data-bv-field="size"
                                                                        class="wt-select-box selectpicker"
                                                                        style="display: none;">
                                                                        <option *ngFor="let city of religionList"
                                                                            [data-value]="city.id">{{city.nameEn}}
                                                                        </option>
                                                                    </select> -->
                                                                    <div class="nice-select wt-select-box selectpicker"
                                                                        tabindex="0">
                                                                        <span
                                                                            class="current">{{jobSeekerPersonalDetailModel.religion?.nameEn}}</span>
                                                                        <!-- <ul class="list">
                                                                            <li *ngFor="let city of religionList"
                                                                                [data-value]="city.nameEn"
                                                                                class="option"
                                                                                (click)="assignReligionId(city.id)">
                                                                                {{city.nameEn}}</li>
                                                                          
                                                                        </ul> -->
                                                                    </div>
                                                                    <button _ngcontent-sva-c15="" type="button"
                                                                        tabindex="-1" data-bs-toggle="dropdown"
                                                                        role="combobox" aria-owns="bs-select-1"
                                                                        aria-haspopup="listbox" aria-expanded="false"
                                                                        title="team-size" data-id="city"
                                                                        class="btn dropdown-toggle bs-placeholder btn-light">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option">
                                                                            <div _ngcontent-sva-c15=""
                                                                                class="filter-option-inner">
                                                                                <div _ngcontent-sva-c15=""
                                                                                    class="filter-option-inner-inner">
                                                                                    team-size</div>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                    <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                        <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                            <input _ngcontent-sva-c15="" type="search"
                                                                                autocomplete="off" role="combobox"
                                                                                aria-label="Search"
                                                                                aria-controls="bs-select-1"
                                                                                aria-autocomplete="list"
                                                                                class="form-control">
                                                                        </div>
                                                                        <div _ngcontent-sva-c15="" role="listbox"
                                                                            id="bs-select-1" tabindex="-1"
                                                                            class="inner show">
                                                                            <ul _ngcontent-sva-c15=""
                                                                                role="presentation"
                                                                                class="dropdown-menu inner show">
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div><mat-icon _ngcontent-sva-c15="" role="img"
                                                                    class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                                    aria-hidden="true"
                                                                    data-mat-icon-type="font">translate</mat-icon>
                                                            </div>
                                                        </div>
                                                        <span class="mandatory"
                                                            *ngIf="jobSeekerPersonalDetailModel.religionId == 0 && submitPersonalDetails">This
                                                            field is requied</span>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Languages Known:</label>
                                                        <div class="ls-inputicon-box">
                                                            <textarea type="text" class="form-control"
                                                                placeholder="English, Arabic"
                                                                [(ngModel)]="jobSeekerPersonalDetailModel.languagesKnown"
                                                                disabled></textarea>
                                                            <span class="mandatory"
                                                                *ngIf="jobSeekerPersonalDetailModel.languagesKnown == '' && submitPersonalDetails">This
                                                                field is requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <!-- <button class="btn site-button mt-3" (click)="savePersonalDetails()">Save</button> -->
                                        </div>
                                    </div>
                                </mat-tab>
                                <!-- <mat-tab label="Third"> Content 3 </mat-tab> -->
                            </mat-tab-group>
                            <div class="cabdidate-de-info">
                                <div class="twm-s-info-wrap">

                                    <app-job-question-answer [jobId]="jobId"
                                        [candId]="candId"></app-job-question-answer>
                                </div>
                                <hr />
                                <div class="twm-s-info-wrap mb-5">
                                    <h4 class="twm-s-title m-t0">About {{jobSeekerModel.fullNameEn}}
                                        <!-- <button  type="submit" class="btn site-button action"
                                            (click)="showEditToggle()"
                                        >
                                        <mat-icon *ngIf="!showEdit">edit</mat-icon>
                                        <mat-icon *ngIf="showEdit">cancel</mat-icon>
                                        </button> -->
                                    </h4>

                                    <p *ngIf="!showEdit">{{jobSeekerProfileSummary.summary}}</p>
                                    <hr />

                                    <app-job-seeker-keyskill [seekerId]="this.candId"
                                        [edit]="false"></app-job-seeker-keyskill>
                                    <hr />

                                    <app-job-seeker-language [seekerId]="this.candId"></app-job-seeker-language>
                                    <hr />
                                    <app-job-seeker-prof-detail [candId]="candId"></app-job-seeker-prof-detail>
                                    <hr />
                                    <app-job-seeker-skillcertification
                                        [id]="this.candId"></app-job-seeker-skillcertification>
                                    <hr />



                                    <div class="we-disp mt-3">
                                        <h4 class="twm-s-title">Employment Detail</h4>
                                        <!-- <button type="submit" (click)="newEmployment()" class="btn site-button action" data-bs-target="#sign_up_popup" data-bs-toggle="modal" data-bs-dismiss="modal"><mat-icon>add</mat-icon></button> -->

                                    </div>
                                    <div class="twm-timing-list-wrap">

                                        <div class="twm-timing-list" *ngFor="let item of jobSeekerEmploymentDetailList">
                                            <div class="twm-time-list-date">{{item.fromYear}} to {{item.toYear}}
                                            </div>
                                            <div class="twm-time-list-title">{{item.companyName}}
                                            </div>
                                            <div class="twm-time-list-position">{{item.designation}}</div>
                                            <div class="twm-time-list-discription">
                                                <p>{{item.jobProfile}}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <hr />

                                    <div class="we-disp mt-3">
                                        <h4 class="twm-s-title">Education &amp; Training</h4>
                                        <!-- <button (click)="newEducation()" type="submit" class="btn site-button action" data-bs-target="#sign_up_popup1" data-bs-toggle="modal" data-bs-dismiss="modal"><mat-icon>add</mat-icon></button> -->
                                    </div>

                                    <div class="twm-timing-list-wrap">

                                        <div class="twm-timing-list" *ngFor="let item of jobSeekerEducationDetailList">
                                            <div class="twm-time-list-date">Passing year: {{item.passingYear}}</div>
                                            <div class="twm-time-list-title">{{item.specialization}}
                                            </div>
                                            <div class="twm-time-list-position">{{item.instituteName}}</div>
                                            <div class="twm-time-list-discription">
                                                <!-- <p>One of the main areas that I work on with my clients is shedding these non-supportive beliefs and 
                                                        replacing them with beliefs that will help them to accomplish their desires.</p> -->
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- Candidate detail START -->



                            <!-- <div class="twm-s-map mb-5">
                                <h4 class="section-head-small mb-4">Location</h4>
                                <div class="twm-s-map-iframe twm-s-map-iframe-2">
                                    <iframe height="270"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.8534521658976!2d-118.2533646842856!3d34.073270780600225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c6fd9829c6f3%3A0x6ecd11bcf4b0c23a!2s1363%20Sunset%20Blvd%2C%20Los%20Angeles%2C%20CA%2090026%2C%20USA!5e0!3m2!1sen!2sin!4v1620815366832!5m2!1sen!2sin"></iframe>
                                </div>
                            </div> -->




                            <!-- <div class="twm-s-contact-wrap mb-5">
                                    <h4 class="section-head-small mb-4">Contact us</h4> 
                                    <div class="twm-s-contact twm-s-contact-2">
                                        <div class="row">

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="username" type="text" required="" class="form-control" placeholder="Name">
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="email" type="text" class="form-control" required="" placeholder="Email">
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="phone" type="text" class="form-control" required="" placeholder="Phone">
                                                </div>
                                            </div>
                                            
                                            
                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <textarea name="message" class="form-control" rows="3" placeholder="Message"></textarea>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-12">
                                                <button type="submit" class="site-button">Submit Now</button>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div> -->



                        </div>



                    </div>

                </div>

            </div>


            <div class="modal fade twm-sign-up" id="sign_up_popup" aria-hidden="true"
                aria-labelledby="sign_up_popupLabel" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content">
                        <div>

                            <div class="modal-header">
                                <h4 class="modal-title" id="sign_up_popupLabel">Employment Detail</h4>
                                <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>

                            <div class="modal-body">
                                <div class="twm-tabs-style-2">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Designation:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="text" class="form-control"
                                                        placeholder="Software Developer"
                                                        [(ngModel)]="jobSeekerEmploymentDetail.designation">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEmploymentDetail.designation == '' && submitEmployment">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Company Name:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="text" class="form-control" placeholder="Mahad"
                                                        [(ngModel)]="jobSeekerEmploymentDetail.companyName">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEmploymentDetail.companyName == '' && submitEmployment">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Company Location:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="text" class="form-control" placeholder="London"
                                                        [(ngModel)]="jobSeekerEmploymentDetail.companyLocation">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEmploymentDetail.companyLocation == '' && submitEmployment">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>From Month:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="integer" class="form-control" placeholder="05"
                                                        [(ngModel)]="jobSeekerEmploymentDetail.fromMonth" min="1"
                                                        max="12" maxlength="2">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEmploymentDetail.fromMonth == '' && submitEmployment">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>From Year:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="integer" class="form-control" placeholder="05"
                                                        [(ngModel)]="jobSeekerEmploymentDetail.fromYear" min="1900"
                                                        max="2023" maxlength="4" minlength="4">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEmploymentDetail.fromYear == 0 && submitEmployment">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>To Month:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="integer" class="form-control" placeholder="05"
                                                        [(ngModel)]="jobSeekerEmploymentDetail.toMonth" min="1" max="12"
                                                        maxlength="2">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEmploymentDetail.toMonth == '' && submitEmployment">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>To Year:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="integer" class="form-control" placeholder="05"
                                                        [(ngModel)]="jobSeekerEmploymentDetail.toYear" min="1900"
                                                        max="2023" maxlength="4" minlength="4">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEmploymentDetail.toYear == 0 && submitEmployment">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label>Job Profile:</label>
                                                <div class="ls-inputicon-box">
                                                    <textarea type="text" class="form-control" placeholder="Yo Bro"
                                                        [(ngModel)]="jobSeekerEmploymentDetail.jobProfile"></textarea>
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEmploymentDetail.jobProfile == '' && submitEmployment">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-md-12 mt-3">
                                            <button type="submit" class="site-button"
                                                (click)="saveEmployment()">Save</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!-- <div class="modal-footer">
                                <span class="modal-f-title">Login or Sign up with</span>
                                <ul class="twm-modal-social">
                                    <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                                </ul>
                            </div> -->

                        </div>
                    </div>
                </div>

            </div>

            <div class="modal fade twm-sign-up" id="sign_up_popup1" aria-hidden="true"
                aria-labelledby="sign_up_popupLabel" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content">
                        <div>

                            <div class="modal-header">
                                <h4 class="modal-title" id="sign_up_popupLabel">Education Detail</h4>
                                <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>

                            <div class="modal-body">
                                <div class="twm-tabs-style-2">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Specialization:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="text" class="form-control"
                                                        placeholder="Telemcommunication Engineering"
                                                        [(ngModel)]="jobSeekerEducationDetail.specialization">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEducationDetail.specialization == '' && submitEducation">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Institute Name:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="text" class="form-control" placeholder="Mahad Group"
                                                        [(ngModel)]="jobSeekerEducationDetail.instituteName">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEducationDetail.instituteName == '' && submitEducation">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Passing Year:</label>
                                                <div class="ls-inputicon-box">
                                                    <input type="integer" class="form-control" placeholder="2020"
                                                        [(ngModel)]="jobSeekerEducationDetail.passingYear" minlength="4"
                                                        maxlength="4">
                                                    <span class="mandatory"
                                                        *ngIf="jobSeekerEducationDetail.passingYear == 0 && submitEducation">This
                                                        field is requied</span>
                                                    <i class="fs-input-icon fas fa-at"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <div _ngcontent-sva-c15=""
                                                    class="form-group city-outer-bx has-feedback">
                                                    <label _ngcontent-sva-c15="">Institute Location:</label>
                                                    <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                        <div _ngcontent-sva-c15=""
                                                            class="dropdown bootstrap-select wt-select-box">
                                                            <select _ngcontent-sva-c15="" name="team-size"
                                                                data-live-search="true" title="team-size" id="city"
                                                                data-bv-field="size" class="wt-select-box selectpicker"
                                                                style="display: none;">
                                                                <option *ngFor="let city of countryModelList"
                                                                    [data-value]="city.id">{{city.nameEn}}</option>
                                                            </select>
                                                            <div class="nice-select wt-select-box selectpicker"
                                                                tabindex="0">
                                                                <span class="current"></span>
                                                                <ul class="list">
                                                                    <li *ngFor="let city of countryModelList"
                                                                        [data-value]="city.nameEn" class="option"
                                                                        (click)="assignInstituteLocationId(city.id)">
                                                                        {{city.nameEn}}</li>
                                                                    <!-- <li data-value="" *ngFor="let city of cityList" (click)="assignCity(city.id)" class="option">{{city.nameEn}}</li> -->
                                                                </ul>
                                                            </div>
                                                            <button _ngcontent-sva-c15="" type="button" tabindex="-1"
                                                                data-bs-toggle="dropdown" role="combobox"
                                                                aria-owns="bs-select-1" aria-haspopup="listbox"
                                                                aria-expanded="false" title="team-size" data-id="city"
                                                                class="btn dropdown-toggle bs-placeholder btn-light">
                                                                <div _ngcontent-sva-c15="" class="filter-option">
                                                                    <div _ngcontent-sva-c15=""
                                                                        class="filter-option-inner">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option-inner-inner">
                                                                            team-size
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                            <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                    <input _ngcontent-sva-c15="" type="search"
                                                                        autocomplete="off" role="combobox"
                                                                        aria-label="Search" aria-controls="bs-select-1"
                                                                        aria-autocomplete="list" class="form-control">
                                                                </div>
                                                                <div _ngcontent-sva-c15="" role="listbox"
                                                                    id="bs-select-1" tabindex="-1" class="inner show">
                                                                    <ul _ngcontent-sva-c15="" role="presentation"
                                                                        class="dropdown-menu inner show"></ul>
                                                                </div>
                                                            </div>
                                                        </div><mat-icon _ngcontent-sva-c15="" role="img"
                                                            class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                            aria-hidden="true"
                                                            data-mat-icon-type="font">translate</mat-icon>
                                                    </div>
                                                </div>
                                                <span class="mandatory"
                                                    *ngIf="jobSeekerEducationDetail.instituteLocationId == 0 && submitEducation">This
                                                    field is requied</span>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <div _ngcontent-sva-c15=""
                                                    class="form-group city-outer-bx has-feedback">
                                                    <label _ngcontent-sva-c15="">Course Enrolled:</label>
                                                    <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                                        <div _ngcontent-sva-c15=""
                                                            class="dropdown bootstrap-select wt-select-box">
                                                            <select _ngcontent-sva-c15="" name="team-size"
                                                                data-live-search="true" title="team-size" id="city"
                                                                data-bv-field="size" class="wt-select-box selectpicker"
                                                                style="display: none;">
                                                                <option *ngFor="let city of religionList"
                                                                    [data-value]="city.id">{{city.nameEn}}</option>
                                                            </select>
                                                            <div class="nice-select wt-select-box selectpicker"
                                                                tabindex="0">
                                                                <span class="current"></span>
                                                                <ul class="list">
                                                                    <li *ngFor="let city of courseModelList"
                                                                        [data-value]="city.nameEn" class="option"
                                                                        (click)="assignCourseId(city.id)">
                                                                        {{city.nameEn}}</li>
                                                                    <!-- <li data-value="" *ngFor="let city of cityList" (click)="assignCity(city.id)" class="option">{{city.nameEn}}</li> -->
                                                                </ul>
                                                            </div>
                                                            <button _ngcontent-sva-c15="" type="button" tabindex="-1"
                                                                data-bs-toggle="dropdown" role="combobox"
                                                                aria-owns="bs-select-1" aria-haspopup="listbox"
                                                                aria-expanded="false" title="team-size" data-id="city"
                                                                class="btn dropdown-toggle bs-placeholder btn-light">
                                                                <div _ngcontent-sva-c15="" class="filter-option">
                                                                    <div _ngcontent-sva-c15=""
                                                                        class="filter-option-inner">
                                                                        <div _ngcontent-sva-c15=""
                                                                            class="filter-option-inner-inner">
                                                                            team-size
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                            <div _ngcontent-sva-c15="" class="dropdown-menu">
                                                                <div _ngcontent-sva-c15="" class="bs-searchbox">
                                                                    <input _ngcontent-sva-c15="" type="search"
                                                                        autocomplete="off" role="combobox"
                                                                        aria-label="Search" aria-controls="bs-select-1"
                                                                        aria-autocomplete="list" class="form-control">
                                                                </div>
                                                                <div _ngcontent-sva-c15="" role="listbox"
                                                                    id="bs-select-1" tabindex="-1" class="inner show">
                                                                    <ul _ngcontent-sva-c15="" role="presentation"
                                                                        class="dropdown-menu inner show"></ul>
                                                                </div>
                                                            </div>
                                                        </div><mat-icon _ngcontent-sva-c15="" role="img"
                                                            class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color"
                                                            aria-hidden="true"
                                                            data-mat-icon-type="font">translate</mat-icon>
                                                    </div>
                                                </div>
                                                <span class="mandatory"
                                                    *ngIf="jobSeekerEducationDetail.courseId == 0 && submitEducation">This
                                                    field is requied</span>
                                            </div>
                                        </div>

                                        <div class="col-md-12 mt-3">
                                            <button type="submit" class="site-button"
                                                (click)="saveEducation()">Save</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!-- <div class="modal-footer">
                                <span class="modal-f-title">Login or Sign up with</span>
                                <ul class="twm-modal-social">
                                    <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                                </ul>
                            </div> -->

                        </div>
                    </div>
                </div>

            </div>

        </div>
        <!-- Candidate Detail V2 END -->


    </div>
</div>