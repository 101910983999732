import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyUserModel } from 'src/app/model/CompanyUser';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyPackageService } from 'src/app/services/companyPackage';
import { CompanyUserService } from 'src/app/services/companyUser.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  submitEducation: boolean = false;
  packageExist: boolean = false;

  constructor(private authService: AuthService, private uploadMedia: UploadMediaService,
    private companyUserService: CompanyUserService, private masterService: MasterService,
    private packageService: CompanyPackageService,
    private tost: ToastrService, public loader: LoaderService) {

  }
  ngOnInit(): void {
    this.getUser();
    this.checkPackage();
  }

  companyUser: CompanyUserModel = {
    id: 0,
    companyId: 0,
    userId: '',
    fullName: '',
    mobileNo: '',
    emailId: '',
    role: '',
    password: '',
  }

  getUser() {
    let aspId = this.authService.getAuthStorage().id;
    this.companyUserService.getCompanyUserInd(aspId).subscribe((user) => {
      this.companyUser = user;
    })
  }
  update() {
    this.companyUserService.update(this.companyUser).subscribe((reps) => {
      if (reps["isSuccess"]) {
        this.tost.success("Information Updated");
      } else {
        this.tost.error("Error updating information");
      }
    })
  }

  checkPackage() {
    let compId: Number = this.authService.getAuthStorage().companyId;
    this.packageService.getByCompId(compId).subscribe((data) => {
      if (data.length > 0) {
        this.packageExist = true;
      }
    })
  }
}
