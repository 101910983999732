<div class="page-content">

    <!-- INNER PAGE BANNER -->
    <div class="wt-bnr-inr overlay-wraper mt-5" style="background-image:url(images/banner/1.jpg);">
        <div class="overlay-main site-bg-white opacity-01"></div>
        <div class="container">
            <div class="wt-bnr-inr-entry">
                <div class="banner-title-outer">
                    <div class="banner-title-name">
                        <h2 class="wt-title">Our Packages</h2>
                    </div>
                </div>
                <!-- BREADCRUMB ROW -->

                <!-- <div>
                    <ul class="wt-breadcrumb breadcrumb-style-2">
                        <li><a href="/">Home</a></li>
                        <li>Our Packages</li>
                    </ul>
                </div> -->

                <!-- BREADCRUMB ROW END -->
            </div>
        </div>
    </div>
    <!-- INNER PAGE BANNER END -->

    <!-- CONTACT FORM -->
    <div class="section-full twm-contact-one">
        <div class="section-content">
            <div class="container">

                <!-- CONTACT FORM-->
                <div class="tab-content" id="myTab3Content">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="Monthly">
                        <div class="pricing-block-outer">
                            <div class="row ">
                                <div class="col-lg-4 col-md-6 m-b30" *ngFor="let item of packageList">
                                    <app-package [package]="item" [enroll]="false"> </app-package>

                                </div>


                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <!-- <div class="gmap-outline">
        <div class="google-map">
            <div style="width: 100%">
                <iframe height="460" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.8534521658976!2d-118.2533646842856!3d34.073270780600225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c6fd9829c6f3%3A0x6ecd11bcf4b0c23a!2s1363%20Sunset%20Blvd%2C%20Los%20Angeles%2C%20CA%2090026%2C%20USA!5e0!3m2!1sen!2sin!4v1620815366832!5m2!1sen!2sin"></iframe>
            </div>
        </div>
    </div> -->


    </div>