<div class="content-admin-main">

    <div class="wt-admin-right-page-header clearfix">
        <h2>Post a Job</h2>
        <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>Job Submission Form</span></div>
    </div>
    <app-loading *ngIf="loader.loader"></app-loading>

    <!--Logo and Cover image-->
    <!-- <div class="panel panel-default">
        <div class="panel-heading wt-panel-heading p-a20">
            <h4 class="panel-tittle m-a0">Logo and Cover image</h4>
        </div>
        <div class="panel-body wt-panel-body p-a20 p-b0 m-b30 ">
            
            <div class="row">
                    
                <div class="col-lg-12 col-md-6">
                    <div class="form-group">
                        
                        <div class="dashboard-profile-pic">
                            <div class="dashboard-profile-photo">
                                <img src="images/jobs-company/pic1.jpg" alt="">
                                <div class="upload-btn-wrapper">
                                    <button class="site-button button-sm">Upload Photo</button>
                                    <input type="file" name="myfile">
                                </div>
                            </div>
                            <p><b>Company Logo :- </b> Max file size is 1MB, Minimum dimension: 136 x 136 And Suitable files are .jpg & .png</p>
                        </div>
                    </div> 
                </div>

                <div class="col-lg-12 col-md-6">
                    <div class="dashboard-cover-pic">
                        <form action="upload.php" class="dropzone"></form>
                        <p><b>Background Banner Image :- </b> Max file size is 1MB, Minimum dimension: 770 x 310 And Suitable files are .jpg & .png</p> 
                    </div>                                    
                </div>

            </div>
                        
        </div>
    </div>  -->

    <!--Basic Information-->
    <div class="panel panel-default">
        <div class="panel-heading wt-panel-heading p-a20">
            <h4 class="panel-tittle m-a0"><i class="fa fa-suitcase me-3"></i>Job Details</h4>
        </div>
        <div class="panel-body wt-panel-body p-a20 m-b30 ">
            <div>
                <div class="row">
                    <!--Job title-->
                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Job Title</label>
                            <div class="ls-inputicon-box">
                                <input type="text" class="form-control" placeholder="Backend developer"
                                    [(ngModel)]="job.jobTitle">
                                <span class="mandatory" *ngIf="job.jobTitle== '' && isSubmit">This field is
                                    requied</span>
                                <i class="fs-input-icon fa fa-address-card"></i>
                            </div>
                            <!-- <span class="mandatory"
                                    *ngIf="!clicked"
                                >
                                    This Field is required!
                                </span> -->
                        </div>
                    </div>

                    <!--Job Category-->
                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group city-outer-bx has-feedback">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Job Type:</label>
                                <ngx-select-dropdown [ngModel]="selectedObject(jobTypeList,job.jobTypeId )"
                                    [config]="configSelect" (change)="assignJobType($event.value.id)"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="jobTypeList"></ngx-select-dropdown>

                            </div>
                            <!-- <label>Industry Type:</label>
                                        <select style="display: none;"  [(ngModel)]="register.industryId">
                                            <option *ngFor="let industry of industryList" [value]="industry.id">{{industry.nameEn}}</option>
                                        </select>
                                        <div class="nice-select" tabindex="0">
                                            <span class="current">Select Industry</span>
                                            <ul class="list">
                                                <li  *ngFor="let industry of industryList" [data-value]="industry.nameEn" class="option" (click)="assignIndustry(industry.id)">{{industry.nameEn}}</li>
                                            </ul>
                                        </div> -->

                        </div>
                        <span class="mandatory" *ngIf="job.jobTypeId == 0 && isSubmit">This field is requied</span>
                    </div>
                    <!-- <mat-slider min="1000" max="500000000" step="10000" showTickMarks discrete>
                        <input matSliderStartThumb>
                        <input matSliderEndThumb>
                    </mat-slider> -->
                    <!--Job Type-->
                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Country:</label>
                                <ngx-select-dropdown [ngModel]="selectedObject(countryList,job.countryId )"
                                    [config]="configSelect" (change)="assignCountry($event.value.id)"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="countryList"></ngx-select-dropdown>


                            </div>
                            <span class="mandatory" *ngIf="job.countryId == 0 && isSubmit">This field is
                                requied</span>
                            <!-- <label>Industry Type:</label>
                                    <select style="display: none;"  [(ngModel)]="register.industryId">
                                        <option *ngFor="let industry of industryList" [value]="industry.id">{{industry.nameEn}}</option>
                                    </select>
                                    <div class="nice-select" tabindex="0">
                                        <span class="current">Select Industry</span>
                                        <ul class="list">
                                            <li  *ngFor="let industry of industryList" [data-value]="industry.nameEn" class="option" (click)="assignIndustry(industry.id)">{{industry.nameEn}}</li>
                                        </ul>
                                    </div> -->

                        </div>
                    </div>


                    <!--Job Type-->
                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">City:</label>
                                <ngx-select-dropdown [ngModel]="selectedObject(cityList,job.jobLocationId )"
                                    [config]="configSelect" (change)="assignCity($event.value.id)"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="cityList"></ngx-select-dropdown>


                            </div>
                            <span class="mandatory" *ngIf="job.jobLocationId == 0 && isSubmit">This field is
                                requied</span>
                            <!-- <label>Industry Type:</label>
                                    <select style="display: none;"  [(ngModel)]="register.industryId">
                                        <option *ngFor="let industry of industryList" [value]="industry.id">{{industry.nameEn}}</option>
                                    </select>
                                    <div class="nice-select" tabindex="0">
                                        <span class="current">Select Industry</span>
                                        <ul class="list">
                                            <li  *ngFor="let industry of industryList" [data-value]="industry.nameEn" class="option" (click)="assignIndustry(industry.id)">{{industry.nameEn}}</li>
                                        </ul>
                                    </div> -->

                        </div>
                    </div>


                    <!--Offered Salary-->
                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Nationality:</label>
                                <ngx-select-dropdown [ngModel]="selectedObject(nationalityList,job.nationalityId )"
                                    [config]="configSelect" (change)="assignNationality($event.value.id)"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="nationalityList"></ngx-select-dropdown>


                            </div>
                            <span class="mandatory" *ngIf="job.nationalityId == 0 && isSubmit">This field is
                                requied</span>
                            <!-- <label>Industry Type:</label>
                                    <select style="display: none;"  [(ngModel)]="register.industryId">
                                        <option *ngFor="let industry of industryList" [value]="industry.id">{{industry.nameEn}}</option>
                                    </select>
                                    <div class="nice-select" tabindex="0">
                                        <span class="current">Select Industry</span>
                                        <ul class="list">
                                            <li  *ngFor="let industry of industryList" [data-value]="industry.nameEn" class="option" (click)="assignIndustry(industry.id)">{{industry.nameEn}}</li>
                                        </ul>
                                    </div> -->

                        </div>
                    </div>

                    <!--Experience-->
                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Experience</label>
                            <div class="ls-inputicon-box">
                                <input type="number" class="form-control" placeholder="2" [(ngModel)]="job.experieance">
                                <i class="fs-input-icon fa fa-user-edit"></i>
                            </div>
                            <span class="mandatory"
                                *ngIf="(job.experieance == '' || job.experieance == null)   && isSubmit">This field is
                                requied</span>
                        </div>
                    </div>

                    <!-- <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Gender</label>
                            <div class="ls-inputicon-box">
                                <input type="text" class="form-control" placeholder="Male" [(ngModel)]="job.gender">
                                <i class="fs-input-icon fas fa-at"></i>
                            </div>
                            <span class="mandatory" *ngIf="job.gender == '' && isSubmit">This field is requied</span>
                        </div>
                    </div> -->

                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Gender:</label>
                                <ngx-select-dropdown [(ngModel)]="job.gender" [config]="configStatic"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="['Male','Female']"></ngx-select-dropdown>

                            </div>
                            <span class="mandatory" *ngIf="job.gender == '' && isSubmit">This field is
                                requied</span>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Total Vacancies</label>
                            <div class="ls-inputicon-box">
                                <input type="number" class="form-control" placeholder="3"
                                    [(ngModel)]="job.totalNoOfVacancy">
                                <i class="fs-input-icon fas fa-at"></i>
                            </div>
                            <span class="mandatory"
                                *ngIf="(job.totalNoOfVacancy == 0 || job.totalNoOfVacancy == null)  && isSubmit">This
                                field is
                                requied</span>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Min Salary:</label>
                            <div class="ls-inputicon-box">
                                <input type="number" class="form-control" placeholder="3"
                                    [(ngModel)]="job.userDefined1">
                                <span class="mandatory"
                                    *ngIf="(job.userDefined1 == '' || job.userDefined1 == null)  && isSubmit">This field
                                    is
                                    requied</span>
                                <i class="fs-input-icon fas fa-at"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Max Salary:</label>
                            <div class="ls-inputicon-box">
                                <input type="number" class="form-control" placeholder="3"
                                    [(ngModel)]="job.userDefined3">
                                <span class="mandatory"
                                    *ngIf="(job.userDefined3 == '' || job.userDefined3  == null)  && isSubmit">This
                                    field is
                                    requied</span>
                                <i class="fs-input-icon fas fa-at"></i>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Salary Year:</label>
                                <ngx-select-dropdown [(ngModel)]="job.userDefined2" [config]="configStatic"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="['year','month','hour']"></ngx-select-dropdown>

                            </div>
                            <span class="mandatory" *ngIf="job.userDefined2 == '' && isSubmit">This field is
                                requied</span>
                        </div>
                    </div>

                    <!--Functional Area-->
                    <div class="col-xl-4 col-lg-6 col-md-12">
                        <div class="form-group">
                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                <label _ngcontent-sva-c15="">Functional Area:</label>
                                <ngx-select-dropdown [ngModel]="selectedObject(functionalAreaList,job.functinalAreaId )"
                                    [config]="configSelect" (change)="assignFunctionalArea($event.value.id)"
                                    [instanceId]="'instance21'" [multiple]="false"
                                    [options]="functionalAreaList"></ngx-select-dropdown>


                            </div>
                            <span class="mandatory" *ngIf="job.functinalAreaId == 0 && isSubmit">This field is
                                requied</span>
                        </div>
                    </div>


                    <!--Description-->
                    <!-- <div class="col-md-12">
                        <div class="form-group">
                            <label>Job Description</label>
                            <textarea class="form-control" rows="3" [(ngModel)]="job.jobDescription"
                                placeholder="Greetings! We are Galaxy Software Development Company. We hope you enjoy our services and quality."></textarea>
                            <span class="mandatory" *ngIf="job.jobDescription == '' && isSubmit">This field is
                                requied</span>
                        </div>
                    </div> -->

                    <div class="col-md-12 mt-3 mb-3">
                        <div class="form-group">
                            <label>Key Skills</label>
                            <span (click)="addKeySkill()">

                                <i class="fa fa-add i ms-3"></i>
                            </span>
                            <br>
                            <div class="ls-inputicon-box col-3">
                                <input type="text" class="form-control" placeholder="Skills" [(ngModel)]="keySkill">
                                <i class="fs-input-icon fa fa-user-edit"></i>
                            </div>
                            <div class="d-flex key-skill-main mb-1">
                                <div class="key-skill me-3 mt-3" *ngFor="let item of keySkillList; index as i">
                                    <span class="me-2" (click)="removeKeySkill(i)">
                                        <i class="fa fa-close i"></i>
                                    </span>
                                    <span>{{item}}</span>
                                </div>
                            </div>
                            <span class="mandatory" *ngIf="keySkillList.length == 0 && isSubmit">This field is
                                requied</span>


                        </div>
                    </div>
                    <div class="col-md-12 mt-3 mb-3">
                        <div class="form-group">

                            <label>Questions</label>
                            <span (click)="addQuestion()">

                                <i class="fa fa-add i ms-3"></i>
                            </span>
                        </div>
                        <div *ngFor="let item of jobQuestionList;let i = index">
                            <div class="d-flex question mt-2">
                                <div class="d-flex row">

                                    <div [class]="item.userDefined1=='active'?'mandatory0-up button-sm active':'mandatory0-up button-sm inactive'"
                                        (click)="toggleMandatory('active',i)">Mandatory</div>
                                    <div [class]=" item.userDefined1!='active'?'mandatory0-down button-sm active':'mandatory0-down button-sm inactive' "
                                        (click)="toggleMandatory('unactive',i)">Optional</div>
                                </div>
                                <div class="ls-inputicon-box me-2">
                                    <input type="text" class="form-control" placeholder="What is your notice period?"
                                        [(ngModel)]="item.question">
                                    <i class="fs-input-icon fas fa-book-reader"></i>
                                </div>
                                <span (click)="removeQuestion(i)">

                                    <i class="fa fa-close i ms-2"></i>
                                </span>
                            </div>
                            <span class="mandatory"
                                *ngIf="item.question == '' || item.question == null || item.question == undefined">{{item.message}}</span>

                        </div>


                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Job Description</label>
                            <br>
                            <span class="mandatory" *ngIf="job.jobDescription == '' && isSubmit">This field is
                                requied</span>
                            <div class="NgxEditor__Wrapper">
                                <angular-editor #editor [(ngModel)]="job.jobDescription" [config]="config"
                                    id="my-textarea">
                                    <ng-template #customButtons let-executeCommandFn="executeCommandFn">
                                        <ae-toolbar-set>
                                            <div class="col">
                                                <!-- <div ngbDropdown class="d-inline-block disp-flex">
                                                    <mat-icon aria-hidden="false" class="disp-flex"
                                                        aria-label="Dashboard" style="margin-right: 15px;"
                                                        id="dropdownBasic1" ngbDropdownToggle>library_add</mat-icon>
                                                </div> -->
                                            </div>
                                        </ae-toolbar-set>
                                    </ng-template>
                                </angular-editor>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <button class="site-button" (click)="isActive()">Publish</button>
                    </div>

                    <!--Start Date-->
                    <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label>Start Date</label>
                                <div class="ls-inputicon-box"> 
                                    <input class="form-control datepicker" data-provide="datepicker" name="company_since" type="datetime-local" placeholder="mm/dd/yyyy">
                                    <i class="fs-input-icon far fa-calendar"></i>
                                </div>
                            </div>
                        </div> -->


                </div>
            </div>
        </div>
    </div>
    <div [class]="'modal fade twm-sign-up'" id="sign_up_popup" aria-hidden="true" aria-labelledby="sign_up_popupLabel"
        tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <app-loading *ngIf="loader.loader"></app-loading>
            <div class="modal-content">
                <div>
                    <div class="modal-header">
                        <h4 class="modal-title mandatory" id="sign_up_popupLabel">
                            Subscribe
                        </h4>
                        <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <div class="twm-tabs-style-2">
                            <div class="row">
                                <h5>We welcome you on board Mahad Jobs, to post a job subscribe to our package by
                                    clicking on the upgrage button</h5>

                            </div>
                        </div>
                    </div>


                </div>

                <div class="modal-footer">
                    <button class="site-button " (click)="route()">Upgrade</button>
                </div>

            </div>
        </div>
    </div>

</div>