import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { CompanyModel } from 'src/app/model/Compnay';
import { JobsModel } from 'src/app/model/Jobs';
import { CityModel } from 'src/app/model/master/City';
import { CountryModel } from 'src/app/model/master/Country';
import { IndustryModel } from 'src/app/model/master/Industry';
import { UploadMedia } from 'src/app/model/UploadMedia';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employer-company',
  templateUrl: './employer-company.component.html',
  styleUrls: ['./employer-company.component.scss']
})
export class EmployerCompanyComponent implements OnInit {
  yearList: Array<number> = [];
  yearList0: number[] = [];
  active: string
  url: string = "";
  urlCp: string = "";
  industry0: string = "";
  location: string = "";
  countryName: string = "";
  media: UploadMedia = {
    id: 0,
    base64: "",
    name: ""
  }
  error: boolean = false;
  errorCover: boolean = false;

  company: CompanyModel = {
    id: 0,
    creationUserName: '',
    creationDate: '',
    lastUpdateUserName: '',
    lastUpdateDate: '',
    deletedOn: '',
    deletedBy: '',
    nameEn: '',
    nameAr: '',
    descriptions: '',
    emailId: '',
    locationId: 0,
    phoneNo: '',
    industryId: 0,
    crnumber: '',
    establishmentYear: 0,
    address: '',
    userDefined1: '',
    userDefined2: '',
    userDefined3: '',
    userDefined4: '',
    countryId: 0
  }

  industry: IndustryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  industryList: Array<IndustryModel> = []

  city: CityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  cityList: Array<CityModel> = []

  country: CountryModel = {
    id: 0,
    nameEn: '',
    nameAr: ''
  }
  countryList: Array<CountryModel> = []



  public options: Object = {
    toolbarInline: true,
    charCounterCount: false,
    toolbarVisibleWithoutSelection: true
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '23rem',
    minHeight: '5rem',
    placeholder: 'Enter text in this rich text editor....',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: 'Quote',
        class: 'quoteClass',
      },
      {
        name: 'Title Heading',
        class: 'titleHead',
        tag: 'h1',
      },
    ],

  };

  configSelect = {
    displayFn: (item: any) => { return item.nameEn; },
    displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    searchOnKey: 'nameEn',
    limitTo: 0,
    height: "231px",
    enableSelectAll: true,
  };

  configStaticNoSearch = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: false,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  constructor(private authService: AuthService, private uploadMedia: UploadMediaService,

    private companyService: CompanyService, private masterService: MasterService, private toaster: ToastrService) {
    this.assignUrl();
  }
  ngOnInit(): void {
    this.yearList = this.masterService.yearList();
    this.getYerList();
    this.company.id = this.authService.getAuthStorage().companyId;
    this.companyService.getById(this.company.id).subscribe((data) => {
      this.company = data;
      this.assignUrl();
      this.error = false;
      this.errorCover = false;
      this.masterService.getCityAll().subscribe((data) => {
        this.cityList = data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == this.company.locationId) {
            this.location = data[i].nameEn;
            break;
          }
        }
      });
      this.masterService.getIndustryAll().subscribe((data) => {
        this.industryList = data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == this.company.industryId) {
            this.industry0 = data[i].nameEn;
            break;
          }
        }
      });
      this.masterService.getCountryAll().subscribe((data) => {
        this.countryList = data;
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == this.company.countryId) {
            this.countryName = data[i].nameEn;
            break;
          }
        }
      });
    })
    throw new Error('Method not implemented.');
  }

  assignUrl() {
    this.url = environment.apiUrl + 'ProfilePhoto/' + this.company.userDefined2;
    this.authService.saveLogo(this.company.userDefined2);
    this.urlCp = environment.apiUrl + 'ProfilePhoto/' + this.company.userDefined1;
  }

  toggleActive(classS: string) {
    console.log(classS);
    this.active = classS;
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      console.log(reader.result);
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      this.media.id = this.authService.getAuthStorage().companyId;
      this.media.name = "company" + this.authService.getAuthStorage().companyId + "." + type;
      this.media.base64 = reader.result.toString().split(";base64,")[1];
      this.company.userDefined2 = this.media.name;
      this.uploadMedia.uploadProfileCompanyLogo(this.media).subscribe((data) => {
        this.assignUrl();
        window.location.reload();
      });
      // console.log(reader.result,type);
    };
  }

  handleUploadCoverPhoto(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      this.media.id = this.authService.getAuthStorage().companyId;
      this.media.name = "companyCover" + this.authService.getAuthStorage().companyId + "." + type;
      this.media.base64 = reader.result.toString().split(";base64,")[1];
      this.uploadMedia.uploadProfileCover(this.media).subscribe((data) => {
        this.assignUrl();
        window.location.reload();
      });
      // console.log(reader.result,type);
    };
  }

  assignJobType(id: number) {
    this.company.industryId = id;
  }
  assignLocation(id: number) {
    this.company.locationId = id;
  }

  assignCountry(id: Number) {
    this.company.countryId = id;
    this.cityList = [];
    this.company.locationId = 0;
    this.masterService.getCityByCountryId(id).subscribe((data) => {
      this.cityList = data;
    });
  }

  assignYear(year: number) {
    this.company.establishmentYear = year;
  }

  async getYerList() {
    for (let i = 1900; i <= 2023; i++) {
      this.yearList.push(i);
    }
  }


  update() {
    this.companyService.update(this.company).subscribe((data) => {
      if (data.isSuccess) {
        this.toaster.success("Successfully updated company");
      }
      // toaster.show()
    });
  }
  imageError() {
    this.error = true;
  }
  imageErrorCover() {
    this.errorCover = true;
  }

  selectedObject(list, val) {
    return list.find(n => n.id == val);
  }

}
