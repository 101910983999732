import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthStorage } from 'src/app/model/AuthStorage';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Input() active: string
  display: string = "none";
  selected: Number = 0;
  auth: AuthStorage;
  url: string
  error: boolean = false;

  constructor(private authSrv: AuthService, private routerLink: Router) { }
  ngOnInit(): void {
    let url = window.location.pathname;
    this.change(url);
    this.auth = this.authSrv.getAuthStorage();
    this.url = environment.apiUrl + 'ProfilePhoto/' + this.auth.logo;
  }

  change(url: string) {
    if (url.includes("employer-dashboard")) {
      this.selected = 0;
    } else if (url.includes("employer-company")) {
      this.selected = 1;
    } else if (url.includes("employer-postjob") || url.includes("employer-managejob")) {
      this.selected = 3;
    } else if (url.includes("all-candidate")) {
      this.selected = 2;
    } else if (url.includes("packages")) {
      this.selected = 4;
    } else if (url.includes("shortlisted")) {
      this.selected = 5;
    } else if (url.includes("company-user")) {
      this.selected = 6;
    } else if (url.includes("change-pwd")) {
      this.selected = 7;
    } else if (url.includes("profile")) {
      this.selected = 8;
    } else {
      this.selected = 10;
    }
  }

  toggleMenu() {

    if (this.display == "none") {
      this.display = "block";
    } else {
      this.display = "none";
    }
  }

  logout() {
    this.authSrv.logout();
    window.location.href = "/"
  }

  route(link: string) {
    this.change(link);
    this.routerLink.navigate([link]);
  }

  imageError() {
    this.error = true;
  }


}
