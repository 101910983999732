import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    IsAuthenticated: boolean;
    active: string
    type: string;
    typeForNavbar: string = "";


    constructor(private router: Router, private authService: AuthService) {
        // this.IsAuthenticated = authService.isAuth();
        // // if(!this.IsAuthenticated){
        // //     router.navigateByUrl("/login")
        // // }
        // console.log(this.authService.getAuthStorage());
        // if(window.location.pathname != "/" && window.location.pathname != "view-job"){
        //     if(!this.IsAuthenticated && !window.location.pathname.includes("signup")){
        //         window.location.href = "/signup";
        //     }
        // }
        console.log(window.location.pathname, "ss");
        let notSideBarUrl = ["/our-packages", "/", "/contact-us", "/login"]
        if (authService.getAuthStorage().companyId != null && !notSideBarUrl.includes(window.location.pathname)) {

            this.type = "employer";
        }
        if (authService.getAuthStorage().companyId != null) {

            this.typeForNavbar = "employer";
        }
        // console.log(this.type);
        // if(this.type == "employer" && window.location.pathname == "/"){
        //     window.location.href = '/employer-dashboard'
        // }
    }

    ngOnInit() {
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.loader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.loader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    toggleActive(classS: string) {
        console.log(classS);
        this.active = classS;
    }
}