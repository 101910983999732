import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponseModel } from '../model/LoginResponseModel';
import { environment } from 'src/environments/environment';
import { JsonPipe } from '@angular/common';
import { AuthStorage } from '../model/AuthStorage';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserShorlistingService {
  constructor(private http: HttpClient) { }


  private createPath = environment.apiUrl + 'CompanyUserShorlistingJobSeeker/Create';
  private removePath = environment.apiUrl + 'CompanyUserShorlistingJobSeeker/Remove/';
  private isShortlistedPath = environment.apiUrl + 'CompanyUserShorlistingJobSeeker/IsShortlisted';
  private getJobSeekerPath = environment.apiUrl + 'CompanyUserShorlistingJobSeeker/ShortlistedJobSeeker';

  create(data: any): Observable<any> {
    return this.http.post<any>(this.createPath, data);
  }

  remove(companyUserId: Number, jobSeekerId: Number): Observable<any> {
    return this.http.delete<any>(this.removePath + companyUserId.toString() + "/" + jobSeekerId.toString(), {});
  }

  isShortlisted(jobSeekerId: Number, companyUserId: Number): Observable<any> {
    return this.http.get<any>(this.isShortlistedPath + "?jobSeekerId=" + jobSeekerId.toString() + "&companyUserId=" + companyUserId.toString());
  }

  getShortlistedJobSeeker(companyUserId: Number): Observable<any> {
    return this.http.get<any>(this.getJobSeekerPath + "?id=" + companyUserId.toString());
  }
}