<div class="twm-s-info-wrap">
    <h4 class="twm-s-title m-t0">
        Key Skills
    </h4>

    <div class="skills-cont d-flex" >
        <div class="skills  mb-2" *ngFor="let item of jobSeekerSkillModelList">
            <span>{{item.nameEn}}</span>
        </div>
    </div>
    <!-- <p *ngIf="!showEdit">
        {{
        jobSeekerProfileSummary.summary
        }}
    </p> -->
</div>