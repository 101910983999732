<div class="content-admin-main">

    <div class="wt-admin-right-page-header clearfix">
        <h2>Candidates</h2>
        <div class="breadcrumbs"><a href="#">Home</a><a href="#">Dasboard</a><span>Candidates</span></div>
    </div>

    <div class="twm-pro-view-chart-wrap">
        <app-loading *ngIf="loader.loader"></app-loading>
        <div class="col-lg-12 col-md-12 mb-4">
            <div class="panel panel-default site-bg-white m-t30">
                <div class="panel-heading wt-panel-heading p-a20">
                    <h4 class="panel-tittle m-a0"><i class="far fa-list-alt me-3"></i>All Candidates</h4>
                </div>
                <div class="panel-body wt-panel-body">
                    <div class="twm-D_table p-a20 table-responsive">
                        <table mat-table [dataSource]="dataSource">

                            <!-- Position Column -->
                            <ng-container matColumnDef="name">
                              <th mat-header-cell *matHeaderCellDef> Name. </th>
                              <td mat-cell *matCellDef="let element"> <div class="twm-DT-candidates-list">
                                <!-- <div class="twm-media">
                                    <div class="twm-media-pic">
                                       <img src="images/candidates/pic1.jpg" alt="">
                                    </div>
                                </div> -->
                                <div class="twm-mid-content">
                                    <a class="twm-job-title" (click)="viewProfile(element.action[0])">
                                        <h4> {{element.name}} </h4>
                                        <!-- <p class="twm-candidate-address">
                                            <i class="feather-map-pin"></i>New York
                                        </p> -->
                                    </a>
                                    
                                </div>
                                
                            </div> </td>
                            </ng-container>
                        
                            <!-- Name Column -->
                            <ng-container matColumnDef="applied_for">
                              <th mat-header-cell *matHeaderCellDef> Applied For </th>
                              <td mat-cell *matCellDef="let element"> {{element.applied_for}} </td>
                            </ng-container>
                        
                            <!-- Weight Column -->
                            <ng-container matColumnDef="date">
                              <th mat-header-cell *matHeaderCellDef> Date </th>
                              <td mat-cell *matCellDef="let element"> {{element.date}} </td>
                            </ng-container>
                        
                            <!-- Symbol Column -->
                            <ng-container matColumnDef="status">
                              <th mat-header-cell *matHeaderCellDef> Status </th>
                              <td mat-cell *matCellDef="let element"> 
                                    <div class="twm-jobs-category">
                                        <span *ngIf="element.status == 'Approved'" class="twm-bg-green">Approved</span>
                                        <span *ngIf="element.status == 'Pending'" class="twm-bg-golden">Pending</span>
                                        <span *ngIf="element.status == 'Rejected'" class="twm-bg-red">Rejected</span>
                                    </div> 
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                <td mat-cell *matCellDef="let element"> <div class="twm-table-controls">
                                    <ul class="twm-DT-controls-icon list-unstyled">
                                        <li>
                                            <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="View profile"
                                                (click)="viewProfile(element.action[0])"
                                            >
                                                <span class="fa fa-eye"></span>
                                            </button>
                                        </li>
                                        <li>
                                            <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Send message"
                                                (click)="viewCv(element.action[1])"
                                            >
                                                <span class="far fa-file"></span>
                                            </button>
                                        </li>
                                        <!-- <li>
                                            <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Delete">
                                                <span class="far fa-trash-alt"></span>
                                            </button>
                                        </li> -->
                                    </ul>
                                </div> </td>
                              </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          </table>
                        
                          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                                         showFirstLastButtons
                                         aria-label="Select page of periodic elements">
                          </mat-paginator>
                        
                    
                    </div>
                </div>
            </div>
        </div>

    </div>

    
      
                                          
</div>