<div class="twm-jobs-list-style1 mb-5" *ngIf="jobSeeker != null">
    <div class="twm-media">
        <img *ngIf="!error" [src]="urlUser" alt="#" (error)="imageError()">
        <i *ngIf="error" class="fa fa-user i"></i>
        <!-- <p class="twm-job-address">{{job.company["location"]}}</p> -->
    </div>
    <div class="twm-mid-content">
        <h4>{{jobSeeker.fullNameEn}}</h4>
        <!-- <a [href]="url" class="twm-job-title">
            <h4>Preview Cv<br /><span class="twm-job-post-duration">
                </span></h4>
        </a> -->
        <p class="twm-job-address"> <mat-icon>location_on</mat-icon> {{jobSeeker.currentCity}}</p>
        <!-- <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a> -->
        <!-- <div class="twm-jobs-category green pointer"><span class="twm-bg-sky"><i class="fa fa-save"></i> Save</span>
        </div> -->
        <!-- <button _ngcontent-lkq-c45="" class="site-button" style="color: white;"><i class="fa fa-save"></i>
            Save</button> -->
    </div>
    <div class="twm-right-content">
        <!-- <div class="twm-jobs-category green"><span class="twm-bg-green">{{job.jobType}}</span></div>
        <div class="twm-jobs-amount">{{job.userDefined1}} <span>/ {{job.userDefined2}}</span></div> -->
        <a [href]="'/user-profile-employer?Id=' + jobSeeker.id" class="twm-jobs-browse site-text-primary">Browse</a>
        <br>
        <a [href]="url" target="_blank" class="twm-jobs-browse site-text-primary" *ngIf="packageExist">Preview Cv</a>

    </div>
</div>