import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthStorage } from 'src/app/model/AuthStorage';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-employer-login',
  templateUrl: './employer-login.component.html',
  styleUrls: ['./employer-login.component.scss']
})
export class EmployerLoginComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private tost: ToastrService,
    private spinner: NgxSpinnerService,
    public loader: LoaderService,
  ) {

    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.loader.loader = false;

  }

  ngOnInit(): void {
  }
  login() {
    this.spinner.show();
    this.authService
      .login(this.form.value)
      .subscribe((data: AuthStorage) => {
        console.log(data);
        if (data.companyId != null && data.companyId != undefined && data.companyId != 0) {
          data.type = "employer";
          data.email = data.username;
        } else {
          this.tost.error("Either email or password is wrong");
          return;
        }

        this.authService.saveAuthStorage(data);
        this.authService.saveToken(data);
        console.log(this.authService.getAuthStorage());
        // window.location.href = '/home'
        this.spinner.hide();
        this.tost.success("Successfully loggedin");
        if (data.type == "employer") {
          window.location.href = "/employer-dashboard";
        } else {
          window.location.href = "/"
        }
      });
  }
  get username() {
    return this.form.get('username');
  }

  get password() {
    return this.form.get('password');
  }

}
