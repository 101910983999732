<app-preloader></app-preloader>
<!-- <app-loading></app-loading> -->
<div class="page-wraper">
    <app-navbar-style-two [type]="typeForNavbar" *ngIf="type != 'employer'"></app-navbar-style-two>
    <router-outlet *ngIf="type != 'employer'"></router-outlet>

    <app-employer-top-nav *ngIf="type == 'employer'" [active]="active"
        (toggleActive)="toggleActive($event)"></app-employer-top-nav>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <app-side-nav [active]="active" *ngIf="type == 'employer'"></app-side-nav>
    <div id="content" [ngClass]="active" *ngIf="type == 'employer'">
        <router-outlet *ngIf="type == 'employer'"></router-outlet>
    </div>
    <app-footer-style-one *ngIf="type != 'employer'"></app-footer-style-one>
</div>