<div class="page-content" style="transform: none;">



    <!-- OUR BLOG START -->
    <div class="section-full p-t12  p-b90 site-bg-white" style="transform: none;">


        <app-loading *ngIf="loader.loader"></app-loading>


        <div class="container" style="transform: none;">
            <div class="wt-admin-right-page-header clearfix">
                <h2>Shortlisted Candidates</h2>
                <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>Shortlisted Candidates</span></div>
            </div>

            <!-- <hr /> -->
            <div class="row" style="transform: none;">


                <div class="col-lg-12 col-md-12">

                    <div class="twm-jobs-list-wrap">
                        <span class="woocommerce-result-count-left">Showing total of {{jobSeekerList.length}}
                            candidate</span>

                        <div class="form-group mb-4">
                        </div>
                        <ul>
                            <li *ngFor="let item of jobSeekerList | paginate: { itemsPerPage: 7, currentPage: p }">
                                <app-candidate-widget [jobSeeker]="item"></app-candidate-widget>
                        </ul>
                        <div class="pagination-outer">
                            <div class="pagination-style1">
                                <pagination-controls (pageChange)="paginate($event)"></pagination-controls>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="pagination-outer">
                        <div class="pagination-style1">
                            <ul class="clearfix">
                                <li class="prev"><a href="javascript:;"><span> <i class="fa fa-angle-left"></i> </span></a></li>
                                <li><a href="javascript:;">1</a></li>
                                <li class="active"><a href="javascript:;">2</a></li>
                                <li><a href="javascript:;">3</a></li>
                                <li><a class="javascript:;" href="javascript:;"><i class="fa fa-ellipsis-h"></i></a></li>
                                <li><a href="javascript:;">5</a></li>
                                <li class="next"><a href="javascript:;"><span> <i class="fa fa-angle-right"></i> </span></a></li>
                            </ul>
                        </div>
                    </div> -->

                </div>

            </div>
        </div>
    </div>
    <!-- OUR BLOG END -->



</div>