<div class="content-admin-main">

    <div class="wt-admin-right-page-header clearfix">
        <h2>Profile</h2>
        <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>Profile</span></div>
    </div>


    <!--Basic Information-->
    <div class="panel panel-default">
        <div class="panel-body wt-panel-body p-a20 m-b30 ">
            <div>
                <div class="row">

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Full Name</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_name" type="text" placeholder="Company"
                                    [(ngModel)]="companyUser.fullName">
                                <!-- <i class="fs-input-icon fa fa-user "></i> -->
                                <mat-icon class="fs-input-icon">company</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_phone" type="text"
                                    placeholder="(251) 1234-456-7890" [(ngModel)]="companyUser.mobileNo" prefix="+"
                                    mask="(000) 000 00 00">
                                <!-- <i class="fs-input-icon fa fa-phone-alt"></i> -->
                                <mat-icon class="fs-input-icon">phone</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Email Address</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_Email" type="email"
                                    placeholder="Devid@example.com" [(ngModel)]="companyUser.emailId" disabled="true">
                                <!-- <i class="fs-input-icon fa fa-envelope"></i> -->
                                <mat-icon class="fs-input-icon">mail</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Role</label>
                            <div class="ls-inputicon-box">
                                <input class="form-control" name="company_since" type="text" placeholder=""
                                    [(ngModel)]="companyUser.role" disabled="true">
                                <!-- <i class="fs-input-icon fa fa-globe-americas"></i> -->
                                <mat-icon class="fs-input-icon">public</mat-icon>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 mt-3">
                        <div class="text-left">
                            <button type="submit" class="site-button" (click)="update()">Save Changes</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>