

        <div class="content-admin-main">

            <div class="wt-admin-right-page-header clearfix">
                <h2>Hello, {{name}}</h2>
                <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>Dasboard</span></div>
            </div>

            <div class="twm-dash-b-blocks mb-5">
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                        <div class="panel panel-default">
                            <div class="panel-body wt-panel-body gradi-1 dashboard-card ">
                                <div class="wt-card-wrap">
                                    <div class="wt-card-icon"><i class="far fa-address-book"></i></div>
                                    <div class="wt-card-right wt-total-active-listing counter ">{{dashboard.jobPosted}}</div>
                                    <div class="wt-card-bottom ">
                                        <h4 class="m-b0">Posted Jobs</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                        <div class="panel panel-default">
                            <div class="panel-body wt-panel-body gradi-2 dashboard-card ">
                                <div class="wt-card-wrap">
                                    <div class="wt-card-icon"><i class="far fa-file-alt"></i></div>
                                    <div class="wt-card-right  wt-total-listing-view counter ">{{dashboard.candidateApplied}}</div>
                                    <div class="wt-card-bottom">
                                        <h4 class="m-b0">Total Applications</h4> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                        <div class="panel panel-default">
                            <div class="panel-body wt-panel-body gradi-3 dashboard-card ">
                                <div class="wt-card-wrap">
                                    <div class="wt-card-icon"><i class="far fa-envelope"></i></div>
                                    <div class="wt-card-right wt-total-listing-review counter ">{{dashboard.jobViews}}</div>
                                    <div class="wt-card-bottom">
                                        <h4 class="m-b0">Total Job Views</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-12 mb-3">
                        <div class="panel panel-default">
                            <div class="panel-body wt-panel-body gradi-4 dashboard-card ">
                                <div class="wt-card-wrap">
                                    <div class="wt-card-icon"><i class="far fa-bell"></i></div>
                                    <div class="wt-card-right wt-total-listing-bookmarked counter ">0</div>
                                    <div class="wt-card-bottom">
                                        <h4 class="m-b0">Notifications</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>

            <!-- <div class="twm-pro-view-chart-wrap">
                <div class="row">
                    <div class="col-xl-6 col-lg-12 col-md-12 mb-4">
                        <div class="panel panel-default site-bg-white">
                            <div class="panel-heading wt-panel-heading p-a20">
                                <h4 class="panel-tittle m-a0"><i class="far fa-chart-bar"></i>Your Profile Views</h4>
                            </div>
                            <div class="panel-body wt-panel-body twm-pro-view-chart">
                                <canvas id="profileViewChart" width="453" height="226" style="display: block; box-sizing: border-box; height: 180.8px; width: 362.4px;"></canvas>
                            </div>
                        </div>
                    
                    </div>
                    

                    <div class="col-xl-6 col-lg-12 col-md-12 mb-4">
                        <div class="panel panel-default">
                            <div class="panel-heading wt-panel-heading p-a20">
                                <h4 class="panel-tittle m-a0">Inbox</h4>
                            </div>
                            <div class="panel-body wt-panel-body bg-white">
                                <div class="scroll-wrapper dashboard-messages-box-scroll scrollbar-macosx" style="position: relative;"><div class="dashboard-messages-box-scroll scrollbar-macosx scroll-content scroll-scrolly_visible" style="height: auto; margin-bottom: 0px; margin-right: 0px; max-height: 394px;">
                                
                                    <div class="dashboard-messages-box">
                                        <div class="dashboard-message-avtar"><img src="images/user-avtar/pic1.jpg" alt=""></div>
                                        <div class="dashboard-message-area">
                                            <h5>Lucy Smith<span>18 June 2023</span></h5>
                                            <p>Bring to the table win-win survival strategies to ensure proactive domination. at the end of the day, going forward, a new normal that has evolved from generation.</p>
                                        </div>
                                    </div>                    
                            
                                    <div class="dashboard-messages-box">
                                        <div class="dashboard-message-avtar"><img src="images/user-avtar/pic3.jpg" alt=""></div>
                                        <div class="dashboard-message-area">
                                            <h5>Richred paul<span>19 June 2023</span></h5>
                                            <p>Bring to the table win-win survival strategies to ensure proactive domination. at the end of the day, going forward, a new normal that has evolved from generation.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="dashboard-messages-box">
                                        <div class="dashboard-message-avtar"><img src="images/user-avtar/pic4.jpg" alt=""></div>
                                        <div class="dashboard-message-area">
                                            <h5>Jon Doe<span>20 June 2023</span></h5>
                                            <p>Bring to the table win-win survival strategies to ensure proactive domination. at the end of the day, going forward, a new normal that has evolved from generation.</p>
                                        </div>
                                    </div>
                                    
                                    <div class="dashboard-messages-box">
                                        <div class="dashboard-message-avtar"><img src="images/user-avtar/pic1.jpg" alt=""></div>
                                        <div class="dashboard-message-area">
                                            <h5>Thomas Smith<span>22 June 2023</span></h5>
                                            <p>Bring to the table win-win survival strategies to ensure proactive domination. at the end of the day, going forward, a new normal that has evolved from generation. </p>
                                        </div>
                                    </div>
                                </div><div class="scroll-element scroll-x scroll-scrolly_visible"><div class="scroll-element_outer"><div class="scroll-element_size"></div><div class="scroll-element_track"></div><div class="scroll-bar" style="width: 0px;"></div></div></div><div class="scroll-element scroll-y scroll-scrolly_visible"><div class="scroll-element_outer"><div class="scroll-element_size"></div><div class="scroll-element_track"></div><div class="scroll-bar" style="height: 199px; top: 0px;"></div></div></div></div>                        
                                                 
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="panel panel-default site-bg-white m-t30">
                            <div class="panel-heading wt-panel-heading p-a20">
                                <h4 class="panel-tittle m-a0"><i class="far fa-list-alt"></i>Recent Activities</h4>
                            </div>
                            <div class="panel-body wt-panel-body">
                                
                                <div class="dashboard-list-box list-box-with-icon">
                                    <ul>
                                        <li>
                                            <i class="fa fa-envelope text-success list-box-icon"></i>Nikol Tesla has sent you <a href="#" class="text-success">private message!</a>
                                            <a href="#" class="close-list-item color-lebel clr-red">
                                                <i class="far fa-trash-alt"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <i class="fa fa-suitcase text-primary list-box-icon"></i>Your job for  
                                            <a href="#" class="text-primary">Web Designer</a>
                                            has been approved!
                                            <a href="#" class="close-list-item color-lebel clr-red">
                                                <i class="far fa-trash-alt"></i>
                                            </a>
                                        </li>
                                            
                                        <li>
                                            <i class="fa fa-bookmark text-warning list-box-icon"></i>
                                            Someone bookmarked your
                                            <a href="#" class="text-warning">SEO Expert</a> 
                                            Job listing! 
                                            <a href="#" class="close-list-item color-lebel clr-red">
                                                <i class="far fa-trash-alt"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <i class="fa fa-tasks text-info list-box-icon"></i>
                                            Your job listing Core
                                            <a href="#" class="text-info">PHP Developer</a> for Site Maintenance is expiring! 
                                            <a href="#" class="close-list-item color-lebel clr-red">
                                                <i class="far fa-trash-alt"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <i class="fa fa-paperclip text-success list-box-icon"></i>
                                            You have new application for
                                            <a href="#" class="text-success"> UI/UX Developer &amp; Designer! </a>
                                            <a href="#" class="close-list-item color-lebel clr-red">
                                                <i class="far fa-trash-alt"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <i class="fa fa-suitcase text-danger list-box-icon"></i>
                                            Your Magento Developer job expire  
                                            <a href="#" class="text-danger">Renew</a>  now it.
                                            <a href="#" class="close-list-item color-lebel clr-red">
                                                <i class="far fa-trash-alt"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <i class="fa fa-star site-text-orange list-box-icon"></i> 
                                            David cope left a 
                                            <a href="#" class="site-text-orange"> review 4.5</a> for Real Estate Logo
                                            <a href="#" class="close-list-item color-lebel clr-red">
                                                <i class="far fa-trash-alt"></i>
                                            </a>
                                        </li>
                                    </ul>
                                
                                </div>
                                            
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 mb-4">
                        <div class="panel panel-default">
                            <div class="panel-heading wt-panel-heading p-a20">
                                <h4 class="panel-tittle m-a0">Recent Applicants</h4>
                            </div>
                            <div class="panel-body wt-panel-body bg-white">
                                <div class="twm-dashboard-candidates-wrap">
                                    <div class="row">

                                        <div class="col-xl-6 col-lg-12 col-md-12">
                                             <div class="twm-dash-candidates-list">
                                                 <div class="twm-media">
                                                     <div class="twm-media-pic">
                                                        <img src="images/candidates/pic1.jpg" alt="#">
                                                     </div>
                                                     
                                                 </div>
                                                 <div class="twm-mid-content">
                                                     <a href="#" class="twm-job-title">
                                                         <h4>Wanda Montgomery </h4>
                                                     </a>
                                                     <p>Charted Accountant</p>
                                                     
                                                     <div class="twm-fot-content">
                                                         <div class="twm-left-info">
                                                            <p class="twm-candidate-address"><i class="feather-map-pin"></i>New York</p>
                                                            <div class="twm-jobs-vacancies">$20<span>/ Day</span></div>
                                                         </div>
                                                         <div class="twm-right-btn">

                                                            <ul class="twm-controls-icon list-unstyled">
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="View profile">
                                                                        <span class="fa fa-eye"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Send message">
                                                                        <span class="far fa-envelope-open"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Delete">
                                                                        <span class="far fa-trash-alt"></span>
                                                                    </button>
                                                                </li>
                                                            </ul>

                                                         </div>
                                                    </div>
                                                 </div>
                                                 
                                             </div>
                                        </div>
    
                                        <div class="col-xl-6 col-lg-12 col-md-12">
                                            <div class="twm-dash-candidates-list">
                                                <div class="twm-media">
                                                    <div class="twm-media-pic">
                                                       <img src="images/candidates/pic2.jpg" alt="#">
                                                    </div>
                                                    
                                                </div>
                                                <div class="twm-mid-content">
                                                    <a href="#" class="twm-job-title">
                                                        <h4>Peter Hawkins</h4>
                                                    </a>
                                                    <p>Medical Professed</p>
                                                    
                                                    <div class="twm-fot-content">
                                                        <div class="twm-left-info">
                                                           <p class="twm-candidate-address"><i class="feather-map-pin"></i>New York</p>
                                                           <div class="twm-jobs-vacancies">$7<span>/ Hour</span></div>
                                                        </div>
                                                        <div class="twm-right-btn">

                                                            <ul class="twm-controls-icon list-unstyled">
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="View profile">
                                                                        <span class="fa fa-eye"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Send message">
                                                                        <span class="far fa-envelope-open"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Delete">
                                                                        <span class="far fa-trash-alt"></span>
                                                                    </button>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                   </div>
                                                </div>
                                                
                                            </div>
                                        </div>
    
                                        <div class="col-xl-6 col-lg-12 col-md-12">
                                            <div class="twm-dash-candidates-list">
                                                <div class="twm-media">
                                                    <div class="twm-media-pic">
                                                       <img src="images/candidates/pic3.jpg" alt="#">
                                                    </div>
                                                    
                                                </div>
                                                <div class="twm-mid-content">
                                                    <a href="#" class="twm-job-title">
                                                        <h4>Ralph Johnson  </h4>
                                                    </a>
                                                    <p>Bank Manger</p>
                                                    
                                                    <div class="twm-fot-content">
                                                        <div class="twm-left-info">
                                                           <p class="twm-candidate-address"><i class="feather-map-pin"></i>New York</p>
                                                           <div class="twm-jobs-vacancies">$180<span>/ Day</span></div>
                                                        </div>
                                                        <div class="twm-right-btn">
                                                            <ul class="twm-controls-icon list-unstyled">
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="View profile">
                                                                        <span class="fa fa-eye"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Send message">
                                                                        <span class="far fa-envelope-open"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Delete">
                                                                        <span class="far fa-trash-alt"></span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                   </div>
                                                </div>
                                                
                                            </div>
                                        </div>
    
                                        <div class="col-xl-6 col-lg-12 col-md-12">
                                            <div class="twm-dash-candidates-list">
                                                <div class="twm-media">
                                                    <div class="twm-media-pic">
                                                       <img src="images/candidates/pic4.jpg" alt="#">
                                                    </div>
                                                    
                                                </div>
                                                <div class="twm-mid-content">
                                                    <a href="#" class="twm-job-title">
                                                        <h4>Randall Henderson </h4>
                                                    </a>
                                                    <p>IT Contractor</p>
                                                    
                                                    <div class="twm-fot-content">
                                                        <div class="twm-left-info">
                                                           <p class="twm-candidate-address"><i class="feather-map-pin"></i>New York</p>
                                                           <div class="twm-jobs-vacancies">$90<span>/ Week</span></div>
                                                        </div>
                                                        <div class="twm-right-btn">
                                                            <ul class="twm-controls-icon list-unstyled">
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="View profile">
                                                                        <span class="fa fa-eye"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Send message">
                                                                        <span class="far fa-envelope-open"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Delete">
                                                                        <span class="far fa-trash-alt"></span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                   </div>
                                                </div>
                                                
                                            </div>
                                        </div>
    
                                        <div class="col-xl-6 col-lg-12 col-md-12">
                                            <div class="twm-dash-candidates-list">
                                                <div class="twm-media">
                                                    <div class="twm-media-pic">
                                                       <img src="images/candidates/pic6.jpg" alt="#">
                                                    </div>
                                                    
                                                </div>
                                                <div class="twm-mid-content">
                                                    <a href="#" class="twm-job-title">
                                                        <h4>Christina Fischer </h4>
                                                    </a>
                                                    <p>Charity &amp; Voluntary</p>
                                                    
                                                    <div class="twm-fot-content">
                                                        <div class="twm-left-info">
                                                           <p class="twm-candidate-address"><i class="feather-map-pin"></i>New York</p>
                                                           <div class="twm-jobs-vacancies">$19<span>/ Hour</span></div>
                                                        </div>
                                                        <div class="twm-right-btn">
                                                            <ul class="twm-controls-icon list-unstyled">
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="View profile">
                                                                        <span class="fa fa-eye"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Send message">
                                                                        <span class="far fa-envelope-open"></span>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Delete">
                                                                        <span class="far fa-trash-alt"></span>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                   </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                       
                                    </div>
                                </div>              
                            </div>
                        </div>

                    </div>

                </div>
            </div> -->
                                                  
        </div>
