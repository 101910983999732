import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponseModel } from '../model/LoginResponseModel';
import { environment } from 'src/environments/environment';
import { JsonPipe } from '@angular/common';
import { AuthStorage } from '../model/AuthStorage';

@Injectable({
    providedIn: 'root'
})
export class CompanyUserService {
    constructor(private http: HttpClient) { }


    private createPath = environment.apiUrl + 'CompanyUser/Registration';
    private removePath = environment.apiUrl + 'CompanyUser/ToggleActive';
    private changeRolePath = environment.apiUrl + 'CompanyUser/ChangeRole';
    private getPath = environment.apiUrl + 'CompanyUser/GetByCompanyId';
    private getUserPath = environment.apiUrl + 'CompanyUser/GetByAspId';
    private updatePath = environment.apiUrl + 'CompanyUser/Update';

    create(data: any): Observable<any> {
        return this.http.post<any>(this.createPath, data);
    }

    remove(data: any): Observable<any> {
        return this.http.put<any>(this.removePath, data);
    }

    update(data: any): Observable<any> {
        return this.http.put<any>(this.updatePath, data);
    }

    changeRole(data: any): Observable<any> {
        return this.http.put<any>(this.changeRolePath, data);
    }

    getCompanyUser(companyId: Number): Observable<any> {
        return this.http.get<any>(this.getPath + "?id=" + companyId.toString());
    }
    getCompanyUserInd(id: string): Observable<any> {
        return this.http.get<any>(this.getUserPath + "?id=" + id.toString());
    }
}