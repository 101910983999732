import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyUserModel } from 'src/app/model/CompanyUser';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyUserService } from 'src/app/services/companyUser.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-company-user-widget',
  templateUrl: './company-user-widget.component.html',
  styleUrls: ['./company-user-widget.component.scss']
})
export class CompanyUserWidgetComponent implements OnInit {
  constructor(private authService: AuthService, private companyUserService: CompanyUserService,
    private masterService: MasterService,
    private tost: ToastrService, public loader: LoaderService) {

  }
  @Input() companyUser: CompanyUserModel;
  url: string = "";
  urlCl: string = "";
  urlUser: string = "";
  ngOnInit(): void {
  }
  toggleAdmin(id: Number, role: string): void {
    this.companyUser.companyId = this.authService.getAuthStorage().companyId;
    this.companyUserService.changeRole({ "id": id, "role": role }).subscribe((data) => {
      if (data.isSuccess) {
        if (this.companyUser.role == "admin") { this.companyUser.role = "user" } else { this.companyUser.role = "admin" }
      }
    })
  }
}
