<div class="content-admin-main">

    <div class="wt-admin-right-page-header clearfix">
        <h2>Manage Jobs</h2>
        <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>My Job Listing</span></div>
    </div>

    <!--Basic Information-->
</div>
<div class="panel panel-default">
    <div class="panel-heading wt-panel-heading p-a20">
        <h4 class="panel-tittle m-a0"><i class="fa fa-suitcase me-3"></i> Job Details</h4>
        <app-loading *ngIf="loader.loader"></app-loading>
        <div class="panel-body wt-panel-body p-a20 m-b30 ">
            <div class="twm-D_table p-a20 table-responsive">
                <div class="twm-D_table p-a20 table-responsive">
                    <table mat-table [dataSource]="dataSource">

                        <!-- Position Column -->
                        <ng-container matColumnDef="job_title">
                            <th mat-header-cell *matHeaderCellDef> Job title. </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="twm-DT-candidates-list">
                                    <!-- <div class="twm-media">
                                <div class="twm-media-pic">
                                   <img src="images/candidates/pic1.jpg" alt="#">
                                </div>
                            </div> -->
                                    <div class="twm-mid-content">
                                        <a (click)="viewCandidate(element.action)" class="twm-job-title">
                                            <h4> {{element.job_title}} </h4>
                                            <!-- <p class="twm-candidate-address">
                                        <i class="feather-map-pin"></i>New York
                                    </p> -->
                                        </a>

                                    </div>

                                </div>
                            </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef> Category </th>
                            <td mat-cell *matCellDef="let element"> {{element.category}} </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="job_type">
                            <th mat-header-cell *matHeaderCellDef> Job Type </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="twm-jobs-category">
                                    <span class="twm-bg-golden">{{element.job_type}} </span>
                                </div>

                            </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="application">
                            <th mat-header-cell *matHeaderCellDef> Application </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.application}}

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef> Job Location </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.location}}

                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="twm-table-controls">
                                    <ul class="twm-DT-controls-icon list-unstyled">
                                        <li>
                                            <!-- <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="View profile">
                                            <span class="fa fa-eye"></span>
                                        </button> -->
                                            <button title="" data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-original-title="View profile"
                                                (click)="viewCandidate(element.action)">
                                                <span class="fa fa-eye"></span>
                                            </button>


                                        </li>
                                        <li>
                                            <button title="" data-bs-toggle="tooltip" data-bs-placement="top"
                                                data-bs-original-title="View profile">
                                                <a [href]="'/employer-postjob?Id='+ element.action">
                                                    <span class="fa fa-edit"></span>
                                                </a>
                                            </button>
                                            <!-- <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Send message">
                                            <span class="far fa-file"></span>
                                        </button> -->
                                        </li>
                                        <!-- <li>
                                        <button title="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Delete">
                                            <span class="far fa-trash-alt"></span>
                                        </button>
                                    </li> -->
                                    </ul>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                        aria-label="Select page of periodic elements">
                    </mat-paginator>


                </div>
            </div>
        </div>
    </div>

</div>