import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CompanyPackageService {
    private packagePath = environment.apiUrl + 'CompanyPackage/';

    private eventguestpath = environment.apiUrl + 'EventGuests/';

    constructor(private http: HttpClient) { }

    create(data: any): Observable<any> {
        return this.http.post<any>(this.packagePath + 'create', data);
    }

    update(data: any): Observable<any> {
        return this.http.put<any>(this.packagePath + 'update', data);
    }

    getByCompId(id: Number): Observable<any> {
        return this.http.get<any>(this.packagePath + 'companyId?Id=' + id);
    }

    getAll(): Observable<any> {
        return this.http.get<any>(this.packagePath + '/all');
    }

    getByFilter(data: any): Observable<any> {
        return this.http.post<any>(this.packagePath + '/getByFilter', data);
    }

    getByCompanyId(id: Number): Observable<any> {
        return this.http.get<any>(this.packagePath + '/companyId?companyId=' + id);
    }

    saveResponse(data: any): Observable<any> {
        return this.http.put<any>(this.eventguestpath + 'save-response/', data);
    }

    GetGuestByEventId(id: number): Observable<any> {
        return this.http.get<any>(this.eventguestpath + 'event-guests/' + id);
    }

    AddEventGuest(data: any): Observable<any> {
        return this.http.post<any>(this.eventguestpath + 'add-guest', data);
    }

    sendEmail(id: number): Observable<any> {
        return this.http.post<any>(
            this.eventguestpath + 'send-email?Id=' + id,
            null
        );
    }


    GetSingleEventUserResponse(
        eventId: number,
        guestId: string
    ): Observable<any> {
        return this.http.get<any>(
            this.eventguestpath + 'event-guest/' + eventId + '/' + guestId + ''
        );
    }

    DeleteEventGuest(id: number): Observable<any> {
        return this.http.delete<any>(this.eventguestpath + 'delete/' + id);
    }
}
