<div class="page-wraper container">

    <!-- <app-navbar-style-one></app-navbar-style-one> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content ">
        <app-loading *ngIf="loader.loader"></app-loading>
        <!-- <app-login *ngIf="type == 'login'" [type]="type" [userType]="userType" (toggle)="toggle($event)"></app-login> -->

        <fieldset class="modal-content margin">
            <div>
                <div class="">
                    <h2 class="text-center" id="sign_up_popupLabel">Forget Password</h2>
                    <hr />
                </div>

                <div class="modal-body">
                    <div class="twm-tabs-style-2 d-flex align-content-center">
                        <div class="tab-content" id="myTabContent">
                            <!--Signup Candidate Content-->
                            <div [ngClass]="'tab-pane fade show active'" id="sign-candidate">
                                <div>
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12">
                                            <label>Email:</label>
                                            <div class="form-group mb-3">
                                                <input type="email" class="form-control" placeholder="Email"
                                                    [(ngModel)]="forgetPwd.email">
                                                <span class="mandatory" *ngIf="forgetPwd.email == '' && submit">
                                                    This Field is required!
                                                </span>
                                                <span class="mandatory"
                                                    *ngIf="!forgetPwd.email.includes('@') && !forgetPwd.email.includes('.')">
                                                    Enter Valid Email
                                                </span>
                                            </div>
                                        </div>

                                
                                        <div class="col-md-12">
                                            <button class="site-button"
                                                (click)="onSubmit()">Reset</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!-- 
                <div class="modal-footer">
                    <span class="modal-f-title">Login or Sign up with</span>
                    <ul class="twm-modal-social">
                        <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                    </ul>
                </div> -->

            </div>

        </fieldset>
    </div>
</div>