import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IsActiveModel } from 'src/app/model/IsActive';
import { JobsModel } from 'src/app/model/Jobs';
import { CityModel } from 'src/app/model/master/City';
import { FunctionalAreaModel } from 'src/app/model/master/FunctionalArea';
import { IndustryModel } from 'src/app/model/master/Industry';
import { JobTypeModel } from 'src/app/model/master/JobType';
import { NationalityModel } from 'src/app/model/master/Nationality';
import { AuthService } from 'src/app/services/auth.service';
import { JobService } from 'src/app/services/job.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CountryModel } from 'src/app/model/master/Country';
import { JobQuestionModel } from 'src/app/model/JobQuestion';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-employer-postjob',
  templateUrl: './employer-postjob.component.html',
  styleUrls: ['./employer-postjob.component.scss']
})
export class EmployerPostjobComponent implements OnInit {

  clicked: boolean = false;
  isSubmit: boolean = false;
  jobId: Number = 0;
  html: string = '';
  keySkill: string = "";
  keySkillList: Array<string> = [];
  search: string = "";
  question: string = "";
  yearList: number[] = [];

  configSelect = {
    displayFn: (item: any) => { return item.nameEn; },
    displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    searchOnKey: 'nameEn',
    limitTo: 0,
    height: "231px",
    enableSelectAll: true,
  };

  configStaticNoSearch = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: false,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  configStatic = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  constructor(private jobService: JobService, private masterService: MasterService, private _router: Router,
    private authService: AuthService, private tost: ToastrService, public loader: LoaderService) {
    this.loader.loader = false;
  }

  ngOnInit(): void {
    this.yearList = this.masterService.yearList();
    var urlString = window.location.href;
    var url = new URL(urlString);
    var id = url.searchParams.get("Id");
    if (id != null && id != undefined && id != "") {
      this.jobId = parseInt(id);
      this.getJob(this.jobId);
    }
    this.masterService.getNationalitAll().subscribe((data) => {
      this.nationalityList = data;
    });
    this.masterService.getCountryAll().subscribe((data) => {
      this.countryList = data;
    });
    this.masterService.getJobTypeAll().subscribe((data) => {
      this.jobTypeList = data;
    });
    this.masterService.getfunctionalAreaAll().subscribe((data) => {
      this.functionalAreaList = data;
    });
    this.checkPackage();
  }

  public options: Object = {
    toolbarInline: true,
    charCounterCount: false,
    toolbarVisibleWithoutSelection: true
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '23rem',
    minHeight: '5rem',
    placeholder: 'Enter text in this rich text editor....',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: 'Quote',
        class: 'quoteClass',
      },
      {
        name: 'Title Heading',
        class: 'titleHead',
        tag: 'h1',
      },
    ],

  };

  jobQuestion: JobQuestionModel = {
    id: 0,
    jobId: 0,
    question: '',
    userDefined1: '',
    message: ''
  }
  jobQuestionList: JobQuestionModel[] = [];

  job: JobsModel = {
    id: 0,
    companyId: this.authService.getAuthStorage().companyId,
    jobTitle: "",
    experieance: "",
    jobTypeId: 0,
    jobLocationId: 0,
    countryId: 0,
    nationalityId: 0,
    gender: "",
    totalNoOfVacancy: 0,
    functinalAreaId: 0,
    jobDescription: "",
    applicant: 0,
    jobViews: 0,
    jobType: null,
    jobLocation: null,
    functinalArea: null,
    creationDate: null,
    nationality: null,
    company: null,
    userDefined1: "",
    userDefined2: "",
    userDefined3: "",
    userDefined4: "",
    country: null,
    keySkill: "",
    jobQuestions: null
  }
  jobMandatory: Array<string> = [
    "jobTitle",
    "experieance",
    "jobTypeId",
    "jobLocationId",
    "nationalityId",
    "gender",
    "totalNoOfVacancy",
    "functinalAreaId",
    "jobDescription",
    "userDefined1",
    "userDefined2",
    "userDefined3",
    "keySkill"
  ]

  industry: IndustryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  industryList: Array<IndustryModel> = []

  city: CityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  cityList: Array<CityModel> = []

  country: CountryModel = {
    id: 0,
    nameEn: '',
    nameAr: ''
  }
  countryList: Array<CountryModel> = []


  nationality: NationalityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  nationalityList: Array<NationalityModel> = []

  jobType: JobTypeModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  jobTypeList: Array<JobTypeModel> = []

  functionalArea: FunctionalAreaModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    count: 0
  }
  functionalAreaList: Array<FunctionalAreaModel> = []

  isActiveModel: IsActiveModel = {
    userId: ''
  }

  assignNationality(id: Number) {
    this.job.nationalityId = id;
  }

  assignCountry(id: Number) {
    this.job.countryId = id;
    // this.job.jobLocationId = 0;
    this.cityList = [];
    this.masterService.getCityByCountryId(id).subscribe((data) => {
      this.cityList = data;
    });
  }

  assignCity(id: Number) {
    this.job.jobLocationId = id;
  }

  assignFunctionalArea(id: Number) {
    this.job.functinalAreaId = id;
  }

  assignSalaryYear(id: string) {
    this.job.userDefined2 = id;
  }

  assignJobType(id: Number) {
    this.job.jobTypeId = id;
  }

  assignGender(gender: string) {
    this.job.gender = gender;
  }

  checkPackage() {
    let userId = this.authService.getAuthStorage().id;
    this.isActiveModel.userId = userId;
    this.authService.isActive(this.isActiveModel).subscribe((data) => {
      if (!data.isSuccess) {
        this.tost.error(data["message"]);
        window.location.href = "/packages";
      }
    })
  }

  isActive() {

    let userId = this.authService.getAuthStorage().id;
    this.isActiveModel.userId = userId;
    this.authService.isActive(this.isActiveModel).subscribe((data) => {
      if (data.isSuccess) {
        this.submit();
      } else {
        this.tost.warning("Your Email id is Awaiting admin approval");
      }
    })
  }

  validate(event: any, field: any) {
    if (event.target.value == null || event.target.value == '' || event.target.value.includes("e") || event.target.value.includes("+") || event.target.value.includes("-") || event.target.value.includes("*") || event.target.value.includes("/") || event.target.value.includes("%") || event.target.value.includes("^") || event.target.value.includes("*")) {
      this.job[field] = this.job[field];
    }
  }

  submit() {
    this.loader.loader = true;
    this.storeKeySkill();
    this.job.experieance = this.job.experieance?.toString();
    if (this.job.userDefined1 > this.job.userDefined3) {
      this.tost.warning("Maximun salary cannot be smaller than minimum salary");
      return;
    }
    this.job.userDefined1 = this.job.userDefined1?.toString();
    this.job.userDefined2 = this.job.userDefined2?.toString();
    this.job.userDefined3 = this.job.userDefined3?.toString();
    if (this.validateQuestion()) {
      if (this.masterService.validate(this.jobMandatory, this.job)) {
        this.clicked = true;
        if (this.job.id == 0) {
          this.job.userDefined1 = this.job.userDefined1;
          this.job.jobQuestions = this.jobQuestionList;
          this.jobService.create(this.job).subscribe((data) => {
            if (data["isSuccess"] == false) {
              if (data["message"] == "Please select your package to continue") {
                $("#sign_up_popup").modal('show');
              } else {
                this.tost.error(data["message"]);
              }
            } else {

              this.tost.success("Successfully posted");
              this.loader.loader = false;
              window.location.href = "/employer-managejob"
            }
            this.loader.loader = false;
          })
        } else {
          this.job.jobQuestions = this.jobQuestionList;
          this.jobService.update(this.job).subscribe((data) => {
            this.tost.success("Successfully posted");
            this.loader.loader = false;
            window.location.href = "/employer-managejob"
          })
        }
      } else {
        this.isSubmit = true;
        this.loader.loader = false;
      }
    } else {
      this.loader.loader = false;
    }
  }
  validateQuestion(): boolean {
    for (let i = 0; i < this.jobQuestionList.length; i++) {
      if ([null, undefined, ""].includes(this.jobQuestionList[i].question.trim())) {
        this.jobQuestionList[i].message = "This field is required";
        return false;
      }
    }
    return true;
  }
  getJob(id: Number) {
    this.loader.loader = true;
    this.jobService.getById(id).subscribe((data) => {
      this.job = data[0];
      this.jobQuestionList = this.job.jobQuestions;
      this.assignCountry(this.job.countryId);
      if (this.job.keySkill != undefined) {
        if (this.job.keySkill.includes(",")) {
          this.keySkillList = this.job.keySkill.split(",");
        } else {
          if (!["", ",", " "].includes(this.job.keySkill.toString())) {
            this.keySkillList = [this.job.keySkill.toString()];
          }
        }
      }
      this.loader.loader = false;
    })
  }

  route() {
    $("#sign_up_popup").modal('hide');
    this._router.navigateByUrl('/packages')
  }

  addKeySkill() {
    if (this.keySkill.trim() != "") {
      this.keySkillList.push(this.keySkill);
      this.keySkill = "";
    }
  }
  removeKeySkill(index: number) {
    this.keySkillList.splice(index, 1);
  }
  storeKeySkill() {
    let str: string = "";
    for (let i = 0; i < this.keySkillList.length; i++) {
      str += this.keySkillList[i]
      if (i != this.keySkillList.length - 1) {
        str += ",";
      }
    }
    this.job.keySkill = str;
  }

  addQuestion() {
    let jobQuestion: JobQuestionModel = {
      id: 0,
      jobId: this.jobId,
      question: '',
      userDefined1: 'active',
      message: ''
    }
    this.jobQuestionList.push(jobQuestion);
  }
  removeQuestion(index: number) {
    this.jobQuestionList.splice(index, 1);
  }

  toggleMandatory(val: string, index: number) {
    this.jobQuestionList[index].userDefined1 = val;
  }

  selectedObject(list, val) {
    return list.find(n => n.id == val);
  }

}
