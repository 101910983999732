import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SentMessageModel } from 'src/app/model/SentMessage';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { SentMessageService } from 'src/app/services/sentMesaage.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  sentMessage: SentMessageModel = {
    name: '',
    email: '',
    mobileNo: '',
    subject: '',
    message: ''
  }
  sentMessageMadatory = ["name", "email", "mobileNo", "subject", "message"];
  submit: boolean = false;
  constructor(
    private msgService: SentMessageService,
    private masterService: MasterService,
    public loader: LoaderService,
    public toast: ToastrService
  ) {
    this.loader.loader = false;

  }
  ngOnInit(): void {
  }
  onSubmit() {
    this.submit = true;
    this.loader.loader = true;
    this.sentMessage.mobileNo = this.sentMessage.mobileNo?.toString();
    if (!this.sentMessage.email.includes('@') || !this.sentMessage.email.includes('.')) {
      this.loader.loader = false;
      this.submit = true;
      return;
    }
    if (this.masterService.validate(this.sentMessageMadatory, this.sentMessage)) {
      this.msgService.sent(this.sentMessage).subscribe((data) => {
        if (data.isSuccess) {
          this.toast.success("Message sent successfully");
          this.loader.loader = false;
        } else {
          this.toast.error(data.message.toString());
          this.loader.loader = false;
        }
      })
    } else {
      this.loader.loader = false;
    }
  }
}
