<div class="page-content" style="transform: none;">



    <!-- OUR BLOG START -->
    <div class="section-full site-bg-white" style="transform: none;">


        <app-loading *ngIf="loader.loader"></app-loading>


        <div class="container" style="transform: none;">

            <div class="wt-admin-right-page-header clearfix">
                <h2>Candidates</h2>

                <div class="breadcrumbs"><a href="/employer-dashboard">Home</a><span>Candidates</span></div>
                <button class="btn btn-primary button-sm site-button mt-2" (click)="filter()">Filter Candidate</button>
            </div>
            <!-- <hr /> -->
            <!-- Filter -->
            <div class="job-filter-area pt-10" [ngStyle]="{'display': isFilter?'block':'none'}">
                <div class="container">
                    <div>
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Skills</label>
                                            <input type="text" class="form-control" placeholder="eg: .net"
                                                (change)="skillInput($event)">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group city-outer-bx has-feedback">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">By Industry:</label>
                                                <ngx-select-dropdown
                                                    [ngModel]="selectedObject(industryList,jobSeekerProffDetailModel.industryId )"
                                                    [config]="configSelect" (change)="assignIndustry($event.value.id)"
                                                    [instanceId]="'instance21'" [multiple]="false"
                                                    [options]="industryList"></ngx-select-dropdown>

                                            </div>

                                        </div>
                                        <span class="mandatory"
                                            *ngIf="jobSeekerProffDetailModel.industryId == 0 && isSubmit">This field is
                                            requied</span>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group city-outer-bx has-feedback">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">By Functional Area/Category:</label>
                                                <ngx-select-dropdown
                                                    [ngModel]="selectedObject(functionalAreaList,jobSeekerProffDetailModel.functionalAreaId )"
                                                    [config]="configSelect"
                                                    (change)="assignFunctionalArea($event.value.id)"
                                                    [instanceId]="'instance21'" [multiple]="false"
                                                    [options]="functionalAreaList"></ngx-select-dropdown>

                                            </div>

                                        </div>
                                        <span class="mandatory"
                                            *ngIf="jobSeekerProffDetailModel.functionalAreaId == 0 && isSubmit">This
                                            field is
                                            requied</span>
                                    </div>

                                    <!--Job Category-->
                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group city-outer-bx has-feedback">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">By Course/Qualification:</label>
                                                <ngx-select-dropdown
                                                    [ngModel]="selectedObject(courseModelList,jobSeekerProffDetailModel.courseId )"
                                                    [config]="configSelect" (change)="assignCourseId($event.value.id)"
                                                    [instanceId]="'instance21'" [multiple]="false"
                                                    [options]="courseModelList"></ngx-select-dropdown>

                                            </div>

                                        </div>
                                        <span class="mandatory"
                                            *ngIf="jobSeekerProffDetailModel.courseId == 0 && isSubmit">This
                                            field is
                                            requied</span>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group city-outer-bx has-feedback">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">By Avalibility:</label>
                                                <ngx-select-dropdown
                                                    [ngModel]="selectedObject(availabilityModelList,jobSeekerProffDetailModel.availabilityToJoinId )"
                                                    [config]="configSelect"
                                                    (change)="assignAvalibility($event.value.id)"
                                                    [instanceId]="'instance21'" [multiple]="false"
                                                    [options]="availabilityModelList"></ngx-select-dropdown>

                                            </div>

                                        </div>
                                        <span class="mandatory"
                                            *ngIf="jobSeekerProffDetailModel.availabilityToJoinId == 0 && isSubmit">This
                                            field is
                                            requied</span>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class="form-group city-outer-bx has-feedback">
                                            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                                <label _ngcontent-sva-c15="">By Work Level:</label>
                                                <ngx-select-dropdown
                                                    [ngModel]="selectedObject(workLevelModelList,jobSeekerProffDetailModel.workLevelId )"
                                                    [config]="configSelect" (change)="assignWorkLevel($event.value.id)"
                                                    [instanceId]="'instance21'" [multiple]="false"
                                                    [options]="workLevelModelList"></ngx-select-dropdown>

                                            </div>

                                        </div>
                                        <span class="mandatory"
                                            *ngIf="jobSeekerProffDetailModel.workLevelId == 0 && isSubmit">This
                                            field is
                                            requied</span>
                                    </div>
                                    <!-- <div class="col-sm-6 col-lg-3">
                                <div class="form-group">
                                    <ngx-select-dropdown [config]="configStatic" [instanceId]="'instance21'"
                                        [multiple]="false" [options]="['Male','Female']"></ngx-select-dropdown>

                                  
                                </div>
                            </div> -->
                                    <!-- <div class="col-sm-6 col-lg-3">
                                <div class="form-group">
                                    <select>
                                        <option>Filter By Category</option>
                                        <option>Another option</option>
                                        <option>A option</option>
                                        <option>Potato</option>
                                    </select>
                                </div>
                            </div> -->
                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class=" form-group">
                                            <label>By Location</label>
                                            <ngx-select-dropdown
                                                [ngModel]="selectedObject(countryModelList,candiFilter.locationId )"
                                                [config]="configSelect" (change)="assignCountry($event.value.id)"
                                                [instanceId]="'instance21'" [multiple]="false"
                                                [options]="countryModelList"></ngx-select-dropdown>

                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 col-md-12">
                                        <div class=" form-group">
                                            <label>By Nationality</label>
                                            <ngx-select-dropdown
                                                [ngModel]="selectedObject(nationalityList,candiFilter.nationalityId )"
                                                [config]="configSelect" (change)="assignNationality($event.value.id)"
                                                [instanceId]="'instance21'" [multiple]="false"
                                                [options]="nationalityList"></ngx-select-dropdown>

                                        </div>
                                    </div>

                                    <div class="col-12 ">
                                        <div class="form-group">
                                            <label><b>Experience</b></label>
                                            <div class="row">
                                                <div class="col-6 col-md-3">
                                                    <label>Min Experience</label>
                                                    <input type="number" class="form-control" placeholder="2"
                                                        [(ngModel)]="candiFilter.minExperience">
                                                </div>
                                                <div class="col-6 col-md-3">
                                                    <label>Max Experience</label>
                                                    <input type="number" class="form-control" placeholder="2"
                                                        [(ngModel)]="candiFilter.maxExperience">
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label><b>Salary</b></label>
                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <label>Min Salary</label>
                                                    <input type="number" class="form-control" placeholder="2"
                                                        [(ngModel)]="candiFilter.minSalary">
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <label>Max Salary</label>
                                                    <input type="number" class="form-control" placeholder="2"
                                                        [(ngModel)]="candiFilter.maxSalary">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-lg-3  mt-5">
                                        <button type="submit" class="btn cmn-btn" (click)="submit()">
                                            Search By Filter
                                            <i class='bx bx-plus'></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Filter -->

            <div class="row mt-5" style="transform: none;">


                <div class="col-lg-12 col-md-12">

                    <div class="twm-jobs-list-wrap1">
                        <span class="woocommerce-result-count-left">Showing {{currPage * countPerpage + 1}} total of {{total}}
                            candidate</span>

                        <!-- <div class="form-group mb-4">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search by Skills"
                                    (keyup)="skillInput($event)">
                                <button class="btn" type="button"><i class="feather-search"></i></button>
                            </div>
                        </div> -->
                        <ul class="mt-3">
                            <li *ngFor="let item of jobSeekerList | paginate: { itemsPerPage: countPerpage, currentPage: currPage + 1,totalItems:total }">
                                <app-candidate-widget [packageExist]="packageExist" [jobSeeker]="item"></app-candidate-widget>
                        </ul>
                        <div class="pagination-outer">
                            <div class="pagination-style1">
                                <pagination-controls (pageChange)="paginate($event)"></pagination-controls>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- OUR BLOG END -->



</div>