import { Component, OnInit } from '@angular/core';
import { JobFilterModel } from 'src/app/model/JobFilter';
import { JobsModel } from 'src/app/model/Jobs';
import { CityModel } from 'src/app/model/master/City';
import { CourseModel } from 'src/app/model/master/Course';
import { FunctionalAreaModel } from 'src/app/model/master/FunctionalArea';
import { IndustryModel } from 'src/app/model/master/Industry';
import { JobTypeModel } from 'src/app/model/master/JobType';
import { NationalityModel } from 'src/app/model/master/Nationality';
import { AuthService } from 'src/app/services/auth.service';
import { JobService } from 'src/app/services/job.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss']
})
export class ViewJobComponent implements OnInit {
  masterService: MasterService;
  p: any;
  areaFilter: string = ""
  locationFilter: string = ""
  baseUrl: string = environment.apiUrl + "ProfilePhoto/company";
  constructor(masterService: MasterService, private jobSrv: JobService, private authService: AuthService,
    public loader: LoaderService) {
    this.masterService = masterService;
    this.loader.loader = true;
  }
  ngOnInit(): void {
    var urlString = window.location.href;
    var url = new URL(urlString);
    var id = url.searchParams.get("jobId");
    if (id != null && id != undefined && id != "") {
      this.jobFilter.jobTypeId = [parseInt(id)];
    }
    id = url.searchParams.get("funcId");
    if (id != null && id != undefined && id != "") {
      this.jobFilter.functinalAreaId = [parseInt(id)];
    }
    id = url.searchParams.get("locId");
    if (id != null && id != undefined && id != "") {
      this.jobFilter.jobLocationId = [parseInt(id)];
    }
    id = url.searchParams.get("jobTitle");
    if (id != null && id != undefined && id != "") {
      this.jobFilter.jobTitle = id;
    }

    this.masterService.getNationalitAll().subscribe((data) => {
      this.nationalityList = data;
      console.log(this.nationalityList);
    });
    this.masterService.getCityAll().subscribe((data) => {
      this.cityList = data;
      console.log(this.cityList);
    });
    this.masterService.getJobTypeAll().subscribe((data) => {
      this.jobTypeList = data;
      console.log(this.jobTypeList);
    });
    this.masterService.getfunctionalAreaAll().subscribe((data) => {
      this.functionalAreaList = data;
      console.log(this.functionalAreaList);
    });
    this.masterService.getCourseAll().subscribe((data) => {
      this.coursesList = data;
      console.log(this.functionalAreaList);
    });
    // this.dataSource.sort = this.sort;
    this.getJobList();

  }
  industry: IndustryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  industryList: Array<IndustryModel> = []

  city: CityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  cityList: Array<CityModel> = []

  nationality: NationalityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  nationalityList: Array<NationalityModel> = []

  jobType: JobTypeModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  jobTypeList: Array<JobTypeModel> = []

  functionalArea: FunctionalAreaModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    count: 0
  }
  functionalAreaList: Array<FunctionalAreaModel> = []

  courses: CourseModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  coursesList: Array<CourseModel> = []

  job: JobsModel = {
    id: 0,
    companyId: this.authService.getAuthStorage().companyId,
    jobTitle: "",
    experieance: "",
    jobTypeId: 0,
    jobLocationId: 0,
    countryId: 0,
    nationalityId: 0,
    applicant: 0,
    gender: "",
    totalNoOfVacancy: 0,
    functinalAreaId: 0,
    jobDescription: "",
    jobViews: 0,
    creationDate: "",
    jobType: null,
    jobLocation: null,
    functinalArea: null,
    nationality: null,
    company: null,
    userDefined1: "",
    userDefined2: "",
    userDefined3: "",
    userDefined4: "",
    country: null,
    keySkill: "",
    jobQuestions: null
  }
  jobList: Array<JobsModel> = [];

  jobFilter: JobFilterModel = {
    jobTypeId: [],
    functinalAreaId: [],
    jobLocationId: [],
    experience: [],
    postDate: [],
    companyId: [],
    jobTitle: ""
  }

  delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  }

  getJobList() {
    this.loader.loader = true;
    this.jobSrv.getByFilter(this.jobFilter).subscribe((data) => {
      data = data.reverse()
      this.jobList = data;
      this.loader.loader = false;
    });
  }

  paginate(p: any) {
    this.p = p;
  }

  filterArea(event: any) {
    console.log("filterArea");
    this.areaFilter = event.target.value;
  }

  filterLocation(event: any) {
    console.log("filterArea");
    this.locationFilter = event.target.value;
  }

  functionalareaFilter(id: Number) {
    console.log("functionalAreaFilter", id);
    if (this.jobFilter.functinalAreaId.indexOf(id) == -1) {

      this.jobFilter.functinalAreaId.push(id);
    } else {
      let index: number = this.jobFilter.functinalAreaId.indexOf(id);
      this.jobFilter.functinalAreaId.splice(index, 1);
    }
    this.getJobList();
    console.log(this.jobFilter);
  }

  dateFilter(data: string) {
    if (!this.jobFilter.postDate.includes(data)) {
      this.jobFilter.postDate = [data];
    } else {
      this.jobFilter.postDate = [];
    }
    this.getJobList();
  }

  locationFilter2(id: Number) {
    console.log("functionalAreaFilter", id);
    if (this.jobFilter.jobLocationId.indexOf(id) == -1) {

      this.jobFilter.jobLocationId.push(id);
    } else {
      let index: number = this.jobFilter.jobLocationId.indexOf(id);
      this.jobFilter.jobLocationId.splice(index, 1);
    }
    this.getJobList();
    console.log(this.jobFilter);
  }
  async filterTitle(event: any) {
    this.jobFilter.jobTitle = event.target.value;
    await this.delay(2000);
    this.getJobList();
  }

  typeOfEmp(id: Number) {
    console.log("functionalAreaFilter", id);
    if (this.jobFilter.jobTypeId.indexOf(id) == -1) {

      this.jobFilter.jobTypeId.push(id);
    } else {
      let index: number = this.jobFilter.jobTypeId.indexOf(id);
      this.jobFilter.jobTypeId.splice(index, 1);
    }
    this.getJobList();
    console.log(this.jobFilter);
  }


}
