<!-- HEADER START -->
<header class="site-header header-style-3 mobile-sider-drawer-menu">
    <div class="sticky-wrapper" style="height: 90px; ">
        <div class="sticky-header main-bar-wraper navbar-expand-lg is-fixed">
            <div class="main-bar" style="border-bottom: 5px solid #cb3925 !important;">
                <div class="container clearfix">
                    <div class="logo-header">
                        <div class="logo-header-inner logo-header-one">
                            <a href="/">
                                <img src="assets/img/logo_mahad.png" alt="" />
                            </a>
                        </div>
                    </div>

                    <!-- NAV Toggle Button -->
                    <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button"
                        class="navbar-toggler collapsed">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar icon-bar-first"></span>
                        <span class="icon-bar icon-bar-two"></span>
                        <span class="icon-bar icon-bar-three"></span>
                    </button>

                    <!-- MAIN Vav -->
                    <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                        <ul class="nav navbar-nav">
                            <li class="has-child">
                                <a routerLink="/view-job">Jobs</a>
                                <div class="fa fa-angle-right submenu-toogle"></div>
                            </li>
                            <li class="has-child" *ngIf="type != 'user'">
                                <a href="/employer-dashboard">Employers</a>
                                <div class="fa fa-angle-right submenu-toogle"></div>
                            </li>
                        </ul>
                    </div>

                    <!-- Header Right Section-->
                    <div class="extra-nav header-2-nav">
                        <!-- <div class="extra-cell">
                        <div class="header-search">
                            <a href="#search" class="header-search-icon"><mat-icon class="feather-search mat-icon">login</mat-icon> </a>
                        </div>                                
                    </div> -->
                        <div class="extra-cell">
                            <div class="header-nav-btn-section">
                                <div class="twm-nav-btn-left" *ngIf="!IsAuthenticated">
                                    <a class="twm-nav-sign-up" routerLink="/login" data-bs-toggle="modal"
                                        href="#sign_up_popup" role="button">
                                        <mat-icon class="feather-search mat-icon">login</mat-icon><span
                                            class="span-class">Sign Up/Login</span>
                                    </a>
                                </div>

                                <div class="twm-nav-btn-right" *ngIf="IsAuthenticated">
                                    <a routerLink="/user-profile" class="twm-nav-post-a-job">
                                        <mat-icon class="feather-search mat-icon">person</mat-icon>
                                        <span class="span-class">{{
                                            name
                                            }}</span>
                                    </a>
                                </div>
                                <div class="twm-nav-btn-right" *ngIf="IsAuthenticated">
                                    <a (click)="logout()" class="twm-nav-post-a-job">
                                        <mat-icon class="feather-search mat-icon">logout</mat-icon>
                                        <span class="span-class">Logout</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- SITE Search -->
            <div id="search">
                <span class="close"></span>
                <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                    <input class="form-control" value="" name="q" type="search" placeholder="Type to search" />
                    <span class="input-group-append">
                        <button type="button" class="search-btn">
                            <i class="fa fa-paper-plane"></i>
                        </button>
                    </span>
                </form>
            </div>
        </div>
    </div>
</header>