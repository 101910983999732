import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyUserModel } from 'src/app/model/CompanyUser';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyUserService } from 'src/app/services/companyUser.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';

@Component({
  selector: 'app-company-user',
  templateUrl: './company-user.component.html',
  styleUrls: ['./company-user.component.scss']
})
export class CompanyUserComponent implements OnInit {
  p: any;
  submitEducation: boolean = false;
  constructor(private authService: AuthService, private uploadMedia: UploadMediaService,
    private companyUserService: CompanyUserService, private masterService: MasterService,
    private tost: ToastrService, public loader: LoaderService) {

  }
  ngOnInit(): void {
    this.getUser();
  }

  configStatic = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  companyUser: CompanyUserModel = {
    id: 0,
    companyId: 0,
    userId: '',
    fullName: '',
    mobileNo: '',
    emailId: '',
    role: '',
    password: '',
  }
  companyUserList: Array<CompanyUserModel> = [];



  getUser() {
    console.log("KK");
    this.companyUser.companyId = this.authService.getAuthStorage().companyId;
    this.companyUserService.getCompanyUser(this.companyUser.companyId).subscribe((data) => {
      this.companyUserList = data;
      console.log("KK", data);
    })
  }
  assignRole(role: string) {
    this.companyUser.role = role
  }

  addRole() {
    if (this.companyUser.password.length < 6) {
      this.tost.error("Password must be at least 6 characters long");
      return;

    }
    if (!this.companyUser.password.match(/(?=.*?[0-9])/)) {
      this.tost.error("Password must be at least 1 digit");
      return;

    }
    if (this.companyUser.mobileNo.toString().trim().length < 7) {
      this.tost.error("Enter valid mobile number");
      return;


    }
    if (this.companyUser.fullName.toString().trim().length < 1) {
      this.tost.error("Enter valid name");
      return;


    }
    if (!this.companyUser.emailId.toString().trim().includes("@")) {
      this.tost.error("Enter valid email");
      return;

    }
    this.companyUser.companyId = this.authService.getAuthStorage().companyId;
    this.companyUserService.create(this.companyUser).subscribe((data) => {
      if (data.isSuccess) {
        this.tost.success("Role Added Successfully");
        this.resetRole();
        this.getUser();
      } else {
        this.tost.error("Error creating user");
      }
    })
  }

  resetRole() {
    this.companyUser = {
      id: 0,
      companyId: 0,
      userId: '',
      fullName: '',
      mobileNo: '',
      emailId: '',
      role: '',
      password: '',
    }
  }

  paginate(p: any) {
    this.p = p;
  }
}
