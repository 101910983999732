import { BrowserModule } from '@angular/platform-browser';
import { NgModule, } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { NavbarStyleThreeComponent } from './components/common/navbar-style-three/navbar-style-three.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { EmployersDetailsComponent } from './components/pages/employers-details/employers-details.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FavouriteJobsComponent } from './components/pages/favourite-jobs/favourite-jobs.component';
import { CandidatesComponent } from './components/pages/candidates/candidates.component';
import { CandidatesDetailsComponent } from './components/pages/candidates-details/candidates-details.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { AuthService } from './services/auth.service';
import { LoginRouteResolverService } from './services/login-route-resolver.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './services/auth.guard';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { EmployerDashboardComponent } from './components/pages/employer-dashboard/employer-dashboard.component';
import { SideNavComponent } from './components/common/side-nav/side-nav.component';
import { EmployerTopNavComponent } from './components/common/employer-top-nav/employer-top-nav.component';
import { EmployerCompanyComponent } from './components/pages/employer-company/employer-company.component';
import { MatIconModule } from '@angular/material/icon';
import { EmployerPostjobComponent } from './components/pages/employer-postjob/employer-postjob.component';
import { EmployerManagejobComponent } from './components/pages/employer-managejob/employer-managejob.component';
import { EmployerViewcandidateComponent } from './components/pages/employer-viewcandidate/employer-viewcandidate.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ViewJobComponent } from './components/pages/view-job/view-job.component';
import { MatTabsModule } from '@angular/material/tabs';
import { UserProfileByEmployerComponent } from './components/pages/user-profile-by-employer/user-profile-by-employer.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { EmployerSignupComponent } from './components/pages/employer-signup/employer-signup.component';
import { EmployerLoginComponent } from './components/pages/employer-login/employer-login.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { PackageComponent } from './components/common/package/package.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PackagesComponent } from './components/pages/packages/packages.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { JobSeekerKeyskillComponent } from './components/common/job-seeker-keyskill/job-seeker-keyskill.component';
import { JobSeekerSkillcertificationComponent } from './components/common/job-seeker-skillcertification/job-seeker-skillcertification.component';
import { JobSeekerLanguageComponent } from './components/common/job-seeker-language/job-seeker-language.component';
import { FogetPwdComponent } from './components/pages/foget-pwd/foget-pwd.component';
import { ResetPwdComponent } from './components/pages/reset-pwd/reset-pwd.component';
// import { NgxMaskDirective } from 'ngx-mask/lib/ngx-mask.directive';
import { NgxMaskDirective, provideNgxMask, NgxMaskPipe } from 'ngx-mask';
import { AllCandidateComponent } from './components/pages/all-candidate/all-candidate.component';
import { CandidateWidgetComponent } from './components/common/candidate-widget/candidate-widget.component';
import { ShortlistedCandidateComponent } from './components/pages/shortlisted-candidate/shortlisted-candidate.component';
import { CompanyUserComponent } from './components/pages/company-user/company-user.component';
import { CompanyUserWidgetComponent } from './components/common/company-user-widget/company-user-widget.component';
import { ChangePwdComponent } from './components/pages/change-pwd/change-pwd.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { UserProfileComponent } from './components/pages/user-profile/user-profile.component';
import { OurPackagesComponent } from './components/pages/our-packages/our-packages.component';
import { JobQuestionAnswerComponent } from './components/common/job-question-answer/job-question-answer.component';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import {MatSliderModule} from '@angular/material/slider';
import { JobSeekerProfDetailComponent } from './components/common/job-seeker-prof-detail/job-seeker-prof-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    NavbarStyleTwoComponent,
    NavbarStyleThreeComponent,
    FooterStyleTwoComponent,
    EmployersComponent,
    EmployersDetailsComponent,
    DashboardComponent,
    FaqComponent,
    ComingSoonComponent,
    ErrorComponent,
    AboutComponent,
    FavouriteJobsComponent,
    CandidatesComponent,
    CandidatesDetailsComponent,
    BlogDetailsComponent,
    BlogComponent,
    ContactComponent,
    EmployerDashboardComponent,
    SideNavComponent,
    EmployerTopNavComponent,
    EmployerCompanyComponent,
    EmployerPostjobComponent,
    EmployerManagejobComponent,
    EmployerViewcandidateComponent,
    ViewJobComponent,
    UserProfileByEmployerComponent,
    LoadingComponent,
    EmployerSignupComponent,
    EmployerLoginComponent,
    HomePageComponent,
    ContactUsComponent,
    PackageComponent,
    PackagesComponent,
    JobSeekerKeyskillComponent,
    JobSeekerSkillcertificationComponent,
    JobSeekerLanguageComponent,
    FogetPwdComponent,
    ResetPwdComponent,
    AllCandidateComponent,
    CandidateWidgetComponent,
    ShortlistedCandidateComponent,
    CompanyUserComponent,
    CompanyUserWidgetComponent,
    ChangePwdComponent,
    UserProfileComponent,
    OurPackagesComponent,
    JobQuestionAnswerComponent,
    JobSeekerProfDetailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    AngularEditorModule,
    MatDatepickerModule,
    NgxMaskDirective, NgxMaskPipe,
    MatNativeDateModule,
    MatRippleModule,
    // NopAnimationsModule
    // prime face

    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SelectDropDownModule,
    MatSliderModule
  ],
  providers: [
    AuthService,
    LoginRouteResolverService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
