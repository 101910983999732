import { Component, OnInit, } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CountryCode } from 'src/app/model/CountryCode';
import { EmployerRegisterModel } from 'src/app/model/EmployerRegisterModel';
import { UploadMedia } from 'src/app/model/UploadMedia';
import { CityModel } from 'src/app/model/master/City';
import { CountryModel } from 'src/app/model/master/Country';
import { IndustryModel } from 'src/app/model/master/Industry';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';


@Component({
  selector: 'app-employer-signup',
  templateUrl: './employer-signup.component.html',
  styleUrls: ['./employer-signup.component.scss']
})
export class EmployerSignupComponent implements OnInit {
  private countryCode: CountryCode
  yearList: Array<number> = [];
  yearList0: number[] = [];


  constructor(
    private masterService: MasterService,
    private fb: FormBuilder,
    private tost: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private uploadMedia: UploadMediaService,
    public loader: LoaderService,
    private routerLink: Router,

  ) {
    this.loader.loader = false;
    this.countryCode = new CountryCode();
  }
  ngOnInit(): void {
    this.yearList0 = this.masterService.yearList();
    this.masterService.getIndustryAll().subscribe((data) => {
      this.industryList = data;
      console.log(this.industryList);
    });
    this.masterService.getCountryAll().subscribe((data) => {
      this.countryList = data;
    });
    this.getCountryCode();
    this.getYerList();
  }

  configSelect = {
    displayFn: (item: any) => { return item.nameEn; },
    displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    searchOnKey: 'nameEn',
    limitTo: 0,
    height: "231px",
    enableSelectAll: true,
  };

  configStaticNoSearch = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: false,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  configStatic = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  error: string = "";
  url: string = "";
  file0: any = null;
  fileCover0: any = null;
  filePdf: any = null;
  isSubmit: boolean = false;
  termCond: boolean = false;
  logoFile: string = "";
  coverFile: string = "";
  register: EmployerRegisterModel = {
    nameEn: "",
    nameAr: "",
    descriptions: "",
    emailId: "",
    locationId: 0,
    countryId: 0,
    phoneNo: "",
    industryId: 0,
    crnumber: "",
    establishmentYear: 0,
    address: "",
    fullName: "",
    mobileNo: "",
    password: "",
    role: "admin",
  };
  madatoryRegister = [
    "nameEn",
    "descriptions",
    "emailId",
    "locationId",
    "phoneNo",
    "industryId",
    "crnumber",
    "establishmentYear",
    "countryId",
    "fullName",
    "mobileNo",
    "password"
  ];
  industry: IndustryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  industryList: Array<IndustryModel> = []

  city: CityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  cityList: Array<CityModel> = []

  country: CountryModel = {
    id: 0,
    nameEn: '',
    nameAr: ''
  }
  countryList: Array<CountryModel> = []

  media: UploadMedia = {
    id: 0,
    base64: "",
    name: ""
  }

  public options: Object = {
    toolbarInline: true,
    charCounterCount: false,
    toolbarVisibleWithoutSelection: true
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '23rem',
    minHeight: '5rem',
    placeholder: 'Enter text in this rich text editor....',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: 'Quote',
        class: 'quoteClass',
      },
      {
        name: 'Title Heading',
        class: 'titleHead',
        tag: 'h1',
      },
    ],

  };

  upload(event: any) {
    this.file0 = event.target.files[0];
    this.logoFile = this.file0["name"]
  }

  async handleUpload(id: string) {
    const file = this.file0;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      this.media.id = parseInt(id);
      this.media.name = "company" + id + "." + type;
      this.media.base64 = reader.result.toString().split(";base64,")[1];
      this.uploadMedia.uploadProfileCompanyLogo(this.media).subscribe((data) => {
        this.loader.loader = false;
        this.routerLink.navigate(["/login"]);

        // window.location.reload();
      });
      // console.log(reader.result,type);
    };
  }

  uploadCover(event: any) {
    this.fileCover0 = event.target.files[0];
    this.coverFile = this.fileCover0["name"]
  }

  async handleUploadCover(id: string) {
    const file = this.fileCover0;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      this.media.id = parseInt(id);
      this.media.name = "companyCover" + id + "." + type;
      this.media.base64 = reader.result.toString().split(";base64,")[1];
      this.uploadMedia.uploadProfileCover(this.media).subscribe((data) => {
        if (data == false) {
          this.tost.error("Error uploading cover photo");
        }

        // window.location.reload();
      });
      // console.log(reader.result,type);
    };
  }


  assignIndustry(id: Number) {
    this.register.industryId = id;
  }

  assignCity(id: Number) {
    this.register.locationId = id;
  }

  assignCountry(id: Number) {
    this.register.countryId = id;
    // this.register.locationId = 0;
    this.cityList = [];
    this.masterService.getCityByCountryId(id).subscribe((data) => {
      this.cityList = data;
    });
  }

  assignYear(year: number) {
    this.register.establishmentYear = year;
  }

  async registerEmployer() {
    console.log(this.register);
    if (!this.termCond) {
      this.tost.warning("Please accept term conditions");
      return;
    }
    if (!this.register.emailId.includes("@") || !this.register.emailId.includes(".")) {
      this.loader.loader = false;
      this.isSubmit = true;
      return;
    }
    this.loader.loader = true;
    if (this.masterService.validate(this.madatoryRegister, this.register) && this.file0 != null) {
      this.authService.registerEmployer(this.register).subscribe((data: any) => {
        this.tost.success("Successfully registered");
        if (data.isSuccess) {
          this.handleUpload(data.objects["companyId"]);
          this.handleUploadCover(data.objects["companyId"]);
        }
        this.register = {
          nameEn: "",
          nameAr: "",
          descriptions: "",
          emailId: "",
          locationId: 0,
          phoneNo: "",
          industryId: 0,
          role: "admin",
          countryId: 0,
          crnumber: "",
          establishmentYear: 0,
          address: "",
          fullName: "",
          mobileNo: "",
          password: ""
        };

      })
    } else {
      this.isSubmit = true;
      this.loader.loader = false;
    }
    // window.location.href = "/employer-login"
  }

  getCountryCode() {
    // this.masterService.getIp().subscribe((ip) => {
    // })
    // this.masterService.getCurrentAccessInfo("ip").subscribe((data) => {
    //   this.register.phoneNo = this.countryCode.getCode(data) + " ";
    //   this.register.mobileNo = this.register.phoneNo
    // })
  }

  async getYerList() {
    for (let i = 1900; i <= 2023; i++) {
      this.yearList.push(i);
    }
  }

  chosenYearHandler(event: any, dp: any) {
    dp.close();
  }

  selectedObject(list, val) {
    return list.find(n => n.id == val);
  }


}
