import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyPackageModel } from 'src/app/model/CompanyPackage';
import { PackageModel } from 'src/app/model/master/Package';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { CompanyPackageService } from 'src/app/services/companyPackage';
import { JobService } from 'src/app/services/job.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  constructor(private authService: AuthService, private uploadMedia: UploadMediaService,
    private companyService: CompanyService, private masterService: MasterService,
    private packageService: CompanyPackageService, private tost: ToastrService, private jobSeekerSrv: JobSeekerService) {

  }

  package: PackageModel = {
    id: 0,
    name: '',
    description: '',
    price: 0,
    featureList: '',
    jobPostAllowed: 0,
    candidatesShortlist: 0
  }
  packageList: Array<PackageModel> = [];
  ngOnInit(): void {
    this.masterService.getPackageAll().subscribe((data) => {
      this.packageList = data;
      this.checkPackage();
    });
  }

  compPackage: CompanyPackageModel = {
    id: 0,
    fromDate: '',
    toDate: '',
    companyId: 0,
    packageId: 0,
  }

  checkPackage() {
    let compId: Number = this.authService.getAuthStorage().companyId;
    this.packageService.getByCompId(compId).subscribe((data) => {
      if (data.length > 0) {
        this.compPackage = data[0];
        for (let i in this.packageList) {
          if (this.packageList[i].id == this.compPackage.packageId) {
            this.packageList = [this.packageList[i]];
            break;
          }
        }
      }
    })
  }
}
