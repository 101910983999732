<div class="twm-jobs-list-style1 mb-5" *ngIf="companyUser != null">
    <div class="twm-media">
        <i class="fa fa-user i"></i>
        <!-- <img [src]="urlUser" alt="#"> -->
        <!-- <p class="twm-job-address">{{job.company["location"]}}</p> -->
    </div>
    <div class="twm-mid-content">
        <h4>{{companyUser.fullName}}</h4>
        
        <!-- <a [href]="url" class="twm-job-title">
            <h4>Preview Cv<br /><span class="twm-job-post-duration">
                </span></h4>
        </a> -->
        <p class="twm-job-address " > <mat-icon class="mat-icon">email</mat-icon> {{companyUser.emailId}}</p>
        <p class="twm-job-address"> <mat-icon class="mat-icon">phone_iphone</mat-icon> {{companyUser.mobileNo}}</p>
        <!-- <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a> -->
        <!-- <div class="twm-jobs-category green pointer"><span class="twm-bg-sky"><i class="fa fa-save"></i> Save</span>
        </div> -->
        <!-- <button _ngcontent-lkq-c45="" class="site-button" style="color: white;"><i class="fa fa-save"></i>
            Save</button> -->
    </div>
    
    <div class="twm-right-content admin" (click)="toggleAdmin(companyUser.id,companyUser.role.trim() == 'admin'?'user':'admin')">
        <!-- <div class="twm-jobs-category green"><span class="twm-bg-green">{{job.jobType}}</span></div>
        <div class="twm-jobs-amount">{{job.userDefined1}} <span>/ {{job.userDefined2}}</span></div> -->
        <a class="twm-jobs-browse site-text-primary">{{companyUser.role.trim() == "admin"? "Remove admin":"Make admin"}}</a>
        <br>


    </div>
</div>