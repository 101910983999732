<div class="panel panel-default">
    <div class="we-disp mt-3 d-flex">
        <h4 class="twm-s-title">
            Work Experience
        </h4>
    </div>
    <div class="panel-body wt-panel-body  m-b30 ">
        <div>
            <div class="row">

                <div class="col-xl-4 col-lg-6 col-md-12">
                    <div class="form-group city-outer-bx has-feedback">
                        <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                            <label _ngcontent-sva-c15="">Industry:</label>
                            <ngx-select-dropdown
                                [ngModel]="selectedObject(industryList,jobSeekerProffDetailModel.industryId )"
                                [config]="configSelect" (change)="assignIndustry($event.value.id)"
                                [instanceId]="'instance21'" [multiple]="false" [options]="industryList"
                                [disabled]="true"></ngx-select-dropdown>

                        </div>

                    </div>
                    <span class="mandatory" *ngIf="jobSeekerProffDetailModel.industryId == 0 && isSubmit">This field is
                        requied</span>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-12">
                    <div class="form-group city-outer-bx has-feedback">
                        <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                            <label _ngcontent-sva-c15="">Functional Area/Category:</label>
                            <ngx-select-dropdown
                                [ngModel]="selectedObject(functionalAreaList,jobSeekerProffDetailModel.functionalAreaId )"
                                [config]="configSelect" (change)="assignFunctionalArea($event.value.id)"
                                [instanceId]="'instance21'" [multiple]="false" [options]="functionalAreaList"
                                [disabled]="true"></ngx-select-dropdown>

                        </div>

                    </div>
                    <span class="mandatory" *ngIf="jobSeekerProffDetailModel.functionalAreaId == 0 && isSubmit">This
                        field is
                        requied</span>
                </div>

                <!--Job Category-->
                <div class="col-xl-4 col-lg-6 col-md-12">
                    <div class="form-group city-outer-bx has-feedback">
                        <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                            <label _ngcontent-sva-c15="">Current Course/Qualification:</label>
                            <ngx-select-dropdown
                                [ngModel]="selectedObject(courseModelList,jobSeekerProffDetailModel.courseId )"
                                [config]="configSelect" (change)="assignCourse($event.value.id)"
                                [instanceId]="'instance21'" [multiple]="false" [options]="courseModelList"
                                [disabled]="true"></ngx-select-dropdown>

                        </div>

                    </div>
                    <span class="mandatory" *ngIf="jobSeekerProffDetailModel.courseId == 0 && isSubmit">This field is
                        requied</span>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-12">
                    <div class="form-group city-outer-bx has-feedback">
                        <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                            <label _ngcontent-sva-c15="">Avalibility:</label>
                            <ngx-select-dropdown
                                [ngModel]="selectedObject(availabilityModelList,jobSeekerProffDetailModel.availabilityToJoinId )"
                                [config]="configSelect" (change)="assignAvalibility($event.value.id)"
                                [instanceId]="'instance21'" [multiple]="false" [options]="availabilityModelList"
                                [disabled]="true"></ngx-select-dropdown>

                        </div>

                    </div>
                    <span class="mandatory" *ngIf="jobSeekerProffDetailModel.availabilityToJoinId == 0 && isSubmit">This
                        field is
                        requied</span>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-12">
                    <div class="form-group city-outer-bx has-feedback">
                        <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                            <label _ngcontent-sva-c15="">Work Level:</label>
                            <ngx-select-dropdown
                                [ngModel]="selectedObject(workLevelModelList,jobSeekerProffDetailModel.workLevelId )"
                                [config]="configSelect" (change)="assignWorkLevel($event.value.id)"
                                [instanceId]="'instance21'" [multiple]="false" [options]="workLevelModelList"
                                [disabled]="true"></ngx-select-dropdown>

                        </div>

                    </div>
                    <span class="mandatory" *ngIf="jobSeekerProffDetailModel.workLevelId == 0 && isSubmit">This
                        field is
                        requied</span>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-12">
                    <div class="form-group city-outer-bx has-feedback">
                        <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                            <label _ngcontent-sva-c15="">Total Experience Year:</label>
                            <div class="ls-inputicon-box">
                                <input type="number" class="form-control" placeholder="3"
                                    [(ngModel)]="jobSeekerProffDetailModel.totalWorkExperienceYear " [disabled]="true">
                                <span class="mandatory"
                                    *ngIf="(jobSeekerProffDetailModel.totalWorkExperienceYear == 0  || this.jobSeekerProffDetailModel.totalWorkExperienceYear == null) && isSubmit ">This
                                    field is
                                    requied</span>
                                <i class="fs-input-icon fa fa-address-card"></i>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="col-xl-4 col-lg-6 col-md-12">
                    <div class="form-group city-outer-bx has-feedback">
                        <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                            <label _ngcontent-sva-c15="">Total Experience Month:</label>
                            <div class="ls-inputicon-box">
                                <input type="number" class="form-control" placeholder="3"
                                    [(ngModel)]="jobSeekerProffDetailModel.totalWorkExperienceMonth " [disabled]="true">
                                <span class="mandatory"
                                    *ngIf="(jobSeekerProffDetailModel.totalWorkExperienceMonth == 0 || this.jobSeekerProffDetailModel.totalWorkExperienceMonth == null)&& isSubmit">This
                                    field is
                                    requied</span>
                                <i class="fs-input-icon fa fa-address-card"></i>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="col-xl-4 col-lg-6 col-md-12">
                    <div class="form-group city-outer-bx has-feedback">
                        <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                            <label _ngcontent-sva-c15="">Monthly Salary:</label>
                            <div class="ls-inputicon-box">
                                <input type="number" class="form-control" placeholder="3"
                                    [(ngModel)]="jobSeekerProffDetailModel.monthlySalary " [disabled]="true">
                                <span class="mandatory"
                                    *ngIf="(jobSeekerProffDetailModel.monthlySalary == 0 || this.jobSeekerProffDetailModel.monthlySalary == null)&& isSubmit">This
                                    field is
                                    requied</span>
                                <i class="fs-input-icon fa fa-address-card"></i>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>

    </div>
</div>