import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { JobsModel } from 'src/app/model/Jobs';
import { AuthService } from 'src/app/services/auth.service';
import { JobService } from 'src/app/services/job.service';
import { JobApplyService } from 'src/app/services/jobApply.service';
import { LoaderService } from 'src/app/services/loader.service';

export interface PeriodicElement {
  job_title: string;
  action: any;
  category: string;
  job_type: string;
  application: string;
  location: string;
}

@Component({
  selector: 'app-employer-managejob',
  templateUrl: './employer-managejob.component.html',
  styleUrls: ['./employer-managejob.component.scss']
})
export class EmployerManagejobComponent implements OnInit {

  ELEMENT_DATA: PeriodicElement[] = [
    // {job_title: 'Hydrogen', category: "Full Stack",application: '3 Applied',job_type:"Full time",action:""},
    // {job_title: 'Helium', category: "Full Stack", application: '3 Applied', job_type:"Freelance",action:""},
    // {job_title: 'Lithium', category:"Full Stack", application: '3 Applied',job_type:"Freelance",action:""},
    // {job_title: 'Beryllium', category: "Frontend", application: '3 Applied',job_type:"Freelance", action:""},
    // {job_title: 'Boron', category: "Frontend", application: '3 Applied',job_type:"Freelance",action:""},
    // {job_title: 'Carbon', category: "Frontend", application: '3 Applied',job_type:"Freelance",action:""},
    // {job_title: 'Nitrogen', category: "Backend", application: '3 Applied',job_type:"Part time",action:""},
    // {job_title: 'Oxygen', category: "Backend", application: '3 Applied',job_type:"Part time",action:""},
    // {job_title: 'Fluorine', category: "Backend", application: '3 Applied',job_type:"Part time",action:""},
    // { job_title: 'Neon', category: "Backend", application: '3 Applied',job_type:"Part time",action:""},
  ];

  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  displayedColumns: string[] = ['job_title', 'category', 'application', 'location', 'job_type', 'action'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  job: JobsModel = {
    id: 0,
    companyId: this.authService.getAuthStorage().companyId,
    jobTitle: "",
    experieance: "",
    jobTypeId: 0,
    jobLocationId: 0,
    countryId: 0,
    nationalityId: 0,
    gender: "",
    totalNoOfVacancy: 0,
    functinalAreaId: 0,
    jobDescription: "",
    creationDate: "",
    jobViews: 0,
    applicant: 0,
    jobType: null,
    jobLocation: null,
    functinalArea: null,
    nationality: null,
    company: null,
    userDefined1: "",
    userDefined2: "",
    userDefined3: "",
    userDefined4: "",
    country: null,
    keySkill: "",
    jobQuestions: null
  }
  jobList: Array<JobsModel> = [];

  constructor(private _liveAnnouncer: LiveAnnouncer, private jobSrv: JobService,
    private authService: AuthService, private jobApply: JobApplyService, public loader: LoaderService) {
    this.loader.loader = true;
  }
  ngOnInit(): void {
    // this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getJobList();
  }
  ngAfterViewInit() {
  }

  getJobList() {
    this.jobSrv.getByCompanyId(this.authService.getAuthStorage().companyId).subscribe((data) => {
      this.jobList = data;
      for (let i = 0; i < this.jobList.length; i++) {
        let dic: PeriodicElement = {
          job_title: this.jobList[i].jobTitle,
          job_type: this.jobList[i].jobType,
          category: this.jobList[i].functinalArea,
          application: this.jobList[i].applicant + " Applicant",
          location: this.jobList[i].jobLocation,
          action: this.jobList[i].id
        };
        this.ELEMENT_DATA.push(dic);
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
      }
      this.loader.loader = false;
    });
  }


  viewCandidate(id: Number) {
    window.location.href = "/employer-viewcandidate?Id=" + id;
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
