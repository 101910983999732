import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { CandidatesDetailsComponent } from './components/pages/candidates-details/candidates-details.component';
import { CandidatesComponent } from './components/pages/candidates/candidates.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { EmployerCompanyComponent } from './components/pages/employer-company/employer-company.component';
import { EmployerDashboardComponent } from './components/pages/employer-dashboard/employer-dashboard.component';
import { EmployerManagejobComponent } from './components/pages/employer-managejob/employer-managejob.component';
import { EmployerPostjobComponent } from './components/pages/employer-postjob/employer-postjob.component';
import { EmployerViewcandidateComponent } from './components/pages/employer-viewcandidate/employer-viewcandidate.component';
import { EmployersDetailsComponent } from './components/pages/employers-details/employers-details.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { FavouriteJobsComponent } from './components/pages/favourite-jobs/favourite-jobs.component';
import { UserProfileByEmployerComponent } from './components/pages/user-profile-by-employer/user-profile-by-employer.component';
import { ViewJobComponent } from './components/pages/view-job/view-job.component';
import { AuthGuard } from './services/auth.guard';
import { EmployerSignupComponent } from './components/pages/employer-signup/employer-signup.component';
import { EmployerLoginComponent } from './components/pages/employer-login/employer-login.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { PackagesComponent } from './components/pages/packages/packages.component';
import { FogetPwdComponent } from './components/pages/foget-pwd/foget-pwd.component';
import { ResetPwdComponent } from './components/pages/reset-pwd/reset-pwd.component';
import { AllCandidateComponent } from './components/pages/all-candidate/all-candidate.component';
import { ShortlistedCandidateComponent } from './components/pages/shortlisted-candidate/shortlisted-candidate.component';
import { CompanyUserComponent } from './components/pages/company-user/company-user.component';
import { ChangePwdComponent } from './components/pages/change-pwd/change-pwd.component';
import { UserProfileComponent } from './components/pages/user-profile/user-profile.component';
import { OurPackagesComponent } from './components/pages/our-packages/our-packages.component';

const routes: Routes = [
    { path: '', component: HomePageComponent },
    { path: 'employers', component: EmployersComponent },
    { path: 'employer-details', component: EmployersDetailsComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'faq', component: FaqComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'about', component: AboutComponent },
    { path: 'favourite-jobs', component: FavouriteJobsComponent },
    { path: 'candidates', component: CandidatesComponent },
    { path: 'candidate-details', component: CandidatesDetailsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'employer-dashboard', component: EmployerDashboardComponent, canActivate: [AuthGuard] },
    { path: 'employer-company', component: EmployerCompanyComponent },
    { path: 'employer-postjob', component: EmployerPostjobComponent, canActivate: [AuthGuard] },
    { path: 'employer-managejob', component: EmployerManagejobComponent },
    { path: 'employer-viewcandidate', component: EmployerViewcandidateComponent },
    { path: 'view-job', component: ViewJobComponent },
    { path: 'signup', component: EmployerSignupComponent },
    { path: 'login', component: EmployerLoginComponent },
    { path: 'user-profile-employer', component: UserProfileByEmployerComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'packages', component: PackagesComponent, canActivate: [AuthGuard] },
    { path: 'forget-pwd', component: FogetPwdComponent },
    { path: 'reset-pwd', component: ResetPwdComponent },
    { path: 'all-candidate', component: AllCandidateComponent, canActivate: [AuthGuard] },
    { path: 'shortlisted-candidate', component: ShortlistedCandidateComponent, canActivate: [AuthGuard] },
    { path: 'company-user', component: CompanyUserComponent, canActivate: [AuthGuard] },
    { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard] },
    { path: 'change-pwd', component: ChangePwdComponent, canActivate: [AuthGuard] },
    { path: 'our-packages', component: OurPackagesComponent },
    // { path: 'change-pwd', component: ChangePwdComponent, canActivate: [AuthGuard] },
    { path: '**', component: ErrorComponent } // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }