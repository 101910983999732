import { Component, OnInit } from '@angular/core';
import { AuthStorage } from 'src/app/model/AuthStorage';
import { JobSeekerModel } from 'src/app/model/JobSeeker';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyUserShorlistingService } from 'src/app/services/companyUserShortlisting.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-shortlisted-candidate',
  templateUrl: './shortlisted-candidate.component.html',
  styleUrls: ['./shortlisted-candidate.component.scss']
})
export class ShortlistedCandidateComponent  {

  p: any;
  constructor(masterService: MasterService, private jobSeekerSrv: CompanyUserShorlistingService, private authService: AuthService,
    public loader: LoaderService, private router: Router) {
    this.loader.loader = true;
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.getJobSeekerList("");
        /* Your code goes here on every router change */
        /* this function calls on navigate forward or back from another page */
      }
    });
  }
  ngOnInit(): void {
    // var urlString = window.location.href;
    // var url = new URL(urlString);
    // var skill = url.searchParams.get("skill");
    // if (skill != null && skill != undefined && skill != "") {
    //   this.getJobSeekerList(skill);
    // } else {
    //   this.getJobSeekerList("");
    // }
  }

  jobSeeker: JobSeekerModel = {
    id: 0,
    userId: '',
    fullNameEn: '',
    fullNameAr: '',
    cvheadline: '',
    eamil: '',
    mobileNo: '',
    currentCity: '',
    profilePicturePath: '',
    resumePath: '',
    coverLetterPath: ''
  }
  jobSeekerList: Array<JobSeekerModel> = [];

  getJobSeekerList(skill: string) {
    let data: AuthStorage = this.authService.getAuthStorage();

    if (data.companyUserId != null) {
      this.jobSeekerList = [];
      this.jobSeekerSrv.getShortlistedJobSeeker(data.companyUserId).subscribe((data) => {
        this.jobSeekerList = data;
        this.loader.loader = false;
      })
    }
  }

  delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  }

  paginate(p: any) {
    this.p = p;
  }
}
