<div class="page-content">

    <!-- INNER PAGE BANNER -->
    <div class="wt-bnr-inr overlay-wraper bg-center" style="background-image:url(images/banner/1.jpg);">
        <div class="overlay-main site-bg-white opacity-01"></div>
        <div class="container">
            <div class="wt-bnr-inr-entry">
                <div class="banner-title-outer">
                    <div class="banner-title-name">
                        <h2 class="wt-title">Contact Us</h2>
                    </div>
                </div>
                <!-- BREADCRUMB ROW -->

                <div>
                    <ul class="wt-breadcrumb breadcrumb-style-2">
                        <li><a href="/employer-dashboard">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>

                <!-- BREADCRUMB ROW END -->
            </div>
        </div>
    </div>
    <!-- INNER PAGE BANNER END -->

    <!-- CONTACT FORM -->
    <div class="section-full twm-contact-one">
        <div class="section-content">
            <div class="container">

                <!-- CONTACT FORM-->
                <div class="contact-one-inner">
                    <div class="row">

                        <div class="col-lg-6 col-md-12">
                            <div class="contact-form-outer">

                                <!-- TITLE START-->
                                <div class="section-head left wt-small-separator-outer">
                                    <h2 class="wt-title">Send Us a Message</h2>
                                    <p>Feel free to contact us and we will get back to you as soon as we can.</p>
                                </div>
                                <!-- TITLE END-->

                                <form class="cons-contact-form" method="post" action="form-handler2.php">
                                    <div class="row">

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group mb-3">
                                                <input [(ngModel)]="sentMessage.name" name="username" type="text"
                                                    required="" class="form-control" placeholder="Name">
                                            </div>
                                            <span class="mandatory" *ngIf="sentMessage.name == '' && submit">
                                                This Field is required!
                                            </span>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group mb-3">
                                                <input [(ngModel)]="sentMessage.email" name="email" type="text"
                                                    class="form-control" required="" placeholder="Email">
                                            </div>
                                            <span class="mandatory" *ngIf="(sentMessage.email  == '' )&& submit">
                                                This Field is required!
                                            </span>
                                            <span class="mandatory"
                                                *ngIf="(sentMessage.email  != '' && (!sentMessage.email.includes('@') || !sentMessage.email.includes('.'))) && submit">Enter
                                                Valid Email</span>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group mb-3">
                                                <input [(ngModel)]="sentMessage.mobileNo" name="phone" type="number"
                                                    class="form-control" required="" placeholder="Phone">
                                            </div>
                                            <span class="mandatory"
                                                *ngIf="(sentMessage.mobileNo == '' ||  sentMessage.mobileNo == null)&& submit">
                                                This Field is required!
                                            </span>
                                        </div>

                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group mb-3">
                                                <input [(ngModel)]="sentMessage.subject" name="subject" type="text"
                                                    class="form-control" required="" placeholder="Subject">
                                            </div>
                                            <span class="mandatory" *ngIf="sentMessage.subject == '' && submit">
                                                This Field is required!
                                            </span>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="form-group mb-3">
                                                <textarea [(ngModel)]="sentMessage.message" name="message"
                                                    class="form-control" rows="3" placeholder="Message"></textarea>
                                            </div>
                                            <span class="mandatory" *ngIf="sentMessage.message == '' && submit">
                                                This Field is required!
                                            </span>
                                        </div>

                                        <div class="col-md-12">
                                            <button type="submit" class="site-button" (click)="onSubmit()">Submit
                                                Now</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="contact-info-wrap">

                                <div class="contact-info">
                                    <div class="contact-info-section">

                                        <div class="c-info-column">
                                            <div class="c-info-icon"><i class=" fas fa-map-marker-alt"></i></div>
                                            <h3 class="twm-title">Qatar</h3>
                                            <p>Doha, Qata</p>
                                        </div>

                                        <div class="c-info-column">
                                            <div class="c-info-icon custome-size"><i class="fas fa-mobile-alt"></i>
                                            </div>
                                            <h3 class="twm-title">Feel free to contact us</h3>
                                            <p><a href="tel:+216-761-8331">+2 900 234 4241</a></p>
                                            <p><a href="tel:+216-761-8331">+2 900 234 3219</a></p>
                                        </div>

                                        <div class="c-info-column">
                                            <div class="c-info-icon"><i class="fas fa-envelope"></i></div>
                                            <h3 class="twm-title">Support</h3>
                                            <p>info@mahadjobs.com</p>
                                            <!-- <p>support12@gmail.com</p> -->
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- <div class="gmap-outline">
        <div class="google-map">
            <div style="width: 100%">
                <iframe height="460" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.8534521658976!2d-118.2533646842856!3d34.073270780600225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c6fd9829c6f3%3A0x6ecd11bcf4b0c23a!2s1363%20Sunset%20Blvd%2C%20Los%20Angeles%2C%20CA%2090026%2C%20USA!5e0!3m2!1sen!2sin!4v1620815366832!5m2!1sen!2sin"></iframe>
            </div>
        </div>
    </div> -->


</div>