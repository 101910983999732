import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { JobApplyModel } from 'src/app/model/JobApply';
import { JobApplyService } from 'src/app/services/jobApply.service';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { LoaderService } from 'src/app/services/loader.service';
import { CompanyPackageService } from 'src/app/services/companyPackage';
import { MasterService } from 'src/app/services/master.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

export interface PeriodicElement {
  name: string;
  action: any;
  applied_for: string;
  status: string;
  date: string;
}

@Component({
  selector: 'app-employer-viewcandidate',
  templateUrl: './employer-viewcandidate.component.html',
  styleUrls: ['./employer-viewcandidate.component.scss']
})
export class EmployerViewcandidateComponent implements OnInit {

  jobId: Number = null;
  url: string = "";
  jobApply: JobApplyModel = {
    id: 0,
    jobId: 0,
    jobSeekerId: 0,
    creationDate: "",
    jobSeeker: undefined,
    job: undefined
  }
  jobApplyList: Array<JobApplyModel> = [];
  packageExist: boolean = false;



  ELEMENT_DATA: PeriodicElement[] = [
  ];

  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  displayedColumns: string[] = ['name', 'applied_for', 'date', 'status', 'action'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _liveAnnouncer: LiveAnnouncer, private jobApplySrv: JobApplyService,
    private packageService: CompanyPackageService,
    private authService: AuthService,
    private toastService: ToastrService,
    public loader: LoaderService) {
    this.loader.loader = true;
  }
  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    var urlString = window.location.href;
    var url = new URL(urlString);
    var id = url.searchParams.get("Id");
    if (id != null && id != undefined && id != "") {
      this.jobId = parseInt(id);
      this.getCandidate(this.jobId);
      this.checkPackage();
    }
  }

  getCandidate(id: Number) {
    this.jobApplySrv.getByJobId(id).subscribe((data) => {
      this.jobApplyList = data;
      let data0: PeriodicElement = {
        name: '',
        action: undefined,
        applied_for: '',
        status: '',
        date: ''
      }
      for (var i = 0; i < this.jobApplyList.length; i++) {
        data0.name = this.jobApplyList[i].jobSeeker.fullNameEn;
        data0.applied_for = this.jobApplyList[i].job.jobTitle;
        data0.date = this.jobApplyList[i].creationDate.replace("T", " at ");;
        data0.status = "Pending";
        data0.action = [this.jobApplyList[i].jobSeekerId, this.jobApplyList[i].jobSeeker.resumePath];
        this.ELEMENT_DATA.push(data0);
      }
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.loader.loader = false;
    })
  }

  viewProfile(id: Number) {
    window.location.href = "/user-profile-employer?Id=" + id + "&jobId=" + this.jobId;
  }
  viewCv(id: string) {
    if (this.packageExist) {

      window.open(environment.apiUrl + 'Cv/' + id);
    } else {
      this.toastService.warning("Please select package");
    }
  }


  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  checkPackage() {
    let compId: Number = this.authService.getAuthStorage().companyId;
    this.packageService.getByCompId(compId).subscribe((data) => {
      if (data.length > 0) {
        this.packageExist = true;
      }
    })
  }

}
